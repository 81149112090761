<template>
  <div class="text-white w-full">

    <div class="tabs w-full flex flex-row flex-nowrap mb-3">
      <a @click="() => { state.currentTab = 'data' }" class="tab tab-lifted text-secondary flex flex-row gap-1" :class="[{'tab-active': state.currentTab == 'data'}]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
        </svg>
        <span class="text-xs text-white">{{ $t("message.data") }}</span>
      </a> 
      <a v-if="state.id" @click="() => { state.currentTab = 'scenarios' }" class="tab tab-lifted text-secondary-focus flex flex-row gap-1" :class="[{'tab-active': state.currentTab == 'scenarios'}]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
        <span class="text-xs text-white">{{ $t("message.responders") }}</span>
      </a>
      <a v-if="state.id" @click="() => { state.currentTab = 'capabilities' }" class="tab tab-lifted text-secondary-focus flex flex-row gap-1" :class="[{'tab-active': state.currentTab == 'capabilities'}]">
        <SVGRenderer svgKey="capabilities_icon" class="w-4 h-4" />
        <span class="text-xs text-white">{{ $t("message.capabilities") }}</span>
      </a> 
    </div>

    <div v-if="state.currentTab == 'data'">
      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.displayName") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.displayName" type="text" :placeholder="$t('message.deviceNamePlaceholder')" class="input input-bordered w-full mb-3" />
      </div>

      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0">
              <span class="label-text">{{ $t("message.deviceType") }}</span>
              <span class="label-text-alt"></span>
            </label>
            <select v-model="state.gistType" class="select select-sm w-full select-bordered ">
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option v-for="(gistType, key) in gistTypes" :key="key" :value="gistType">{{ gistType }}</option>
            </select>
          </div>
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0">
              <span class="label-text">{{ $t("message.adapterType") }}</span>
              <span class="label-text-alt"></span>
            </label>
            <select v-model="state.adapterType" class="select select-sm w-full select-bordered">
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option v-for="(adapterType, key) in currentAdapterTypes" :key="key" :value="adapterType.id">{{ adapterType.id }}</option>
            </select>
          </div>
        </div>

        <div class="w-full mt-3" v-if="currentConfig && !state.displayName.endsWith('[auto]')">
          <div class="flex flex-col w-full border-l-4 border-l-magenta pl-5">
            <label class="label mt-0 p-0">
              <span class="label-text text-magenta font-bold">{{ $t("message.adapterConfig") }}</span>
            </label>
            <div class="form-control w-full" v-for="(placeholder, key) in currentConfig" :key="key">
              <div class="label">
                <span class="label-text text-white text-xs">{{ parseCfgKey(key) }}:</span>
                <div v-if="key.startsWith('boolean')" class="flex justify-center pl-2"><input class="toggle toggle-secondary" type="checkbox" v-model="state.config[key]" :checked="placeholder === true"></div>
              </div>
              <input v-if="!Array.isArray(placeholder) && !key.startsWith('boolean')" v-model="state.config[key]" :type="(key.substring(0,7) == 'secret_') ? 'password' : 'text'" :placeholder="placeholder" class="input input-sm input-bordered w-full " />
              <select v-model="state.config[key]" v-if="Array.isArray(placeholder)" class="select select-bordered select-sm w-full">
                <option disabled selected>{{ $t("message.choose") }}</option>
                <option v-for="oval in placeholder" :key="typeof oval === 'object' ? oval.key : oval" :value="typeof oval === 'object' ? oval.key : oval">{{ typeof oval === 'object' ? oval.value : oval }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="w-full mt-3">
          <button v-if="!state.displayName.endsWith('[auto]')" :disabled="!validation()" class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none" @click="addNewGist">
            {{ $t("message.save") }}
          </button>
          <button :disabled="!saved()" class="disabled:opacity-60 btn btn-outline btn-sm text-white font-light text-xs ml-3 border-none" @click="testSavedGist">
            <span v-if="isLoadingTest()" class="loading loading-spinner loading-xs"></span> {{ $t("message.test") }}
          </button>
          <button v-if="isSiteDesign()" :disabled="!saved()" class="disabled:opacity-60 btn btn-red btn-sm text-white font-light text-xs ml-3 border-none" @click="syncSites">
            <span v-if="isLoadingSyncSites()" class="loading loading-spinner loading-xs"></span> {{ $t("message.syncSites") }}
          </button>
        </div>
        <ShowOutgoingIP />
        <div v-if="state.testOutput != null" class="mt-3 mockup-code text-left max-w-96">
          <pre data-prefix="$"><code>{{ state.testOutput }}</code></pre>
        </div>
      </div>
    </div>

    <div v-if="state.currentTab == 'scenarios'">
      <ListScenarios :responderId="state.id" />

      <div class="form-control w-full pt-5">
        <button data-v-2c58454a="" type="button" class="btn btn-secondary" @click="addNewScenario">
          {{ $t("message.newScenario") }}
        </button>
      </div>
    </div>

    <div v-if="state.currentTab == 'capabilities'">
      <CapabilityEditor :gistId="state.id" />
    </div>

  </div>
</template>

<script setup>
  import Axios from '@/misc/axios';
  import store from '@/misc/vuex-store';
  import { reactive, computed, watch, ref, onMounted } from 'vue';
  import toast from '@/misc/toast.js'
  import ListScenarios from '../ScenariosSidebarComponents/ListScenarios.vue';
  import ShowOutgoingIP from '@/components/ShowOutgoingIP.vue';
  import TenantConfigHandler from '@/misc/TenantConfigHandler';
  import SVGRenderer from '../../../components/SVGRenderer.vue';
  import CapabilityEditor from '../CapabilitiesSidebarContent/CapabilityEditor.vue';

  const props = defineProps({
    backToList: {
      type: Function,
      default: () => {}
    },
    editedAdapterName: {
      type: String,
      default: ''
    },
    editedDisplayName: {
      type: String,
      default: ''
    },
    editedGistType: {
      type: String,
      default: ''
    },
    editedConfig: {
      type: String,
      default: ''
    },
    editedId: {
      type: Number,
      default: 0
    },
    editedParentId: {
      type: Number,
      default: null
    }
  })

  const state = reactive({
    displayName: props.editedDisplayName ? props.editedDisplayName : "",
    gistType: props.editedGistType ? props.editedGistType : null,
    adapterType: props.editedAdapterName ? props.editedAdapterName : null,
    config: props.editedConfig ? JSON.parse(props.editedConfig) : {},
    id: props.editedId ? props.editedId : null,
    parentId: props.editedParentId ? props.editedParentId : null,
    testOutput: null,
    isLoadingTest: false,
    isLoadingSyncSites: false,
    currentTab: 'data',
    connectedScenarios: []
  })

  function addNewScenario() {
    store.commit('showSidebar', { 
      type: 'scenarioTypeSelector', 
      entity: { 
        gistId: state.id, 
        gistName: state.displayName 
      }
    });
  }

  const sidebarContent = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity;
  });

  const gistTypes = computed(() => {
    if(sidebarContent.value.entity.displayName === "system") {
      return ["SITE_DESIGN","INTEGRATION","HELPER"];
    } else {
      return store.getters.getGistTypes;
    }
  });

  const currentAdapterTypes = computed(() => {
    const adapterTypes = store.getters.getAdapterTypes;
    let filteredAdapterTypes = [];
    for(let i in adapterTypes) {
      if(adapterTypes[i].gist_type == state.gistType) {
        let cAdapterType = adapterTypes[i];
        cAdapterType.id = i;
        filteredAdapterTypes.push(cAdapterType);
      }
    }
    return filteredAdapterTypes;
  });

    const currentConfig = ref(null);

  function addNewGist() {
    const entityType = sidebarContent.value.params.entityType;
    const entityId = sidebarContent.value.entity.id ? sidebarContent.value.entity.id : "";

    let uri;

    if (entityType == "system") {
      uri = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/' + entityType + "/add-gist";
    } else {
      uri = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/' + entityType + "/" + entityId + '/add-gist';
    }

    Axios.post(uri, {
      adapterName: state.adapterType,
      config: JSON.stringify(state.config),
      displayName: state.displayName,
      gistType: state.gistType,
      id: state.id
    }, {
      onTokenRefreshed: () => {
        addNewGist()
      }
    }).then(() => {
      state.displayName = "";
      state.gistType = null;
      state.adapterType = null;
      state.config = {};
      state.id = null;
      state.parent_id = null;
      props.backToList();
      toast.success("message.saved")
    })
    .catch((err) => {
      console.log(err)
    })
  }

  function testSavedGist() {
    state.isLoadingTest = true;
    const entityType = sidebarContent.value.params.entityType;
    let uri = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/' + entityType + "/" + state.id + '/test-gist';

    Axios.get(uri, {}, {
      onTokenRefreshed: () => {
        testSavedGist()
      }
    }).then((testResult) => {
      state.isLoadingTest = false;
      state.testOutput = JSON.stringify(testResult.data, null, "\t");
    }).catch((err) => {
      console.log(err)
    })
  }

  function syncSites() {
    state.isLoadingSyncSites = true;
    const entityType = sidebarContent.value.params.entityType;
    let uri = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/' + entityType + "/" + state.id + '/sync-sites';

    Axios.get(uri, {}, {
      onTokenRefreshed: () => {
        syncSites()
      }
    }).then(() => {
      /*
      state.testOutput = JSON.stringify(testResult.data, null, "\t");
       */
      state.isLoadingSyncSites = false;
      window.location.reload();
    }).catch((err) => {
      console.log(err)
    })
  }

  // function getConnectedScenarios() {
  //   if(!state.id) return;

  //   Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/scenarios/with-responder/' + state.id, {
  //     onTokenRefreshed: () => {
  //       getConnectedScenarios()
  //     }
  //   }).then((response) => {
  //     state.connectedScenarios = response.data.data;
  //   }).catch((err) => {
  //     console.log(err)
  //   })
  // }

  function validation() {
    if(!state.displayName || !state.gistType || !state.adapterType) return false;
    return true;
  }

  function saved() {
    return (state.id !== null);
  }

  function isLoadingTest() {
    return state.isLoadingTest;
  }

  function isLoadingSyncSites() {
    return state.isLoadingSyncSites;
  }

  function parseCfgKey(key) {
    if(key.indexOf("$$") >= 0)
    {
      key = key.substring(key.indexOf("$$")+2);
    }
    return key.replace(/_([a-z])/g, function (g) { return " " + g[1].toUpperCase(); })
  }

  function isSiteDesign() {
    return (state.gistType === "SITE_DESIGN");
  }

  watch(() => props.editedAdapterName, (newValue) => {
    state.adapterType = newValue;
  });

  watch(() => props.editedDisplayName, (newValue) => {
    state.displayName = newValue;
  });

  watch(() => props.editedGistType, (newValue) => {
    state.gistType = newValue;
  });

  watch(() => props.editedConfig, (newValue) => {
    state.config = JSON.parse(newValue);
  });

  watch(() => props.editedId, (newValue) => {
    state.id = newValue;
  });

  watch(() => props.editedParentId, (newValue) => {
    state.parentId = newValue;
  });

  watch(() => state.adapterType, (newValue) => {
    if(newValue) {
      getAdapterConfig(newValue);
    }
  }); 
  onMounted(() => {
    if(state.adapterType) {
      getAdapterConfig(state.adapterType);
    }
  });

  function getAdapterConfig(adapterType)
  {
    if(!adapterType) {
      currentConfig.value = null;
    }
    Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/adapter/config?adapterName=' + adapterType, {
      onTokenRefreshed: () => {
        getAdapterConfig()
      }
    }).then((response) => {
      if(typeof response.data === 'string')
      {
        currentConfig.value = JSON.parse(response.data.replaceAll(/'/g, '"'));
      }
      else
      {
        currentConfig.value = response.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  // watch(() => state.currentTab, (newValue) => {
  //   if(newValue == 'scenarios') {
  //     getConnectedScenarios();
  //   }
  // });

</script>
