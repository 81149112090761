<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box">
        <div class="h-full overflow-y-auto w-full">
          <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
              >
                <span class="text-secondary">{{
                  !props.editMode ? $t('message.addNew') : $t('message.edit')
                }}</span>
              </h2>
            </div>
            <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>

          <div class="px-5 pb-5">
            <div class="flex flex-col gap-2 w-full">
              <div class="flex flex-row gap-3 w-full mt-2">
                <!--eslint-disable-->
                <ImFormInput
                  v-model:inputValue="state.code"
                  :title="$t('message.code')"
                  type="text"
                  class="w-full mr-4"
                >
                </ImFormInput>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput
                  v-model:inputValue="state.name"
                  :title="$t('message.name')"
                  type="text"
                  class="w-full mr-4"
                >
                </ImFormInput>
              </div>
              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput
                  v-model:inputValue="state.groupName"
                  :title="$t('message.groupName')"
                  type="text"
                  class="w-full mr-4"
                >
                </ImFormInput>
              </div>
              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput
                  v-model:inputValue="state.valueType"
                  :title="$t('message.valueType')"
                  type="optionManual"
                  :manualOptions="[
                    { value: 'NUMBER', label: 'number' },
                    { value: 'DOUBLE', label: 'double' },
                    { value: 'STRING', label: 'string' },
                    { value: 'BOOLEAN', label: 'boolean' },
                    { value: 'DATE', label: 'date' },
                    { value: 'DATE_TIME', label: 'dateTime' },
                  ]"
                  class="w-full mr-4"
                >
                </ImFormInput>
              </div>

              <div :class="{ hideOpenIndicator: state.metaDataId }">
                <RemLabel class="mt-2" title="Metadata"></RemLabel>
                <div class="dropdown">
                  <select
                    class="input input-sm input-bordered w-full pr-2"
                    v-model="state.metaDataId"
                  >
                    <option v-for="md in state.metaData" v-bind:key="md.id" :value="md.id">
                      {{ md.name }}
                    </option>
                  </select>
                  <button
                    v-if="state.metaDataId"
                    class="clear-button"
                    @click="state.metaDataId = null"
                    aria-label="Törlés"
                  >
                    <span class="text-sm bold"> X </span>
                  </button>
                </div>
              </div>

              <div :class="{ hideOpenIndicator: state.selectedSection }">
                <RemLabel class="mt-2" title="Section"></RemLabel>
                <div class="dropdown">
                  <select
                    class="input input-sm input-bordered w-full pr-2"
                    v-model="state.selectedSection"
                  >
                    <option v-for="section in state.sections" v-bind:key="section" :value="section">
                      {{ section }}
                    </option>
                  </select>
                  <button
                    v-if="state.selectedSection"
                    class="clear-button"
                    @click="state.selectedSection = null"
                    aria-label="Törlés"
                  >
                    <span class="text-sm bold"> X </span>
                  </button>
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <ImFormInput
                  v-model:inputValue="state.ord"
                  :title="$t('message.ord')"
                  type="text"
                  class="w-full mr-4"
                >
                </ImFormInput>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                saveCustomField();
                store.commit('setIsModalOpen', false);
              }
            "
            >{{ $t('message.save') }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { /*computed,*/ onMounted, reactive, watch } from 'vue';
import Axios from '@/misc/axios';
import toast from '@/misc/toast.js';
import store from '@/misc/vuex-store.js';
import { useI18n } from 'vue-i18n';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useRoute } from 'vue-router';
import ImFormInput from '@/components/ImFormInput.vue';
import RemLabel from '@/components/REM/imFormInput/RemLabel.vue';

const { t } = useI18n();
const router = useRoute();
const taskTypeId = router.params.taskTypeId;
const workflowId = router.params.workflowId;

// const emits = defineEmits(['refreshGrid'])

const state = reactive({
  showModal: false,
  code: null,
  name: null,
  metaData: [],
  metaDataId: null,
  groupName: null,
  valueType: null,
  sections: [],
  selectedSection: null,
  ord: null,
});

const props = defineProps([
  'showModal',
  'id',
  'wfCustomFieldsGrid',
  'type',
  'customFieldById',
  'editMode',
]);

const getSections = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/custom-fields/sections`;

  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        getSections();
      },
    });
    state.sections = response.data;
  } catch (error) {
    console.log(error);
  }
};

getSections();

const getMetaData = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/admin/metadata`;

  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        getMetaData();
      },
    });
    state.metaData = response.data.content;
    console.log(state.metaData);
    
  } catch (error) {
    console.log(error);
  }
};

getMetaData();

const saveCustomField = async () => {
  let uri = '';
  const hermes = {
    code: state.code,
    name: state.name,
    metaDataId: state.metaDataId,
    groupName: state.groupName,
    valueType: state.valueType,
    section: state.selectedSection,
    ord: state.ord,
  };

  if (!props.editMode) {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/custom-fields`;

    try {
      await Axios.post(uri, hermes, {
        onTokenRefreshed: () => {
          saveCustomField();
        },
      });

      toast.success(t('message.successfulSave'));
      emptyForm();
      props.wfCustomFieldsGrid?.refresh();
    } catch (error) {
      console.log(error);
      toast.error(t('message.notSuccessfulSave'));
    }
  } else if (props.editMode) {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/custom-field/${props.customFieldById.id}`;

    try {
      await Axios.put(uri, hermes, {
        onTokenRefreshed: () => {
          saveCustomField();
        },
      });

      toast.success(t('message.successfulSave'));
      emptyForm();
      props.wfCustomFieldsGrid?.refresh();
    } catch (error) {
      console.log(error);
      toast.error(t('message.notSuccessfulSave'));
    }
  }
};

const fillForm = () => {
  state.name = props.customFieldById.name;
  state.code = props.customFieldById.code;
  state.metaDataId = props.customFieldById.metaDataId;
  state.groupName = props.customFieldById.groupName;
  state.valueType = props.customFieldById.metaDataValueType;
  state.selectedSection = props.customFieldById.section;
  state.ord = props.customFieldById.ord;
};

watch(
  () => props.showModal,
  () => {
    state.showModal = props.showModal;
    if (state.showModal && props.editMode) {
      fillForm();
    } else {
      emptyForm();
    }
  }
);

const closeModal = () => {
  store.commit('setIsModalOpen', false);
};

const emptyForm = () => {
  state.name = null;
  state.code = null;
  state.metaDataId = null;
  state.groupName = null;
  state.valueType = null;
  state.selectedSection = null;
  state.ord = null;
};

onMounted(() => {
  getMetaData();
});
</script>

<style scoped>
select[disabled] {
  @apply border border-gray-600;
}

.im-dropdown-content {
  position: absolute;
}

.im-invisible {
  display: none;
}

.dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 96%;
}

.clear-button {
  position: absolute;
  right: 0.6rem;
  background: none;
  border: none;
  color: rgb(175, 170, 170);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
}

.clear-button:hover {
  cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
  display: none;
}

.dropdown::before {
  position: absolute;
  font-size: 12px;
  content: ' \25BC';
  top: 7px;
  right: -8px;
  height: 20px;
  width: 28px;
  pointer-events: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.option-hover {
  padding: 5px 0 5px 5px;
  font-size: 0.875rem;
}

.option-hover:hover {
  background-color: rgb(147, 185, 247);
  color: #242933;
}

ul .option-hover:first-of-type {
  border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
  border-radius: 0 0 10px 10px;
}
</style>
