<template>
    <div>
        <div class="input-group input-groupsm mb-4" :class = "{ hideOpenIndicator : selectValue }">
            <div v-if="searchable == true" class="dropdown">
                <!-- <input type="hidden" v-model="selectValue" class="input input-sm input-bordered w-full"
                    :placeholder="$t('message.select')"> -->
                <div class="im-dropdown w-full ">
                    <input type="text" list="optionList" v-model="optionSearchValue" @focusin="focus = true"
                        @focusout="setFocus" class="input input-sm input-bordered w-full border-rad-inputGroup" :mandatory="mandatory"
                     :id="`${title}${type}`">

                    <div class="relative">
                        <ul class="im-dropdown-content bg-base-100 rounded-box z-50 w-full shadow z-40"
                            v-if="focus == true">
                            <!-- <li class="option-hover"><a @click="optionSearchValue = ''; selectValue = ''">{{
                                $t('message.select') }}</a></li> -->
                            <li class="option-hover" v-for="op in options" v-bind:key="op.value + op.label"
                                @click="optionSearchValue = op.label; selectValue = op.value">{{ op.label }}</li>
                        </ul>
                    </div>

                </div>
                <button
                    v-if="selectValue" class="clear-button" @click="selectValue = '', optionSearchValue = ''" aria-label="Törlés">
                    <span class="text-sm bold">
                        X
                    </span>
                </button>
            </div>
            <div v-else class="dropdown">
                <select v-model="selectValue" class="input input-sm input-bordered w-full border-rad-inputGroup" :mandatory="mandatory"
                    @focusout="setFocus" :id="`${title}${type}`">
                    <!-- <option value="" selected hidden>{{ $t('message.select') }}</option> -->
                    <!-- <option value="null">{{ $t('message.empty') }}</option> -->

                    <option v-for="op in options" v-bind:key="op.value + op.label" :value="op.value"
                        :parent="op.parent">{{
                            op.label }}
                    </option>
                </select>
                <button v-if="selectValue" class="clear-button text-sm bold" @click="selectValue = ''" aria-label="Törlés"> X </button>
            </div>
            <button @click="addSelected()" class="btn btn-sm ">{{ $t('message.add') }}</button>
            
        </div>
        <div class="flex flex-row valueRow">
            <a v-for="(select, index) in inputLabels" v-bind:key="select" class="mr-4"
                @click="() => { removeSelected(index) }">{{ select }}</a>
        </div>
    </div>
</template>

<script setup>
import { defineModel, onMounted, ref, computed, watch } from 'vue';
import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";
//import RemPrimaryButtonSmall from '../RemPrimaryButtonSmall.vue';

const props = defineProps(['title', 'type', 'valueLabel', 'optionValue', 'endPoint', 'displayValue', 'searchable', 'manualOptions', 'onchangeMethod', 'validationMethod', 'mandatory']);

const inputValue = defineModel('inputValue')
//const parent = defineModel('parent')
//const validated = defineModel('validated')
//const inputError = defineModel('inputError')
const optionsOut = defineModel('optionsOut')

const options = ref([])
const selectValue = ref("");
const inputLabels = ref([]);

let optionsRepo = [];

const optionSearchValue = ref("");

const focus = defineModel('focus');

const language = computed(() => store.getters.getLanguage);

onMounted(() => {
    inputLabels.value = props.valueLabel == "" || props.valueLabel == null ? [] : props.valueLabel.split(", ");

    getOptions();
})

const getOptions = async () => {

    if (props.type == "optionMultiSelect" && props.optionValue) {

        const resp = await Axios.get(
            TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
            "/rem/option/options/" +
            props.optionValue,
            {
                'Accept-Language': language.value
            },
            {
                onTokenRefreshed: () => {
                    getOptions();
                },
            }
        )
        if (resp && resp.data) {
            resp.data.forEach((op) => {
                options.value.push({
                    label: op.value,
                    value: op.id,
                    parent: op.parent != null ? op.parent.id : "",

                });
            });
        }


    }

    optionsRepo = options.value
    optionsOut.value = options.value

   

    if (inputValue.value == "" || inputValue.value == null) {
        inputValue.value = []
    } else {
        inputValue.value = inputValue.value.split(", ")
    }

}

const setFocus = function () {
    setTimeout(() => { focus.value = false }, 500);
}

const addSelected = () => {

    inputValue.value.push(selectValue.value);

  
    options.value.every((e) => {
   
        if (e.value == selectValue.value) {
        
            inputLabels.value.push(e.label);
            selectValue.value = "";
            return false;
        }
        return true;
    })

}

const removeSelected = (index) => {


    inputValue.value.splice(index, 1);
    inputLabels.value.splice(index, 1)
}

watch(optionSearchValue, (newValue, oldValue) => {
    if (newValue == "") {
        options.value = optionsRepo
        optionsOut.value = options.value
    }
    else if (oldValue != newValue) {
        options.value = optionsRepo.filter(elem => (elem.label.toLowerCase()).includes(newValue.toLowerCase()));
        optionsOut.value = options.value
    }


})




</script>


<style scoped>
.im-dropdown-content {
    position: absolute
}

.im-invisible {
    display: none;

}

.dropdown {
    position: relative;
    display: inline-flex;
  align-items: center;
    width: 100%;
}

.clear-button {
  position: absolute;
  right: 0.6rem;
  background: inherit;
  border: none;
  color: rgb(175, 170, 170);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
}

.clear-button:hover {
  cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
  display: none;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.valueRow {
    background-color: #2A303C;
    border-radius: 8px;
    border: 1px solid #434955;
    padding: 4px;
    padding-left: 10px;
}

.valueRow a:hover {
    font-weight: bolder;
    cursor: pointer;
}

.border-rad-inputGroup{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.option-hover {
    padding: 5px 0 5px 5px;
    font-size: 0.875rem;

}

.option-hover:hover {
    background-color: rgb(147, 185, 247);
    color: #242933;
}

ul .option-hover:first-of-type {
    border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
    border-radius: 0 0 10px 10px;
}
</style>