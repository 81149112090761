<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box">
        <div class="h-full overflow-y-auto w-full">
          <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
              >
                <span class="text-secondary">{{
                  !props.editMode ? $t('message.addNew') : $t('message.edit')
                }}</span>
              </h2>
            </div>
            <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>

          <div class="px-5 pb-5">
            <div class="flex flex-col items-center justify-center gap-2 w-full">
              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{ $t('message.name') }}</span>
                  </label>
                  <input
                    type="text"
                    v-model="state.taskType"
                    class="input input-sm input-bordered w-full"
                  />
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{ $t('message.shortName') }}</span>
                  </label>
                  <input
                    type="text"
                    v-model="state.shortName"
                    class="input input-sm input-bordered w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label
            v-if="User.hasPermission('VocabularyAdmin') && state.editMode"
            class="btn btn-sm text-white font-light btn-error hover:bg-black hover:border-none text-xs"
            @click="
              () => {
                setConfirmation('deleteTaskTypeConfirm', deleteTaskType);
                store.commit('setIsModalOpen', false);
              }
            "
            >{{ $t('message.delete') }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                saveTaskType();
                this.$emit('refreshGrid');
                store.commit('setIsModalOpen', false);
              }
            "
            >{{ $t('message.save') }}</label
          >
        </div>
      </div>
    </div>
    <!--CONFIRMATION MODAL-->
    <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
    <div class="modal">
      <div class="modal-box">
        <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
          {{ $t('message.' + state.confirmationModal.message) }}
        </h3>
        <div class="modal-action">
          <label
            class="btn btn-sm text-white font-light text-xs"
            @click="
              () => {
                state.confirmationModal.show = false;
                state.showModal = true;
              }
            "
            >{{ $t('message.no') }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                state.confirmationModal.function();
                state.confirmationModal.show = false;
                state.showModal = false;
              }
            "
            >{{ $t('message.yes') }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { /*computed,*/ onMounted, reactive, watch } from 'vue';
import Axios from '@/misc/axios';
import toast from '@/misc/toast.js';
import store from '@/misc/vuex-store.js';
import { useI18n } from 'vue-i18n';
import User from '@/misc/User.js';
import TenantConfigHandler from '@/misc/TenantConfigHandler';

const { t } = useI18n();

// const emits = defineEmits(['refreshGrid'])

const state = reactive({
  showModal: false,
  shortName: null,
  taskType: null,
  confirmationModal: {
    show: false,
    message: '',
    function: '',
  },
});

const props = defineProps(['showModal', 'id', 'taskTypesGrid', 'taskType', 'editMode']);

const saveTaskType = async () => {
  let uri = '';
  const hermes = {
    name: state.taskType,
    shortName: state.shortName,
  };

  if (!props.editMode) {
    uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/admin/task-types`;

    try {
      await Axios.post(uri, hermes, {
        onTokenRefreshed: () => {
          saveTaskType();
        },
      });

      toast.success(t('message.successfulSave'));
      emptyForm();
      props.taskTypesGrid?.refresh();
    } catch (error) {
      console.log(error);
      toast.error(t('message.notSuccessfulSave'));
    }
  } else if (props.editMode) {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${props.taskType.id}`;

    try {
      await Axios.put(uri, hermes, {
        onTokenRefreshed: () => {
          saveTaskType();
        },
      });

      toast.success(t('message.successfulSave'));
      emptyForm();
      props.taskTypesGrid?.refresh();
    } catch (error) {
      console.log(error);
      toast.error(t('message.notSuccessfulSave'));
    }
  }
};

const fillForm = () => {
  state.taskType = props.taskType.name;
  state.shortName = props.taskType.shortName;
};

watch(
  () => props.showModal,
  () => {
    state.showModal = props.showModal;
    if (state.showModal && props.editMode) {
      fillForm();
    } else {
      emptyForm();
    }
  }
);

const emptyForm = () => {
  state.taskType = null;
  state.shortName = null;
};

const closeModal = () => {
  store.commit('setIsModalOpen', false);
};

onMounted(() => {});
</script>

<style scoped></style>
