export default {
  data: [
    {
      title: 'doorsAndWindowsType',
      value: '',
      valueLabel: '',
      model: 'doorsAndWindowsType',
      type: 'optionManual',
      mandatory: true,
      validated: false,
      manualOptions: [
        {
          value: 'DOOR',
          label: 'door',
        },
        {
          value: 'WINDOW',
          label: 'window',
        },
      ],
    },
    {
      title: 'doorTyp',
      value: '',
      model: 'doorTypeId',
      type: 'option',
      optionValue: 'DOOR_TYPE',
      parent: '',
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "DOOR"
      },
    },
    {
      title: 'doorsAndWindowsNumber',
      value: '',
      model: 'numberOfItems',
      type: 'integer',
      parent: '',
    },
    {
      title: 'openable',
      value: '',
      model: 'openable',
      type: 'checkbox',
      parent: '',
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "WINDOW"
      },
    },
    {
      title: 'material',
      value: '',
      valueLabel: '',
      model: 'windowMaterialId',
      type: 'option',
      optionValue: 'WINDOW_MATERIAL',
      parent: '',
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "WINDOW"
      },
    },
    {
      title: 'windowWidth',
      value: '',
      model: 'width',
      type: 'positiveNumber',
      parent: '',
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "WINDOW"
      },
    },
    {
      title: 'windowHeight',
      value: '',
      model: 'height',
      type: 'positiveNumber',
      parent: '',
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "WINDOW"
      },
    },
    {
      title: 'windowDepth',
      value: '',
      model: 'depth',
      type: 'positiveNumber',
      parent: '',
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "WINDOW"
      },
    },
  ],
};
