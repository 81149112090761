import { createI18n } from "vue-i18n";
import VueCookie from 'vue-cookie';
import store from '@/misc/vuex-store';
import messages from '@/messages'

let language;
if(VueCookie.get('language')) {
  language = VueCookie.get('language');
} else {
  language = navigator.language.slice(0,2);
}
store.commit('setLanguage', language);

const i18n = createI18n({
  locale: language,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  globalInjection: true,
  legacy: false,
  messages,
})

export default i18n;