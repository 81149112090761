<template>
  <div id="WFCustomFields" class="h-screen max-h-screen">
    <BaseLayout>
      <template #leftButtons> </template>

      <template #mainContent>
        <div class="h-full h-max-full mx-auto px-2">
          <div class="flex justify-between">
            <h1 class="text-lg font-sans font-semibold uppercase text-white my-8">
              {{ state.workflow?.name }} - {{ $t('message.openCustomFields') }}
            </h1>
            <RemUpButton remType="wf-admin" />
          </div>

          <!-- <RemPrimaryButtonSmall class="mr-4" :label="$t('message.import')" :method="() => {
                        importVersions()
                    }"></RemPrimaryButtonSmall> -->

          <RemPrimaryButtonSmall
            class="mr-4"
            :label="$t('message.addNew')"
            :method="
              () => {
                addNewCustomField();
              }
            "
          ></RemPrimaryButtonSmall>

          <RobberVueGrid
            ref="wfCustomFieldsGrid"
            :config="wfCustomFieldsGridConfigComputed"
          />
        </div>

        <AddCustomFieldModal
          @refreshGrid="refreshGrid"
          :showModal="modalStatus"
          :wfCustomFieldsGrid="wfCustomFieldsGrid"
          type="customFields"
          :customFieldById="state.customFieldById"
          :editMode="state.editMode"
        />

        <!--CONFIRMATION MODAL-->
        <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
        <div class="modal">
          <div class="modal-box">
            <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
              {{ $t('message.' + state.confirmationModal.message) }}
            </h3>
            <div class="modal-action">
              <label
                class="btn btn-sm text-white font-light text-xs"
                @click="
                  () => {
                    state.confirmationModal.confirmed = false;
                    state.confirmationModal.show = false;
                  }
                "
                >{{ $t('message.no') }}</label
              >
              <label
                class="btn btn-sm bg-magenta text-white font-light text-xs"
                @click="
                  () => {
                    state.confirmationModal.confirmMethod();
                  }
                "
                >{{ $t('message.yes') }}</label
              >
            </div>
          </div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import RemUpButton from '@/components/REM/RemUpButton.vue';
import BaseLayout from '@/layouts/BaseLayout.vue';
import RobberVueGrid from '@/components/RVG/RobberVueGrid.vue';
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';
import AddCustomFieldModal from './AddCustomFieldModal.vue';

// utils
// import { computedAsync } from '@vueuse/core'
// import RVGUtils from "../../components/RVG/RobberVueGridUtils.js";
// import router from "@/misc/router";
import { useRoute } from 'vue-router';
import { ref, computed, onMounted } from 'vue';
//import User from "../misc/User";
import Axios from '@/misc/axios';
import store from '@/misc/vuex-store';
import toast from '@/misc/toast';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useI18n } from 'vue-i18n';
import wfAdminUtils from './wfAdminUtils';
const { t } = useI18n();

// RVGS
import wfCustomFieldsGridConfig from '@/misc/gridConfigs/workflowAdmin/wfCustomFieldsGridConfig.js';

const wfCustomFieldsGrid = ref();

const router = useRoute();
const taskTypeId = router.params.taskTypeId;
const workflowId = router.params.workflowId;

const state = ref({
  workflow: null,
  customFieldById: null,
  editMode: null,
  confirmationModal: {
    confirmMethod: () => {},
    show: false,
    message: '',
  },
});

const getWorkflow = async () => {
  state.value.workflow = await wfAdminUtils.getWorkflow(taskTypeId, workflowId);
};

const refreshGrid = () => {
  wfCustomFieldsGrid.value?.refresh()
};

const addNewCustomField = () => {
  state.value.editMode = false;
  store.commit('setIsModalOpen', true);
};

const setConfirmation = (message, deleteId) => {
  console.log(message, deleteId);
  
  state.value.confirmationModal.message = message;
  state.value.confirmationModal.show = true;
  state.value.confirmationModal.confirmMethod = () => {
    state.value.confirmationModal.show = false;
    deleteCF(deleteId);
  };
};

const deleteCF = async (id) => {
  console.log('deleteCF', id);
  
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/custom-field/${id}`;
  try {
    await Axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteCF(id);
      },
    });
    toast.success(t('message.deleteSuccessful'));
    wfCustomFieldsGrid?.value.refresh();
    refreshGrid();
  } catch (error) {
    console.log(error);
    toast.error(t('message.deleteNotSuccessful'));
  }
};

// const importVersions = () => {
//     alert('TODO: Import');
// }

const editCustomField = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/custom-field/${id}`;

  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        editCustomField(id);
      },
    });
    state.value.customFieldById = response.data;
    state.value.editMode = true;
    store.commit('setIsModalOpen', true);
  } catch (error) {
    console.log(error);
  }
};

const wfCustomFieldsGridConfigComputed = computed(() => {
  return wfCustomFieldsGridConfig(
    state,
    t,
    taskTypeId,
    workflowId,
    setConfirmation,
    editCustomField
  );
});

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

onMounted(() => {
  getWorkflow();
});
</script>

<style scoped></style>
