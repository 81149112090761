<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box">
        <div class="h-full overflow-y-auto w-full">
          <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
              >
                <span class="text-secondary">{{
                  !props.editMode ? $t('message.addNew') : $t('message.edit')
                }}</span>
              </h2>
            </div>
            <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>

          <div class="px-5 pb-5">
            <div class="flex flex-col items-center justify-center gap-2 w-full">
              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{ $t('message.name') }}</span>
                  </label>
                  <input
                    type="text"
                    v-model="state.name"
                    class="input input-sm input-bordered w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                saveWorkflow();
                store.commit('setIsModalOpen', false);
              }
            "
            >{{ $t('message.save') }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { /*computed,*/ onMounted, reactive, watch } from 'vue';
import Axios from '@/misc/axios';
import toast from '@/misc/toast.js';
import store from '@/misc/vuex-store.js';
import { useI18n } from 'vue-i18n';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const router = useRoute();
const taskTypeId = router.params.taskTypeId;

// const emits = defineEmits(['refreshGrid'])

const state = reactive({
  showModal: false,
  name: null,
});

const props = defineProps(['showModal', 'wfVersionsGrid', 'workflowById', 'editMode']);

const saveWorkflow = async () => {
  let uri = '';
  const hermes = {
    name: state.name,
  };

  if (!props.editMode) {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflows`;

    try {
      await Axios.post(uri, hermes, {
        onTokenRefreshed: () => {
          saveWorkflow();
        },
      });

      toast.success(t('message.successfulSave'));
      emptyForm();
      props.wfVersionsGrid?.refresh();
    } catch (error) {
      console.log(error);
      toast.error(t('message.notSuccessfulSave'));
    }
  } else if (props.editMode) {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${props.workflowById.id}`;

    try {
      await Axios.put(uri, hermes, {
        onTokenRefreshed: () => {
          saveWorkflow();
        },
      });

      toast.success(t('message.successfulSave'));
      props.wfVersionsGrid?.refresh();
    } catch (error) {
      console.log(error);
      toast.error(t('message.notSuccessfulSave'));
    }
  }
};

const fillForm = () => {
  state.name = props.workflowById.name;
};

watch(
  () => props.showModal,
  () => {
    console.log(props.editMode);
    
    state.showModal = props.showModal;
    if (state.showModal && props.editMode) {
      fillForm();
    } else {
      emptyForm();
    }
  }
);

const closeModal = () => {
  store.commit('setIsModalOpen', false);
};

const emptyForm = () => {
  state.name = null;
};

onMounted(() => {});
</script>

<style scoped></style>
