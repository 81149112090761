<template>
  <div class="w-full border border-gray-700 rounded-lg">
    <v-select v-if="props.type == 'uploader'" :options="state.suggestions" label="label" v-model="state.selected"
      :id="focusId" @focusout="focus = false" @search="getOptions" :filterable="false" :placeholder="placeholder"
      :class="{ hideOpenIndicator: state.selected != null }">
      <!-- eslint-disable -->
      <template v-slot:no-options>
        {{ $t('message.noMatchingOptions') }}
      </template>
      <!-- eslint-enable -->
    </v-select>

    <v-select v-if="props.type == 'siteDesign' || props.type == 'editSiteDesign'" :options="state.suggestions"
      label="name" v-model="state.selected" :id="focusId" @search="getOptions" :filterable="false"
      :placeholder="placeholder" :class="{ hideOpenIndicator: state.selected != null }">
      <!-- eslint-disable -->
      <template v-slot:no-options>
        {{ $t('message.noMatchingOptions') }}
      </template>
      <!-- eslint-enable -->
    </v-select>

    <v-select v-if="props.type == 'address'" :options="state.suggestions" label="completeAddress"
      v-model="state.selected" @search="getOptions" :filterable="false" :placeholder="placeholder"
      :class="{ hideOpenIndicator: state.selected != null }">
      <!-- eslint-disable -->
      <template v-slot:no-options>
        {{ $t('message.noMatchingOptions') }}
      </template>
      <!-- eslint-enable -->
    </v-select>

    <v-select v-if="props.type == 'partner'" :options="state.suggestions" label="name" v-model="state.selected"
      @search="getOptions" :filterable="false" :placeholder="placeholder"
      :class="{ hideOpenIndicator: state.selected != null }">
      <!-- eslint-disable -->
      <template v-slot:no-options>
        {{ $t('message.noMatchingOptions') }}
      </template>
      <!-- eslint-enable -->
    </v-select>

    <v-select v-if="props.type == 'responsibles'" :options="state.suggestions" label="name" v-model="state.selected"
      :id="focusId" @focusout="focus = false" @search="getOptions" :filterable="false" :placeholder="placeholder"
      :class="{ hideOpenIndicator: state.selected != null }">
      <!-- eslint-disable -->
      <template v-slot:no-options>
        {{ $t('message.noMatchingOptions') }}
      </template>
      <!-- eslint-enable -->
    </v-select>

    <v-select v-if="props.type == 'tasks'" :options="state.suggestions" label="label" v-model="state.selected"
      :id="focusId" @focusout="focus = false" @search="getOptions" :filterable="false" :placeholder="placeholder"
      :class="{ hideOpenIndicator: state.selected != null }">
      <!-- eslint-disable -->
      <template v-slot:no-options>
        {{ $t('message.noMatchingOptions') }}
      </template>
      <!-- eslint-enable -->

    </v-select>
  </div>
</template>

<script setup>
import 'vue-select/dist/vue-select.css';
import axios from '@/misc/axios.js';
import { reactive, watch, computed, defineModel, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import TenantConfigHandler from '@/misc/TenantConfigHandler';

const { t } = useI18n();

const props = defineProps(['type', 'focusId', 'valueLabel', 'buildingId', 'taskId']);
// const emits = defineEmits(["update:inputValue"]);
const focus = defineModel('focus');
const inputValue = defineModel('inputValue');

const state = reactive({
  selected: inputValue.value || null,
  suggestions: [],
  loading: true,
});

const clearSuggestions = () => {
  state.suggestions = [];
};

defineExpose({ clearSuggestions });

const getOptions = async (search) => {
  let uri = '';

  if (props.type == 'siteDesign') {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/search/siteDesignForRem?searchParam=${search}`;
  }

  if (props.type == 'editSiteDesign') {
    //console.log(props.buildingId);

    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/search/siteDesignForRemModify/${props.buildingId}?searchParam=${search}`;
  }

  if (props.type == 'uploader') {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/user/search-by-name?searchParam=${search}`;
  }

  if (props.type == 'partner') {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      encodeURI(
        `/rem/partner/list?sort=id,desc&filters=[{"field":"name","filterKey":"name","type":"text","operation":"cn","value":"${search}"}]&filter_operator=OR`
      );
  }

  // if (props.type == "gist") {
  //   uri =
  //     TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
  //     `/search/gistForRem?searchParam=${search}`;
  // }

  if (props.type == 'address') {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/property/address/filter-options?searchParam=${search}`;
  }

  if (props.type == 'responsibles') {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/building/responsible-persons?search-param=${search}`;
  }

  if (props.type == 'tasks') {
    uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/task/${props.taskId}/linkable-tasks?search=${search}`;
  }

  const response = await axios.get(uri, {
    onTokenRefreshed: () => {
      getOptions(search);
    },
  });

  try {
    //console.log(response.data);
    if (response.data.content && Array.isArray(response.data.content)) {
      state.suggestions = response.data.content;
    } else if (response.data && Array.isArray(response.data)) {
      state.suggestions = response.data;
    } else {
      state.suggestions = [];
      console.error('Expected an array from the API, but got:', response.data);
    }

    if (props.type == 'tasks') {
      state.suggestions = state.suggestions.map((sugg) => ({
        ...sugg,
        label:
          sugg.linkableTaskIdentifier +
          ' - ' +
          (sugg.linkableTaskSubject.length > 20
            ? sugg.linkableTaskSubject.slice(0, 17) + '...'
            : sugg.linkableTaskSubject),
        id: sugg.linkableTaskId,
      }));
    }

    if (props.type == 'uploader') {
      state.suggestions.map((sugg) => {
        sugg.label = `${sugg.name} (${sugg.email})`
      })
    }

    if (inputValue.value || state.loading == false) {
      state.selected = state.suggestions.find((option) => option.id == inputValue.value) || null;
    } else if (state.loading == true) {
      if (props.type == 'uploader') {
        state.selected =
          state.suggestions.find((option) => option.name == props.valueLabel) || null;
      }
      state.loading = false;
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getOptions(props.valueLabel);
});

const placeholder = computed(() => {
  let ph = '';

  if (props.type == 'siteDesign' || props.type == 'editSiteDesign') {
    ph = t('message.siteDesignPlaceholder');
  } else {
    ph = t('message.search');
  }

  return ph;
});

watch(
  () => inputValue.value,
  (newValue) => {
    if (newValue) {
      state.selected = state.suggestions.find((option) => option.id == newValue) || null;
    } else {
      state.selected = null;
    }
  }
);

watch(
  () => state.selected,
  (newValue) => {
    if (newValue) {
      inputValue.value = newValue.id;
      // emits("update:inputValue", newValue.id);
    } else {
      inputValue.value = null;
      //emits("update:inputValue", null)
    }
  }
);
</script>

<style>
.v-select .vs__dropdown-toggle {
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__dropdown-menu {
  max-height: 250px;
  /* Állítsd be a megfelelő maximális magasságot */
  overflow-y: auto;
  /* Engedélyezi a görgetést */
  @apply border border-gray-700 rounded-md text-sm;
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__selected {
  @apply text-gray-400 text-sm;
}

.v-select .vs__open-indicator {
  @apply text-gray-500;
  display: none;
}

.v-select .vs__actions::before {
  position: absolute;
  font-size: 12px;
  content: ' \25BC';
  top: 7px;
  right: -8px;
  height: 20px;
  width: 28px;
  pointer-events: none;
}

.hideOpenIndicator .vs__actions::before {
  display: none;
}
</style>
