<template>
  <div v-if="state.internalCheck == true">
    <input type="checkbox" class="modal-toggle" v-model="showModalForm" />
    <div class="modal">
      <div class="modal-box">
        <p class="font-semibold text-white my-4 ml-4">
          {{ selectedTransition.label }}
        </p>
        <div class="w-fit flex flex-row">
          <div v-for="(group, index) in state.formGroups" v-bind:key="index" class="px-4"
            :class="{ 'border-left-white': index != 0 }">
            <h2 class="my-4 text-white"> {{$t(`message.${group.groupName}`)  }}</h2>
            <div v-for="config in group.fields" v-bind:key="config.model" class="py-1">
              <!-- eslint-disable -->
              <ImFormLabelCol :item="config" v-if="config.defaultReadonly == true"></ImFormLabelCol>
              <ImFormInput v-if="config.defaultReadonly == false" v-model:inputValue="config.value"
                v-model:parent="config.parent" v-model:validated="config.validated" v-model:options="config.options"
                :title="config.title" :type="config.type" :valueLabel="config.valueLabel"
                :optionValue="config.optionValue" :endPoint="config.endPoint" :manualOptions="config.manualOptions"
                :displayValue="config.displayValue" :searchable="config.searchable"
                :onchangeMethod="config.onchangeMethod" :validationMethod="config.validationMethod"
                :mandatory="config.mandatory" :optionSearchEndpoint="config.optionSearchEndpoint" :helperText="config.helperText ? $t('message.' + config.helperText) : null
                  ">
              </ImFormInput>

              <!-- eslint-enable -->
            </div>
          </div>
        </div>
        <div class="px-4 my-4">
          <RemPrimaryButtonSmall :label="$t('message.save')" :method="() => {
              saveModal();
            }
            " class="mr-4"></RemPrimaryButtonSmall>
          <RemPrimaryButtonSmall :label="$t('message.cancel')" :method="() => {
              showModalForm = false;
            }
            "></RemPrimaryButtonSmall>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// components
import RemPrimaryButtonSmall from "../RemPrimaryButtonSmall.vue";
import ImFormInput from "../../ImFormInput.vue";
import ImFormLabelCol from "../../ImFormLabelCol.vue";

// utils
import { defineModel, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
//import Axios from "@/misc/axios";
//import TenantConfigHandler from '@/misc/TenantConfigHandler';
//import customFieldsMain from "../../../misc/remFormdata/workflow/custom_fields/customFieldsMain";
import utils from "@/misc/fieldConfigs/utils";

let route = useRoute();
const showModalForm = defineModel("showModalForm");
//const taskId = defineModel('taskId');
const selectedTransition = defineModel("selectedTransition");
const saveMethod = defineModel("saveMethod");
import backendTypeMapper from "@/misc/fieldConfigs/backendTypeMapper";

const state = ref({
  formGroups: [],
  mainValidation: false,
  internalCheck: false,
});
onMounted(() => {
  getFields();
});

const saveModal = async () => {
  for (const [groupName, groupValue] of Object.entries(
    state.value.formGroups
  )) {
    for (let i = 0; i < state.value.formGroups[groupName].fields.length; i++) {
      if (
        groupValue.fields[i].model == "state" ||
        groupValue.fields[i].defaultReadonly == true
      )
        continue;

      await utils.saveWFCustomField(route.params.taskId, groupValue.fields[i]);
    }
  }

  saveMethod.value(selectedTransition.value);
};

const getFields = async () => {
  const optionalCustomFields = selectedTransition.value.optionalCustomFields;
  const requiredCustomFields = selectedTransition.value.requiredCustomFields;

  if (optionalCustomFields.length == 0 && requiredCustomFields.length == 0) {
    showModalForm.value = false;
    saveMethod.value(selectedTransition.value);
    return;
  }

  state.value.internalCheck = true;

  requiredCustomFields.forEach((section) => {
    section.groups.forEach((e) => {
      const formGroup = {
        groupName: e.groupName,
        fields: [],
      };
      /*let customFieldGroup;
      for (const [key] of Object.entries(customFieldsMain)) {
        if (customFieldsMain[key].groupName == e.groupName) {
          customFieldGroup = customFieldsMain[key];
        }
      }

      if (!customFieldGroup) {
        console.log(`${e.groupName} custom field group was not found`);
        return;
      }*/

      e.customFields.forEach((field) => {
        if (field.displayable == false) {
          return;
        }

        const fieldConfig = backendTypeMapper.createConfig(field, false, e.customFields);
        if(fieldConfig == false){
          console.log(`No config was found for ${field.metaDataBusinessType}`);
          return;
        }
        fieldConfig.mandatory = true;
        fieldConfig.validated = true;
        /*const fieldConfig = customFieldGroup.form.find((element) => element.model == field.code);
            fieldConfig.id = field.customFieldValueId
            
            fieldConfig.mandatory = true;
            fieldConfig.validated = true;
            fieldConfig.defaultReadonly = !field.editable
            fieldConfig.value = field.value
            
            
            if (field.valueDto && field.valueDto != null) {
                fieldConfig.valueLabel = field.valueDto[fieldConfig.backendName]
                
            }*/
        formGroup.fields.push(fieldConfig);
      });

      if(formGroup.fields.length > 0){
        state.value.formGroups.push(formGroup);
      }
      
    });
  });

  optionalCustomFields.forEach((section) => {
    section.groups.forEach((e) => {
      let formGroup;

      let f = 0;
      let found = false;
      while (f < state.value.formGroups.length && found == false) {
        if (state.value.formGroups[f].groupName == e.groupName) {
          formGroup = state.value.formGroups[f];
          found = true;
        }
      }

      if (found == false) {
        formGroup = {
          groupName: e.groupName,
          fields: [],
        };
      }

      /*let customFieldGroup;
    for (const [key] of Object.entries(customFieldsMain)) {


        if (customFieldsMain[key].groupName == e.groupName) {
            customFieldGroup = customFieldsMain[key]
        }
    }*/

      e.customFields.forEach((field) => {
        if (field.displayable == false) {
          return;
        }

        const fieldConfig = backendTypeMapper.createConfig(field, false, e.customFields);
        /*const fieldConfig = customFieldGroup.form.find((element) => element.model == field.code);
        fieldConfig.id = field.customFieldValueId
        fieldConfig.value = field.value
        fieldConfig.mandatory = false;
        fieldConfig.defaultReadonly = !field.editable

        if (field.valueDto && field.valueDto != null) {
            fieldConfig.valueLabel = field.valueDto[fieldConfig.backendName]
        }*/
        formGroup.fields.push(fieldConfig);
      });
      
      if (found == false && formGroup.fields.length > 0) {
        state.value.formGroups.push(formGroup);
      }
    });
  });
};
</script>

<style scoped>
.modal-box {
  max-width: 100%;
  width: fit-content;
}

.border-left-white {
  border-left: 1px solid white;
}
</style>
