<template>
  <div>
    <div v-for="(event, i) in events" :key="i">
      <div
        v-if="event.eventType == 'REMARK_MODIFIED'"
        class="items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
      >
        <EventTime :dateString="event.triggeredAt" />
        <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
          <a class="font-semibold text-blue-600 dark:text-secondary">
            {{ event.triggeredByUserName + ' ' }} 
          </a>

          <span
            v-if="event.eventData.dataModificationType"
            class="text-gray-900 dark:text-white"
            >{{
              event.eventData.dataModificationType == 'CREATION'
                ? $t('message.commentCreation')
                : event.eventData.dataModificationType == 'MODIFICATION'
                ? $t('message.commentEdition')
                : $t('message.commentDeletion')
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import store from '@/misc/vuex-store';
import EventTime from './components/EventTime.vue';

const events = computed(() => {
  return store.getters.getEvent;
});

</script>
