import axios from '@/misc/axios';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
//import toast from '../toast';

export default {
  

  formatCustomFieldForSaving (field)  {
    if (field.type == 'currencyField') {
      return this.formatCompositCustomFieldForSaving(field);
    }

    if (!field.customFieldId || field.customFieldId == undefined || field.defaultReadonly == true) {
      return;
    }
    let hermes = {
      customFieldId: field.customFieldId,
      values: [],
    };



    if (field.metaDataType == 'SINGLE') {
      let obj = {};
      obj[`${field.metaDataValueType.toLowerCase()}Value`] = field.value;
      hermes.values.push(obj);
    } else {

      for(let element of field.value){
        let obj = {};
        obj[`${field.metaDataValueType.toLowerCase()}Value`] = element;
        hermes.values.push(obj);
      }
      if(hermes.values.length == 0){
        hermes.values = [{}]
      }
    }

    return [hermes];
  },

  formatCompositCustomFieldForSaving(field)  {
    if (!field.customFieldId || field.customFieldId == undefined || field.defaultReadonly == true) {
      return;
    }
    let hermes = [
      {
        customFieldId: field.customFieldId[0],
        values: [],
      },
      {
        customFieldId: field.customFieldId[1],
        values: [],
      },
    ];



    let obj = {};
    obj[`${field.metaDataValueType[0].toLowerCase()}Value`] = field.value[0];
    hermes[0].values.push(obj);

    obj = {};
    obj[`${field.metaDataValueType[1].toLowerCase()}Value`] = field.value[1];
    hermes[1].values.push(obj);

    return hermes;
  },

  async saveWFCustomField (id, field) {
    if (
      !id ||
      !field.customFieldId ||
      field.customFieldId == undefined ||
      field.defaultReadonly == true
    ) {
      return;
    }
    let hermes = this.formatCustomFieldForSaving(field);

    /*if (field.metaDataType == 'SINGLE') {
      let obj = {};
      obj[`${field.backendType.toLowerCase()}Value`] = field.value;
      hermes.push(obj);
    } else {
      for(const element of field.value){
        let obj = {};
        obj[`${field.backendType.toLowerCase()}Value`] = element;
        hermes.push(obj);
      }
      
    }*/

    for(let field of hermes){

      
      await axios.put(
        TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
          '/rem/workflow/task/' +
          id +
          '/custom-field-value/' +
          field.customFieldId,
        field.values,
        {
          onTokenRefreshed: () => {
            this.saveWFCustomField(id, field);
          },
        }
      );
    
      
      
    }

  },
};
