import "./misc/loadTenantConfig.js";
import { createApp } from 'vue'
import router from './misc/router.js'
import store from '@/misc/vuex-store.js'
import moment from 'moment';
import './index.css'
import App from './App.vue'
import 'leaflet/dist/leaflet.css';
import 'vue-leaflet-markercluster/dist/style.css'
import VueCookie from 'vue-cookie';
import VueApexCharts from "vue3-apexcharts";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vSelect from "vue-select";
import i18n from "./misc/i18n.js";

const app = createApp(App);
app.component("v-select", vSelect)
app.use(i18n);
app.use(router);
app.use(Toast)
app.use(store);
app.use(VueApexCharts);

moment.locale('hu');

if(VueCookie.get('access_token')) {
  store.commit('setAccessToken', VueCookie.get('access_token'));
  store.commit('setIdToken', VueCookie.get('id_token'));
  store.commit('setRoles', VueCookie.get('roles'));
}

if(VueCookie.get('graph_access_token')) {
  store.commit('setGraphAccessToken', VueCookie.get('graph_access_token'));
  store.commit('setGraphIdToken', VueCookie.get('graph_id_token'));
}

app.mount('#app');
