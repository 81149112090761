<template>
  <div>
    <!--DOCUMENTS TABLE-->
    <div v-if="props.title == 'documents'" class="im-card-form">
      <h1 class="text-xl font-bold text-secondary mt-0">
        {{ $t('message.documents') }}
      </h1>
      <div class="flex flex-row gap-3 w-full my-2">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="docName">
            <span class="label-text-alt">
              {{ $t('message.name') }}
            </span>
          </label>
          <input
            id="docName"
            v-model="state.docuName"
            type="text"
            class="input input-sm input-bordered w-full th"
          />
        </div>

        <div class="form-control w-full">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt"
              >{{ $t('message.dateOfOrigin') }}
              <span
                :class="{ 'im-mandatory': !state.docuOriginDate || state.docuOriginDate == '' }"
                >{{ ' *' }}</span
              >
            </span>
          </label>
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd"
              :enable-time-picker="false"
              :time-picker-inline="true"
              :locale="$i18n.locale"
              :month-change-on-scroll="false"
              :action-row="{}"
              :clearable="true"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :teleport="true"
              model-type="yyyy-MM-dd"
              :placeholder="t('message.choose')"
              v-model="state.docuOriginDate"
              dark="true"
            />
          </label>
        </div>
      </div>
      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <!--eslint-disable-->
          <ImFormInput
            v-model:inputValue="state.selectedMainType"
            :title="$t('message.mainType')"
            type="option"
            :valueLabel="state.mainPageDocuments.selectedMainTypeLabel"
            optionValue="DOCUMENT_TYPE"
            class="w-full mr-4"
            endPoint=""
            displayValue=""
            searchable="true"
            :mandatory="true"
            :validated="true"
          >
          </ImFormInput>
        </div>
        <div class="form-control w-full mt-0">
          <ImFormInput
            v-model:inputValue="state.selectedSubType"
            v-model:parent="state.selectedSubTypeParent"
            :title="$t('message.subType')"
            type="option"
            :valueLabel="state.selectedSubTypeLabel"
            optionValue="DOCUMENT_SUB_TYPE"
            class="w-full"
            endPoint=""
            displayValue=""
            :onchangeMethod="
              (options, optionsRepo, value) => {
                options.value = optionsRepo.filter((elem) => elem.parent == value);
              }
            "
            searchable="true"
            :mandatory="true"
            :validated="true"
            :disabled="disabled"
          >
          </ImFormInput>
          <!-- eslint-enable -->
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.contractIdentifier') }} </span>
          </label>
          <input
            v-model="state.contractIdentifier"
            type="text"
            class="input input-sm input-bordered w-full"
            :disabled="disabledContractId"
          />
        </div>
      </div>
      <input
        class="file:p-2 file:bg-gray-800 file:border-none file:hover:cursor-pointer w-full text-sm text-gray-200 border border-gray-300 rounded-md bg-gray-50 dark:text-gray-200 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-8"
        aria-describedby="file_input_help"
        :accept="allowedExtensions"
        id="file_input"
        type="file"
        ref="docsInput"
      />

      <button
        v-if="building"
        @click="uploadBuildingFile"
        class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
      >
        {{ $t('message.upload') }}
      </button>
      <button
        v-if="!building"
        @click="uploadPropertyFile"
        class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
      >
        {{ $t('message.upload') }}
      </button>
      <hr class="mb-8" />
      <RobberVueGrid
        v-if="buildingId"
        ref="buildingDocumentsGrid"
        :config="buildingDocumentsGridConfigComputed"
      />
      <RobberVueGrid
        v-if="!buildingId"
        ref="propertyDocumentsGrid"
        :config="propertyDocumentsGridConfigComputed"
      />
      <input type="checkbox" class="modal-toggle" v-model="state.showRemoveConfirmation" />
      <div class="modal">
        <div class="modal-box">
          <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
            {{ $t('message.confirmRemovePartner') }}
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t('message.deny') }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deleteDocument(state.rvgDocumentId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t('message.confirm') }}</label
            >
          </div>
        </div>
      </div>
      <EditDocumentModal
        :propertyGrid="propertyDocumentsGrid"
        :buildingGrid="buildingDocumentsGrid"
        :showModal="modalStatus"
        :id="state.rvgDocumentId"
      />
    </div>
    <!--MAIN PAGE DOCUMENTS-->
    <div
      class="space-y-6 overflow-x-hidden p-6 im-card-bg"
      v-if="props.title == 'documentsMainPage'"
    >
      <div class="flex flex-row gap-3 w-full my-4 px-3">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.name') }}</span>
          </label>
          <input
            v-model="state.mainPageDocuments.name"
            type="text"
            class="input input-sm input-bordered w-full th"
          />
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.fileType') }}</span>
          </label>
          <v-select
            class="border border-gray-700 rounded-lg"
            :options="state.fileTypes"
            :class="{ hideOpenIndicator: state.mainPageDocuments.fileType != null }"
            v-model="state.mainPageDocuments.fileType"
          >
            <!-- eslint-disable -->
            <template v-slot:no-options>
              {{ $t('message.noMatchingOptions') }}
            </template>
            <!-- eslint-enable -->
          </v-select>
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="mainTypeSelector">
            <span class="label-text-alt">{{ $t('message.mainType') }}</span>
          </label>
          <FilteredSelectSearchable
            id="mainTypeSelector"
            v-model="state.mainPageDocuments.typeId"
            :suggestions="documentTypes"
          />
          <!-- eslint-disable -->
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="subTypeSelector">
            <span class="label-text-alt">{{ $t('message.subType') }}</span>
          </label>
          <FilteredSelectSearchable
            id="subTypeSelector"
            v-model="state.mainPageDocuments.subTypeId"
            hasParent="true"
            :suggestions="documentSubTypes"
            :parent-id="state.mainPageDocuments.typeId"
          />
          <!-- eslint-enable -->
        </div>
      </div>

      <div class="flex flex-row gap-3 w-full px-3">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{
              $t('message.upload') + ' (' + $t('message.startDate') + ')'
            }}</span>
          </label>
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd HH:mm"
              :enable-time-picker="true"
              :time-picker-inline="true"
              :locale="$i18n.locale"
              :month-change-on-scroll="false"
              :action-row="{}"
              :clearable="true"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :teleport="true"
              model-type="yyyy-MM-dd HH:mm"
              :placeholder="t('message.choose')"
              v-model="state.mainPageDocuments.fromDate"
              dark="true"
              @update:model-value="checkDates"
            />
          </label>
        </div>

        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{
              $t('message.upload') + ' (' + $t('message.endDate') + ')'
            }}</span>
          </label>
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd HH:mm"
              :enable-time-picker="true"
              :time-picker-inline="true"
              locale="hu"
              :month-change-on-scroll="false"
              :action-row="{}"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :clearable="true"
              :teleport="true"
              model-type="yyyy-MM-dd HH:mm"
              :placeholder="t('message.choose')"
              v-model="state.mainPageDocuments.toDate"
              dark="true"
              @update:model-value="checkDates"
            />
          </label>
        </div>
        <!--eslint-disable-->
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.uploader') }}</span>
          </label>
          <SearchOptions v-model:inputValue="state.mainPageDocuments.uploaderId" type="uploader" />
        </div>

        <div class="form-control w-full">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.floc') }}</span>
          </label>
          <input
            v-model="state.mainPageDocuments.floc"
            type="text"
            class="input input-sm input-bordered w-full th"
          />
        </div>
      </div>

      <div class="flex flex-row gap-3 w-full my-0 px-3">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{
              $t('message.dateOfOrigin') + ' (' + $t('message.startDate') + ')'
            }}</span>
          </label>
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd"
              :enable-time-picker="false"
              :time-picker-inline="true"
              locale="hu"
              :month-change-on-scroll="false"
              :action-row="{}"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :clearable="true"
              :teleport="true"
              model-type="yyyy-MM-dd"
              :placeholder="t('message.choose')"
              v-model="state.mainPageDocuments.originDateFrom"
              dark="true"
              @update:model-value="checkDates"
            />
          </label>
        </div>

        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{
              $t('message.dateOfOrigin') + ' (' + $t('message.endDate') + ')'
            }}</span>
          </label>
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd"
              :enable-time-picker="false"
              :time-picker-inline="true"
              locale="hu"
              :month-change-on-scroll="false"
              :action-row="{}"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :clearable="true"
              :teleport="true"
              model-type="yyyy-MM-dd"
              :placeholder="t('message.choose')"
              v-model="state.mainPageDocuments.originDateTo"
              dark="true"
              @update:model-value="checkDates"
            />
          </label>
        </div>

        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.kng') }}</span>
          </label>
          <input
            v-model="state.mainPageDocuments.kng"
            type="text"
            class="input input-sm input-bordered w-full th"
          />
        </div>
      </div>

      <div class="flex flex-row gap-3 w-full my-0 px-3">
        <!--eslint-disable-->
        <div class="form-control w-full mt-0 mb-2">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.address') }}</span>
          </label>
          <SearchOptions
            v-model:inputValue="state.mainPageDocuments.propertyAddressId"
            type="address"
          />
        </div>

        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.contractIdentifier') }}</span>
          </label>
          <input
            v-model="state.mainPageDocuments.contractIdentifier"
            type="text"
            class="input input-sm input-bordered w-full th"
          />
        </div>

        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ $t('message.workflowIdentifier') }}</span>
          </label>
          <input
            v-model="state.mainPageDocuments.workflowTaskIdentifier"
            type="text"
            class="input input-sm input-bordered w-full th"
          />
        </div>
      </div>

      <RobberVueGrid
        ref="documentsMainPageGrid"
        :config="documentsMainPageGridConfigComputed"
      />
      <EditDocumentModal
        @refreshGrid="refreshGrid"
        :showModal="modalStatus"
        :id="state.rvgDocumentId"
      />
      <div style="height: 2rem"></div>
    </div>
    <!-- PREVIEW MODAL -->
    <input
      type="checkbox"
      id="document_preview"
      class="modal-toggle"
      v-model="state.documentPreview.open"
    />
    <div class="modal" role="dialog">
      <div class="modal-box im-preview-modal-box">
        <h3 class="text-lg font-bold mb-4">{{ state.documentPreview.title }}</h3>
        <iframe class="w-full" style="height: 80%" :src="state.documentPreview.url"></iframe>
        <div class="modal-action">
          <label for="document_preview" class="btn"> {{ $t('message.close') }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import { onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/misc/vuex-store';
import Utils from '../../misc/Utils';
import axios from '@/misc/axios.js';
import { useRoute } from 'vue-router';
import FilteredSelectSearchable from '../FilteredSelectSearchable.vue';
import RobberVueGrid from '@/components/RVG/RobberVueGrid.vue';
import buildingDocumentsGridConfig from '@/misc/gridConfigs/buildingPage/documentsGridConfig.js';
import propertyDocumentsGridConfig from '@/misc/gridConfigs/propertyPage/documentsGridConfig.js';
import documentsMainPageGridConfig from '@/misc/gridConfigs/documentsMainPageGridConfig.js';
import moment from 'moment';
import SearchOptions from '../SearchOptions.vue';
import EditDocumentModal from './EditDocumentModal.vue';
import buildingUtils from '../../pages/BuildingPageComponents/buildingUtils';
import propertyUtils from '../../pages/PropertyPageComponents/propUtils';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import RVGUtils from '../RVG/RobberVueGridUtils.js';
import ImFormInput from '../ImFormInput.vue';
import toast from '@/misc/toast';

const props = defineProps(['title', 'property', 'building']);

const buildingDocumentsGrid = ref();
const propertyDocumentsGrid = ref();

const { t } = useI18n();
const route = useRoute();
const propertyId = route.params.propertyId;
const buildingId = route.params.buildingId;

const docsInput = ref('');
const documentsMainPageGrid = ref(null);
const disabled = ref(true);
const disabledContractId = ref(true);

const state = ref({
  showRemoveConfirmation: false,
  selectedMainType: '',
  selectedMainTypeLabel: '',
  selectedSubType: '',
  selectedSubTypeLabel: '',
  selectedSubTypeParent: '',
  documents: [],
  docuMainType: '',
  docuSubType: '',
  docuName: '',
  contractIdentifier: '',
  docuOriginDate: '',
  uploadAt: null,
  fileTypes: [],
  mainPageDocuments: {
    name: '',
    fileType: '',
    uploaderId: null,
    typeId: null,
    subTypeId: null,
    fromDate: '',
    toDate: '',
    originDateFrom: '',
    originDateTo: '',
    kng: '',
    floc: '',
    propertyAddressId: null,
    contractIdentifier: '',
    workflowTaskIdentifier: '',
    documentTypeLabel: '',
    documentSubTypeLabel: '',
    documentSubTypeParent: '',
  },
  documentPreview: {
    title: '',
    url: '',
    open: false,
  },
});

const correctDate = ref(true);

const checkDates = () => {
  if (
    (state.value.mainPageDocuments.originDateFrom != '' &&
      state.value.mainPageDocuments.originDateTo != '' &&
      state.value.mainPageDocuments.originDateFrom > state.value.mainPageDocuments.originDateTo) ||
    (state.value.mainPageDocuments.fromDate != '' &&
      state.value.mainPageDocuments.toDate != '' &&
      state.value.mainPageDocuments.fromDate > state.value.mainPageDocuments.toDate)
  ) {
    correctDate.value = false;
    toast.error(t('message.startDateLaterThanEndDate'));
  } else {
    correctDate.value = true;
  }
};

const documentTypes = ref([]);
const documentSubTypes = ref([]);
const documentTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(documentTypes.value);
});
const documentSubTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(documentSubTypes.value);
});

const editDocument = async (id) => {
  const response = await Utils.fetchDocumentById(id);

  store.commit('setIsModalOpen', true);
  store.commit('setDocumentById', response);
  state.value.rvgDocumentId = id;
};

const downloadDoc = (id) => {
  axios
    .get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/document/${id}/download`, {
      responseType: 'blob',
    })
    .then((response) => {
      let url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.data.type,
        })
      );
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        Utils.parseContentDispositionToFileName(response.headers['content-disposition'])
      );
      document.body.appendChild(link);
      link.click();
    });
};

const uploadBuildingFile = async () => {
  if (!state.value.selectedMainType) {
    toast.error(t('message.mainType') + t('message.mandatoryField'));
    return;
  } else if (!state.value.selectedSubType) {
    toast.error(t('message.subType') + t('message.mandatoryField'));
    return;
  }

  if (!state.value.docuOriginDate || state.value.docuOriginDate == '') {
    toast.error(t('message.dateOfOrigin') + t('message.mandatoryField'));
    return;
  }

  if (!docsInput.value.files[0]) {
    toast.error(t('message.document') + t('message.choosingMandatory'));
    return;
  }

  await buildingUtils.uploadFile(
    state,
    docsInput,
    t,
    buildingId,
    buildingDocumentsGrid,
    allowedExtensions
  );
  const fileInput = document.getElementById('file_input');
  fileInput.value = '';
};

const uploadPropertyFile = async () => {
  if (!state.value.selectedMainType) {
    toast.error(t('message.mainType') + t('message.mandatoryField'));
    return;
  } else if (!state.value.selectedSubType) {
    toast.error(t('message.subType') + t('message.mandatoryField'));
    return;
  }

  if (!state.value.docuOriginDate || state.value.docuOriginDate == '') {
    toast.error(t('message.dateOfOrigin') + t('message.mandatoryField'));
    return;
  }

  if (!docsInput.value.files[0]) {
    toast.error(t('message.document') + t('message.choosingMandatory'));
    return;
  }

  await propertyUtils.uploadFile(
    state,
    docsInput,
    t,
    propertyId,
    propertyDocumentsGrid,
    allowedExtensions
  );
  const fileInput = document.getElementById('file_input');
  fileInput.value = '';
};

const allowedExtensions = computed(() => {
  return store.getters.getAllowedExtensions;
});

const filterFileTypes = computed(() => {
  return store.getters.getAllowedFileFilters;
});
const fillFileTypes = () => {
  state.value.fileTypes = filterFileTypes;
};

const fillTypeMappings = async () => {
  [documentTypes.value, documentSubTypes.value] = await Promise.all([
    Utils.getAllOptions('DOCUMENT_TYPE'),
    Utils.getAllOptions('DOCUMENT_SUB_TYPE'),
  ]);
};

const buildingDocumentsGridConfigComputed = computed(() => {
  return buildingDocumentsGridConfig(
    state,
    t,
    buildingId,
    editDocument,
    downloadDoc,
    documentTypeMapping.value,
    documentSubTypeMapping.value
  );
});

const propertyDocumentsGridConfigComputed = computed(() => {
  return propertyDocumentsGridConfig(
    state,
    t,
    propertyId,
    editDocument,
    downloadDoc,
    documentTypeMapping.value,
    documentSubTypeMapping.value
  );
});

const documentsMainPageGridConfigComputed = computed(() => {
  return documentsMainPageGridConfig(
    t,
    state.value.mainPageDocuments.name,
    state.value.mainPageDocuments.fileType,
    state.value.mainPageDocuments.uploaderId,
    state.value.mainPageDocuments.typeId,
    state.value.mainPageDocuments.subTypeId,
    state.value.mainPageDocuments.fromDate ? moment(state.value.mainPageDocuments.fromDate) : null,
    state.value.mainPageDocuments.toDate ? moment(state.value.mainPageDocuments.toDate) : null,
    state.value.mainPageDocuments.originDateFrom
      ? moment(state.value.mainPageDocuments.originDateFrom)
      : null,
    state.value.mainPageDocuments.originDateTo
      ? moment(state.value.mainPageDocuments.originDateTo)
      : null,
    state.value.mainPageDocuments.kng,
    state.value.mainPageDocuments.floc,
    state.value.mainPageDocuments.propertyAddressId,
    state.value.mainPageDocuments.contractIdentifier,
    state.value.mainPageDocuments.workflowTaskIdentifier,
    state.value.documentPreview,
    editDocument,
    downloadDoc,
    documentTypeMapping.value,
    documentSubTypeMapping.value
  );
});

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

const refreshGrid = () => {
  documentsMainPageGrid.value?.gridRefresh(true);
  buildingDocumentsGrid.value?.refresh();
  propertyDocumentsGrid.value?.refresh();
};

watch(
  [
    () => state.value.mainPageDocuments.typeId,
    () => state.value.mainPageDocuments.subTypeId,
    () => state.value.mainPageDocuments.name,
    () => state.value.mainPageDocuments.fileType,
    () => state.value.mainPageDocuments.uploaderId,
    () => state.value.mainPageDocuments.fromDate,
    () => state.value.mainPageDocuments.toDate,
    () => state.value.mainPageDocuments.originDateFrom,
    () => state.value.mainPageDocuments.originDateTo,
    () => state.value.mainPageDocuments.kng,
    () => state.value.mainPageDocuments.floc,
    () => state.value.mainPageDocuments.propertyAddressId,
    () => state.value.mainPageDocuments.contractIdentifier,
    () => state.value.mainPageDocuments.workflowTaskIdentifier,
  ],
  () => {
    if (correctDate.value) {
      refreshGrid();
    }
  }
);

watch(
  () => state.value.mainPageDocuments.typeId,
  () => {
    if (
      state.value.mainPageDocuments.typeId == null ||
      state.value.mainPageDocuments.typeId == ''
    ) {
      state.value.mainPageDocuments.subTypeId = null;
    }
  }
);

watch(
  () => state.value.selectedMainType,
  () => {
    state.value.selectedSubTypeParent = state.value.selectedMainType;
    disabled.value = false;
    if (!state.value.selectedMainType) {
      state.value.selectedSubType = null;
      disabled.value = true;
    }
  }
);

watch(
  () => state.value.selectedSubType,
  () => {
    disabledContractId.value = false;
    if (!state.value.selectedSubType) {
      state.value.contractIdentifier = null;
      disabledContractId.value = true;
    }
  }
);

onMounted(() => {
  fillFileTypes();
  fillTypeMappings();
});
</script>

<style scoped>
input[disabled] {
  @apply border border-gray-600;
}
.th {
  height: 2.3rem;
}

.dp__theme_dark {
  --dp-background-color: hsl(220, 17.647%, 20%);
  @apply border border-gray-700 rounded-md text-sm;
}

input[type='file'],
textarea {
  background-color: hsl(220, 17.647%, 20%);
}

.im-card-bg {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  overflow: hidden;
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
  overflow: auto;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}

.im-preview-modal-box {
  width: 75%;
  min-width: 75%;
  height: 75%;
}

.im-mandatory {
  color: red;
}
</style>
