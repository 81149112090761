import axios from '@/misc/axios';
import toast from '@/misc/toast.js';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import Utils from '@/misc/Utils';

export default {
  getWorkflow: async (taskTypeId, workflowId) => {
    const uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}`;

    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.getWorkflow(taskTypeId, workflowId);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  exportWf: async (taskTypeId, workflowId) => {
    const uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/export`;
    try {
      axios
        .get(uri, {
          responseType: 'blob',
        })
        .then((response) => {
          let url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.data.type,
            })
          );
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            Utils.parseContentDispositionToFileName(response.headers['content-disposition'])
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      console.log(error);
    }
  },
  importWf: async (taskTypeId, importInput, emptyForm, t) => {
    const uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/import`;

    const formData = new FormData();
    formData.append('inputFile', importInput.value.files[0]);
    try {
      await axios.post(uri, formData);
      toast.success(t('message.importSuccess'));
      emptyForm();
    } catch (error) {
      console.log(error);
      toast.error(t('message.importError'));
    }
  },
  saveStateOrder: async (taskTypeId, workflowId, sortedStates, t) => {
    const uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/states`;

    try {
      await axios.patch(uri, sortedStates, {
        onTokenRefreshed: () => {
          this.saveStateOrder(taskTypeId, workflowId, sortedStates);
        },
      });
      toast.success(t('message.sortingSuccess'));
    } catch (error) {
      console.log(error);
      toast.error(t('message.sortingError'));
    }
  },
  saveTransitionOrder: async (taskTypeId, workflowId, sortedTransitions, fromStateId, t) => {
    const uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transitions/fromState/${fromStateId}`;

    try {
      await axios.patch(uri, sortedTransitions, {
        onTokenRefreshed: () => {
          this.saveStateOrder(taskTypeId, workflowId, sortedTransitions, fromStateId);
        },
      });
      toast.success(t('message.sortingSuccess'));
    } catch (error) {
      console.log(error);
      toast.error(t('message.sortingError'));
    }
  },
  getTransition: async(taskTypeId, workflowId, transitionId) => {
    const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${transitionId}`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        this.getTransition(transitionId);
      },
    });

    return response.data  
  } catch (error) {
    console.log(error);
  }
  },
  setOnlyResponsible: async (taskTypeId, workflowId, transitionId, respOnly, transitionData, t) => {
    const uri =
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${transitionId}`;

    const hermes = {
      fromStateId: transitionData.fromStateId,
      toStateId: transitionData.toStateId,
      actionName: transitionData.actionName,
      workflowActionCode: transitionData.workflowActionCode,
      onlyResponsible: respOnly,
    };

    try {
      await axios.put(uri, hermes, {
        onTokenRefreshed: () => {
          this.setOnlyResponsible(taskTypeId, workflowId, transitionId, respOnly, transitionData);
        },
      });
      toast.success(t('message.successfulSave'));
    } catch (error) {
      console.log(error);
      toast.error(t('message.notSuccessfulSave'));
    }
  },
};
