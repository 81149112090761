export default {
  title: "property",
  groupName: "Ingatlan adatai",
  typeIds: [5],
  data: [],
  form: [
    {
      id: "",
      customFieldId: "",
      backendType: "NUMBER",
      title: "property", // INGATLAN
      model: "PROPERTY_ID",
    metaDataValueType: "NUMBER",
      value: [],
    },
    {
      id: "",
      customFieldId: "",
      backendType: "NUMBER",
      title: "building", // INGATLAN
      model: "BUILDING_ID",
      metaDataValueType: "NUMBER",
      value: [],
      
    },
    {
      id: "",
      customFieldId: "",
      backendType: "NUMBER",
      title: "premise", // INGATLAN
      model: "PREMISE_ID",
      metaDataValueType: "NUMBER",
      value: [],
      
    },
  ],
};
