<template>
  <div class="space-y-6 p-6 pb-12 my-3 im-card-bg rounded-lg overflow-y-visible">
    <div class="flex flex-row gap-3 w-full my-4 px-3">
      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{ $t('message.taskIdentifier') }}</span>
        </label>
        <input
          v-model="state.taskIdentifier"
          type="text"
          class="input input-sm input-bordered w-full th"
        />
        <!-- eslint-enable -->
      </div>
      <div class="form-control w-full mt-0" :class="{ hideOpenIndicator: state.taskType != null }">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{ $t('message.taskType') }}</span>
        </label>
        <div class="dropdown w-full flex flex-row justify-between">
          <select class="input input-sm input-bordered w-full th" v-model="state.taskType">
            <option v-for="taskType in state.taskTypes" v-bind:key="taskType.id" :value="taskType">
              {{ taskType.name }}
            </option>
          </select>
          <button
            v-if="state.taskType"
            class="clear-button"
            @click="state.taskType = null"
            aria-label="Törlés"
          >
            <span class="text-sm bold"> X </span>
          </button>
        </div>
      </div>
      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{ $t('message.subject') }}</span>
        </label>
        <input
          v-model="state.subject"
          type="text"
          class="input input-sm input-bordered w-full th"
        />
      </div>
      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{ $t('message.state') }}</span>
        </label>
        <input v-model="state.state" type="text" class="input input-sm input-bordered w-full th" />
      </div>
      <!--eslint-disable-->
    </div>

    <div class="flex flex-row gap-3 w-full px-3">
      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{ $t('message.responsible') }}</span>
        </label>
        <SearchOptions v-model:inputValue="state.responsible" type="uploader" class="th" />
      </div>

      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{ $t('message.first_approval_user_id') }}</span>
        </label>
        <SearchOptions v-model:inputValue="state.firstApprover" type="uploader" class="th" />
      </div>

      <div class="form-control w-full">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{ $t('message.second_approval_user_id') }}</span>
        </label>
        <SearchOptions v-model:inputValue="state.secondApprover" type="uploader" class="th" />
      </div>
    </div>

    <div class="flex flex-row gap-3 px-3">
      <div class="form-control mt-0 w-1/4">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{
            $t('message.deadline') + ' (' + $t('message.startDate') + ')'
          }}</span>
        </label>
        <label class="flex flex-row gap-2">
          <VueDatePicker
            format="yyyy MMM dd"
            :enable-time-picker="false"
            :time-picker-inline="true"
            :locale="$i18n.locale"
            :month-change-on-scroll="false"
            :action-row="{}"
            :clearable="true"
            select-text="OK"
            :cancel-text="t('message.cancel')"
            :teleport="true"
            model-type="yyyy-MM-dd"
            :placeholder="t('message.choose')"
            v-model="state.deadlineFrom"
            dark="true"
            @update:model-value="checkDates"
          />
        </label>
      </div>

      <div class="form-control w-1/4 mt-0">
        <label class="label mt-0 pt-0" for="">
          <span class="label-text-alt">{{
            $t('message.deadline') + ' (' + $t('message.endDate') + ')'
          }}</span>
        </label>
        <label class="flex flex-row gap-2">
          <VueDatePicker
            format="yyyy MMM dd"
            :enable-time-picker="false"
            :time-picker-inline="true"
            locale="hu"
            :month-change-on-scroll="false"
            :action-row="{}"
            select-text="OK"
            :cancel-text="t('message.cancel')"
            :clearable="true"
            :teleport="true"
            model-type="yyyy-MM-dd"
            :placeholder="t('message.choose')"
            v-model="state.deadlineTo"
            dark="true"
            @update:model-value="checkDates"
          />
        </label>
      </div>

      <div class="px-3 my-7 pt-1">
        <div class="flex flex-row gap-3">
          <input v-model="state.ownTasks" type="checkbox" id="checkbox" class="" />
          <label for="checkbox">{{ $t('message.ownTasksOnly') }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SearchOptions from '@/components/SearchOptions.vue';
import toast from '@/misc/toast';
import VueDatePicker from '@vuepic/vue-datepicker';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import workflowTaskUtils from './workflowTaskUtils';
const { t } = useI18n();

const emits = defineEmits(['refreshGrid', 'updateFilters']);

const state = ref({
  taskIdentifier: null,
  taskType: null,
  subject: null,
  state: null,
  responsible: null,
  firstApprover: null,
  secondApprover: null,
  deadlineFrom: null,
  deadlineTo: null,
  ownTasks: true,
  taskTypes: [],
});
const correctDate = ref(true);

const getTaskTypes = async () => {
  state.value.taskTypes = await workflowTaskUtils.getTaskTypes();
};

const checkDates = () => {
  if (
    state.value.deadlineFrom != '' &&
    state.value.deadlineTo != '' &&
    state.value.deadlineFrom > state.value.deadlineTo
  ) {
    correctDate.value = false;
    toast.error(t('message.startDateLaterThanEndDate'));
  } else {
    correctDate.value = true;
  }
};

watch(
  [
    () => state.value.taskIdentifier,
    () => state.value.taskType,
    () => state.value.subject,
    () => state.value.state,
    () => state.value.responsible,
    () => state.value.firstApprover,
    () => state.value.secondApprover,
    () => state.value.deadlineFrom,
    () => state.value.deadlineTo,
    () => state.value.ownTasks,
  ],
  () => {
    if (correctDate.value) {
      console.log(state.value.taskType);
      emits('refreshGrid');
      emits('updateFilters', { ...state.value });
    }
  }
);

onMounted(() => {
  getTaskTypes();
  emits('updateFilters', { ...state.value });
});
</script>

<style scoped>
.th {
  height: 2.3rem;
}

.dp__theme_dark {
  --dp-background-color: hsl(220, 17.647%, 20%);
  @apply border border-gray-700 rounded-md text-sm;
}

input[type='file'],
textarea {
  background-color: hsl(220, 17.647%, 20%);
}

.im-card-bg {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  overflow: hidden;
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
  overflow: auto;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}

.im-preview-modal-box {
  width: 75%;
  min-width: 75%;
  height: 75%;
}

.im-mandatory {
  color: red;
}

select[disabled] {
  @apply border border-gray-600;
}

.im-dropdown-content {
  position: absolute;
}

.im-invisible {
  display: none;
}

.dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.clear-button {
  position: absolute;
  right: 0.6rem;
  background: none;
  border: none;
  color: rgb(175, 170, 170);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
}

.clear-button:hover {
  cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
  display: none;
}

.dropdown::before {
  position: absolute;
  font-size: 12px;
  content: ' \25BC';
  top: 7px;
  right: -8px;
  height: 20px;
  width: 28px;
  pointer-events: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.option-hover {
  padding: 5px 0 5px 5px;
  font-size: 0.875rem;
}

.option-hover:hover {
  background-color: rgb(147, 185, 247);
  color: #242933;
}

ul .option-hover:first-of-type {
  border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
  border-radius: 0 0 10px 10px;
}
</style>
