<template>
  <div>
    <!-- <p>Ebben a nézetben még nem elérhető adat.</p> -->
    <div v-for="event in events" :key="event.eventData?.updatedBasicFields?.basicFieldCode">
      <div v-for="update in event.eventData?.updatedBasicFields" :key="update.basicFieldCode">
        <div
          v-if="event.eventType == 'DATA_MODIFIED' && event.eventData != null"
          class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
        >
          <!-- <span>{{ update.basicFieldCode }}</span> -->
          <EventTime :dateString="event.triggeredAt" />
          <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
            <a class="font-semibold text-blue-600 dark:text-secondary">{{
              event.triggeredByUserName
            }}</a>
            {{ $t('message.changedData') }}: {{ $t(`message.${update.basicFieldCode}`) }} -
            <span class="font-semibold text-gray-900 dark:text-white"
              >{{
                update.oldValue != null &&
                update.basicFieldCode == 'TASK_PRIORITY' &&
                update.oldValue == 'LOW'
                  ? $t('message.PriorityLow')
                  : update.oldValue != null &&
                      update.basicFieldCode == 'TASK_PRIORITY' &&
                      update.oldValue == 'MEDIUM'
                    ? $t('message.PriorityMedium')
                    : update.oldValue != null &&
                        update.basicFieldCode == 'TASK_PRIORITY' &&
                        update.oldValue == 'HIGH'
                      ? $t('message.PriorityHigh')
                      : update.oldValue != null && update.oldValue != ''
                        ? update.oldValue
                        : $t('message.empty')
              }}
              >
            </span>
            <span class="font-semibold text-gray-900 dark:text-white"
              >{{
                update.newValue != null &&
                update.basicFieldCode == 'TASK_PRIORITY' &&
                update.newValue == 'LOW'
                  ? $t('message.PriorityLow')
                  : update.newValue != null &&
                      update.basicFieldCode == 'TASK_PRIORITY' &&
                      update.newValue == 'MEDIUM'
                    ? $t('message.PriorityMedium')
                    : update.newValue != null &&
                        update.basicFieldCode == 'TASK_PRIORITY' &&
                        update.newValue == 'HIGH'
                      ? $t('message.PriorityHigh')
                      : update.newValue != null && update.newValue != ''
                        ? update.newValue
                        : $t('message.empty')
              }}
            </span>
          </div>
        </div>
      </div>

      <div v-for="update in event.eventData?.updatedCustomFields" :key="update.customFieldId">
        <div
          v-if="
            event.eventType == 'DATA_MODIFIED' &&
            event.eventData != null &&
            update.metaDataType != 'MULTIPLE'
          "
          class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
        >
          <!-- <span>{{ update.basicFieldCode }}</span> -->
          <EventTime :dateString="event.triggeredAt" />
          <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
            <a class="font-semibold text-blue-600 dark:text-secondary">{{
              event.triggeredByUserName
            }}</a>
            {{ $t('message.changedData') }}: {{ update.customFieldName }} -
            <span class="font-semibold text-gray-900 dark:text-white"
              >{{
                update.oldValue != null && update.oldValue != ''
                  ? update.oldValue
                  : $t('message.empty')
              }}
              >
            </span>
            <span class="font-semibold text-gray-900 dark:text-white">{{
              update.newValue != null && update.newValue != ''
                ? update.newValue
                : $t('message.empty')
            }}</span>
          </div>
        </div>
      </div>

      <div v-for="update in event.eventData?.updatedCustomFields" :key="update.customFieldId">
        <div
          v-if="
            event.eventType == 'DATA_MODIFIED' &&
            event.eventData != null &&
            update.metaDataType == 'MULTIPLE'
          "
          class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
        >
          <!-- <span>{{ update.basicFieldCode }}</span> -->
          <EventTime :dateString="event.triggeredAt" />
          <div
            v-if="update.newValue != null"
            class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300"
          >
            <a class="font-semibold text-blue-600 dark:text-secondary">{{
              event.triggeredByUserName
            }}</a>
            {{
              update.customFieldName == 'Ingatlan adatai'
                ? $t('message.attachedProperties')
                : update.customFieldName == 'Épület adatai'
                  ? $t('message.attachedBuildings')
                  : update.customFieldName == 'Helyiség adatai'
                    ? $t('message.attachedPremises')
                    : update.customFieldName
            }}:
            <span class="font-semibold text-gray-900 dark:text-white">{{
              update.newValue != null && update.newValue != ''
                ? update.newValue
                : $t('message.empty')
            }}</span>
          </div>

          <div
            v-if="update.deletedValue != null"
            class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300"
          >
            <a class="font-semibold text-blue-600 dark:text-secondary">{{
              event.triggeredByUserName
            }}</a>
            {{
              update.customFieldName == 'Ingatlan adatai'
                ? $t('message.deattachedProperties')
                : update.customFieldName == 'Épület adatai'
                  ? $t('message.deattachedBuildings')
                  : update.customFieldName == 'Helyiség adatai'
                    ? $t('message.deattachedPremises')
                    : update.customFieldName
            }}:
            <span class="font-semibold text-gray-900 dark:text-white">{{
              update.deletedValue != null && update.deletedValue != ''
                ? update.deletedValue
                : $t('message.empty')
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import store from '@/misc/vuex-store';
import EventTime from './components/EventTime.vue';

const events = computed(() => {
  return store.getters.getEvent;
});
</script>
