import toast from '@/misc/toast.js'

let uniqueField = 'partnersGrid';

export default (t, type, id, partnerMainTypeMapping, partnerSubTypeMapping, assignFunc, endpointBase, name, mainType, subType) => {
  return {
    endpoint: endpointBase ? endpointBase 
    + (name ? `?name=${name}` : '')
    + (mainType && mainType != 'null' ? `&mainType=${mainType}` : '')
    + (subType && subType != 'null' ? `&subType=${subType}` : '') : null,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100]
    },
    events: {
      onDataLoad: (records) => {
        if(records.length === 0)
        {
          toast.error(t('message.withGiveDataNoPartnerFound'));
        }
        return records;
      }
    },
    filtering: {
      active: false,
      local: false,
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: false,
      gridUniqueId: uniqueField,
      mode: 'server',
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: false
    },
    refreshable: {
      manualActive: false,
      autoActive: false,
      autoCanBeDisabled: false,
      gridUniqueId: uniqueField,
      autoValues: []
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              let buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.attach'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="white" stroke="currentColor" stroke-width="0.2" class="w-4 h-4" viewBox="0 0 24 24"><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'partnerEditButton',
                dropdowned: false,
                onClick: () => {
                  assignFunc(record.id);
                }
              });
              return buttons;
            }
          }
        }
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "name": {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.name ? record.name : 'N/A'
        }
      },
      "partnerMainTypeValue": {
        title: t("message.mainType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.partnerMainTypeValue ? record.partnerMainTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: partnerMainTypeMapping,
        },             
      },
      "partnerSubTypeValue": {
        title: t("message.subType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.partnerSubTypeValue ? record.partnerSubTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: partnerSubTypeMapping,
        },             
      },
      "privatePerson": {
        title: t("message.privateIndividual"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "taxId": {
        title: t("message.taxId"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
      },
      "taxNumber": {
        title: t("message.taxNumber"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        middleware: (value) => {    
          return value || 'N/A'
        },
        exportable: true,
        orderable: true,
      },
    }
  }
}
