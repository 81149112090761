<template>
  <div id="BuildingPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="h-fit px-2 overflow-y-auto mx-30 k">
          <!--Header-->
          <RemFormLayout>
            <template #remformheader>
              <header class="w-full">
                <div class="items-start pb-6 mx-auto md:flex-row mb-4">
                  <div class="flex justify-between mb-4">
                    <div class="flex flex-row">
                      <h1 class="flex mb text-xl text-secondary md:mb-0 uppercase font-bold">
                        {{ $t('message.building') }} -
                        {{ state.building?.basicDataTcafm ?? 'N/A' }}
                        - ({{ state.property ? state.property.primaryAddressCompleteAddress : '' }})
                      </h1>
                      <span @click="toLevelSelector">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-8 h-8 pl-2 pb-1 text-magenta hover:text-gray-200 hover:cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                          />
                        </svg>
                      </span>
                    </div>

                    <RemUpButton remType="building" />
                  </div>
                  <hr class="mb-8 mt-2" />
                  <div class="flex">
                    <button
                      @click="route.push({ name: 'energeticsPage' })"
                      class="flex btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t('message.energetics') }}
                    </button>
                  </div>
                </div>
              </header>
            </template>
            <template #remformsidemenu>
              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(1, 'building-premises');
                      setRoute('building-premises');
                    }
                  "
                  :activeCheck="router.name == 'building-premises'"
                  :icon="'premise_icon'"
                  :buttonLabel="'premises'"
                ></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(2, 'building-legal-situations');
                      setRoute('building-legal-situations');
                    }
                  "
                  :activeCheck="router.name == 'building-legal-situations'"
                  :icon="'legal_icon'"
                  :buttonLabel="'legalSituations'"
                ></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(3, 'building-utilizations');
                      setRoute('building-utilizations');
                    }
                  "
                  :activeCheck="router.name == 'building-utilizations'"
                  :icon="'system_settings_icon'"
                  :buttonLabel="'utilizations'"
                ></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(4, 'building-meters');
                      setRoute('building-meters');
                    }
                  "
                  :activeCheck="router.name == 'building-meters'"
                  :icon="'meter_icon'"
                  :buttonLabel="'meters'"
                ></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(5, 'dataAndMtResp');
                      setRoute('dataAndMtResp');
                    }
                  "
                  :activeCheck="router.name == 'dataAndMtResp'"
                  :icon="'data_icon'"
                  :buttonLabel="'dataAndMtResp'"
                ></SideMenuButton>
              </div>
              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(7, 'building-documents');
                      setRoute('building-documents');
                    }
                  "
                  :activeCheck="router.name == 'building-documents'"
                  :icon="'document_icon'"
                  :buttonLabel="'documents'"
                ></SideMenuButton>
              </div>
              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(8, 'structure-types');
                      setRoute('structure-types');
                      state.structEdit = true;
                    }
                  "
                  :activeCheck="router.name == 'structure-types'"
                  :icon="'struct_types_icon'"
                  :buttonLabel="'structureTypes'"
                ></SideMenuButton>
              </div>
              <div class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(13, 'building-partners');
                      setRoute('building-partners');
                    }
                  "
                  :activeCheck="router.name == 'building-partners'"
                  :icon="'partner_icon'"
                  :buttonLabel="'partners'"
                ></SideMenuButton>
              </div>
              <div v-if="User.hasPermission('WorkflowUser')" class="flex-row mb-4">
                <SideMenuButton
                  :onClick="
                    () => {
                      setTab(16, 'building-linked-tasks');
                      setRoute('building-linked-tasks');
                    }
                  "
                  :activeCheck="router.name == 'building-linked-tasks'"
                  :icon="'linked_tasks_icon'"
                  :buttonLabel="'relatedTasks'"
                ></SideMenuButton>
              </div>
            </template>
            <template #remformmain>
              <!-------------LISTING PREMISES---------------->
              <!-- eslint-disable -->
              <RemRVGForm
                v-if="
                  router.name == 'building-premises' &&
                  store.getters.getRemTab != 10 &&
                  !store.getters.getRemFormEdit
                "
                :title="$t('message.premises')"
                rvgTab="building-premises"
                :addNewMethod="
                  () => {
                    addNewTab('premise', 10);
                    
                  }
                "
                :rvgConfig="premiseGridConfigComputed"
                v-model:elementId="state.premiseId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!--------------ADD/EDIT PREMISE--------------->
              <RemFormCard v-if="store.getters.getRemTab == 10">
                <template #remcardheader>
                  <RemFormBackbutton
                    :method="
                      () => {
                        setTab(1);
                      }
                    "
                  ></RemFormBackbutton>
                  <h2
                    v-if="store.getters.getRemFormEdit == true"
                    class="text-xl text-secondary w-full font-bold"
                  >
                    {{ $t('message.editPremise') }}
                  </h2>
                  <h2
                    v-if="store.getters.getRemFormEdit == false"
                    class="text-xl text-secondary w-full font-bold"
                  >
                    {{ $t('message.premise') }}
                  </h2>
                </template>
                <template #remcardmain>
                  <div>
                    <div v-for="item in state.premiseData" v-bind:key="item.model" class="mb-8">
                      <div v-if="store.getters.getRemFormEdit == false">
                        <ImFormLabel :item="item"></ImFormLabel>
                      </div>
                      <div v-if="store.getters.getRemFormEdit == true">
                        <!-- eslint-disable -->
                        <ImFormInput
                          v-model:inputValue="item.value"
                          v-model:parent="item.parent"
                          :title="$t('message.' + item.title)"
                          :type="item.type"
                          :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue"
                          :endPoint="item.endPoint"
                          :displayValue="item.displayValue"
                          :searchable="item.searchable"
                          :validationMethod="item.validationMethod"
                          :onchangeMethod="item.onchangeMethod"
                          :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                        >
                        </ImFormInput>
                        <!-- eslint-enable -->
                      </div>
                    </div>

                    <div v-if="state.isStaire">
                      <h2 class="text-xl text-secondary w-full font-bold">
                        {{ $t('message.stairway') }}
                      </h2>
                      <hr class="mb-8" />
                      <div v-for="item in state.staircaseData" v-bind:key="item.model" class="mb-8">
                        <div v-if="store.getters.getRemFormEdit == false">
                          <ImFormLabel :item="item"></ImFormLabel>
                        </div>
                        <div v-if="store.getters.getRemFormEdit == true">
                          <!-- eslint-disable -->
                          <ImFormInput
                            v-model:inputValue="item.value"
                            v-model:parent="item.parent"
                            :title="$t('message.' + item.title)"
                            :type="item.type"
                            :valueLabel="item.valueLabel"
                            :optionValue="item.optionValue"
                            :endPoint="item.endPoint"
                            :displayValue="item.displayValue"
                            :searchable="item.searchable"
                            :validationMethod="item.validationMethod"
                            :onchangeMethod="item.onchangeMethod"
                            :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                          >
                          </ImFormInput>
                          <!-- eslint-enable -->
                        </div>
                      </div>
                    </div>

                    <div v-if="state.isElevator">
                      <h2 class="text-xl text-secondary w-full font-bold">
                        {{ $t('message.elevator') }}
                      </h2>
                      <hr class="mb-8" />
                      <div v-for="item in state.elevatorData" v-bind:key="item.model" class="mb-8">
                        <div v-if="store.getters.getRemFormEdit == false">
                          <ImFormLabel :item="item"></ImFormLabel>
                        </div>
                        <div v-if="store.getters.getRemFormEdit == true">
                          <!-- eslint-disable -->
                          <ImFormInput
                            v-model:inputValue="item.value"
                            v-model:parent="item.parent"
                            :title="$t('message.' + item.title)"
                            :type="item.type"
                            :valueLabel="item.valueLabel"
                            :optionValue="item.optionValue"
                            :endPoint="item.endPoint"
                            :displayValue="item.displayValue"
                            :searchable="item.searchable"
                            :validationMethod="item.validationMethod"
                            :onchangeMethod="item.onchangeMethod"
                            :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                          >
                          </ImFormInput>
                          <!-- eslint-enable -->
                        </div>
                      </div>
                    </div>

                    <h2 class="text-xl text-secondary w-full font-bold">
                      {{ $t('message.specialData') }}
                    </h2>
                    <hr class="mb-8" />

                    <div
                      v-for="item in state.premiseSpecialData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <div v-if="store.getters.getRemFormEdit == false">
                        <ImFormLabel :item="item"></ImFormLabel>
                      </div>
                      <div v-if="store.getters.getRemFormEdit == true">
                        <!-- eslint-disable -->
                        <ImFormInput
                          v-model:inputValue="item.value"
                          v-model:parent="item.parent"
                          :title="$t('message.' + item.title)"
                          :type="item.type"
                          :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue"
                          :endPoint="item.endPoint"
                          :displayValue="item.displayValue"
                          :searchable="item.searchable"
                          :validationMethod="item.validationMethod"
                          :onchangeMethod="item.onchangeMethod"
                          :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                        >
                        </ImFormInput>
                        <!-- eslint-enable -->
                      </div>
                    </div>

                    <!-- eslint-disable -->
                    <RemFormButtonRowStored :saveMethod="savePremise" :deleteMethod="deletePremise" v-model:elementId="state.premiseId">
                    </RemFormButtonRowStored>
                    <!-- eslint-enable -->
                  </div>
                </template>
              </RemFormCard>

              <!---------BASE DATA AND MT RESPONSIBLES-------->
              <RemFormCard v-if="router.name == 'dataAndMtResp'">
                <template #remcardheader>
                  <RemFormBackbutton
                    v-if="store.getters.getRemFormEdit == true"
                    :method="
                      () => {
                        setTab(5);
                      }
                    "
                  ></RemFormBackbutton>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t('message.baseDatas') }}
                  </h2>
                </template>
                <template #remcardmain>
                  <div v-for="item in state.basicData" v-bind:key="item.model" class="mb-8">
                    <div v-if="store.getters.getRemFormEdit == false">
                      <ImFormLabel :item="item"></ImFormLabel>
                    </div>
                    <div v-if="store.getters.getRemFormEdit == true">
                      <!-- eslint-disable -->
                      <ImFormInput
                        v-model:inputValue="item.value"
                        v-model:parent="item.parent"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :manualOptions="item.manualOptions"
                        :displayValue="item.endPoint"
                        :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod"
                        :validationMethod="item.validationMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                        :optionSearchEndpoint="item.optionSearchEndpoint"
                      >
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>
                  </div>

                  <!-- eslint-disable -->
                  <RemFormButtonRowStored :saveMethod="saveBuilding" deleteMethod="">
                  </RemFormButtonRowStored>
                  <!-- eslint-enable -->
                </template>
              </RemFormCard>

              <!------------DOCUMENTS------------------>
              <div
                v-if="router.name == 'building-documents' && !store.getters.getRemFormEdit"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
              >
                <PropertyLists title="documents" :building="state.building" />
              </div>

              <!-----------STRUCTURE TYPES-------------->
              <RemFormCard v-if="router.name == 'structure-types'">
                <template #remcardheader>
                  <RemFormBackbutton
                    v-if="store.getters.getRemFormEdit == true"
                    :method="
                      () => {
                        setTab(8);
                      }
                    "
                  ></RemFormBackbutton>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t('message.additionalInfo') }}
                  </h2>
                </template>
                <template #remcardmain>
                  <div
                    v-for="item in state.structureTypesData"
                    v-bind:key="item.model"
                    class="mb-8"
                  >
                    <div v-if="store.getters.getRemFormEdit == false">
                      <!-- eslint-disable -->
                      <RemVisibilityWrapper
                        v-model:toWatch="item.parent"
                        :visibilityCondition="item.visibilityCondition"
                      >
                        <ImFormLabel :item="item"></ImFormLabel>
                      </RemVisibilityWrapper>
                      <!-- eslint-enable -->
                    </div>
                    <div v-if="store.getters.getRemFormEdit == true">
                      <!-- eslint-disable -->
                      <ImFormInputVisibilityWraped
                        v-model:inputValue="item.value"
                        v-model:parent="item.parent"
                        v-model:toWatch="item.parent"
                        v-model:validated="item.validated"
                        :config="item"
                      ></ImFormInputVisibilityWraped>
                      <!-- eslint-enable -->
                    </div>
                  </div>

                  <!-- eslint-disable -->
                  <RemFormButtonRowStored :saveMethod="updateStructural" deleteMethod="">
                  </RemFormButtonRowStored>
                  <!-- eslint-enable -->
                </template>
              </RemFormCard>

              <!--------------LEGAL SITUATIONS--------------->
              <!-- eslint-disable -->
              <RemRVGForm
                v-if="
                  (router.name == 'building-legal-situations' && !store.getters.getRemFormEdit) ||
                  store.getters.getRemTab == 9
                "
                :title="$t('message.legalSituation')"
                rvgTab="building-legal-situations"
                formTab="9"
                :addNewMethod="
                  () => {
                    addNewTab('legalsit', 9);
                  }
                "
                v-model:formData="state.legalsitData"
                :rvgConfig="legalSituationsGridConfigComputed"
                :saveMethod="saveLegalsit"
                :deleteMethod="deleteLegalsit"
                :backMethod="
                  () => {
                    setTab(2);
                  }
                "
                v-model:elementId="state.legalsitId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!--------------UTILIZATIONS------------------>
              <!-- eslint-disable -->
              <RemRVGForm
                v-if="
                  (router.name == 'building-utilizations' && !store.getters.getRemFormEdit) ||
                  store.getters.getRemTab == 11
                "
                :title="$t('message.utilizations')"
                rvgTab="building-utilizations"
                formTab="11"
                :addNewMethod="
                  () => {
                    addNewTab('utilization', 11);
                  }
                "
                v-model:formData="state.utilizationData"
                :rvgConfig="utilizationGridConfigComputed"
                :saveMethod="saveUtilization"
                :deleteMethod="deleteUtilization"
                :backMethod="
                  () => {
                    setTab(3);
                  }
                "
                v-model:elementId="state.utilizationId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!--------------METERS------------------>
              <!-- eslint-disable -->
              <RemRVGForm
                v-if="
                  (router.name == 'building-meters' && !store.getters.getRemFormEdit) ||
                  store.getters.getRemTab == 12
                "
                :title="$t('message.meters')"
                rvgTab="building-meters"
                formTab="12"
                :addNewMethod="
                  () => {
                    addNewTab('meter', 12);
                  }
                "
                v-model:formData="state.meterData"
                :rvgConfig="meterGridConfigComputed"
                :saveMethod="saveMeter"
                :deleteMethod="deleteMeter"
                :backMethod="
                  () => {
                    setTab(4);
                  }
                "
                v-model:elementId="state.meterId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!--------------PARTNERS------------------>
              <!-- eslint-disable -->
              <RemRVGForm
                v-if="
                  (router.name == 'building-partners' &&
                    !store.getters.getRemFormEdit &&
                    store.getters.getRemTab != 15) ||
                  store.getters.getRemTab == 14
                "
                :addNewTitle="'assignNew'"
                :title="
                  state.partnerRvgType
                    ? $t('message.allRelatedPartner')
                    : $t('message.buildingPartners')
                "
                rvgTab="building-partners"
                formTab="14"
                :addNewMethod="
                  () => {
                    setTab(15);
                  }
                "
                v-model:formData="state.partnerData"
                :rvgConfig="
                  state.partnerRvgType
                    ? allRelatedPartnersGridConfigComputed
                    : partnersGridConfigComputed
                "
                :saveMethod="savePartner"
                :deleteMethod="deletePartner"
                :backMethod="
                  () => {
                    setTab(13);
                  }
                "
                :partnerTypeSwitcherMethod="
                  () => {
                    changePartnerRvgType();
                  }
                "
                v-model:elementId="state.partnerId"
              >
              </RemRVGForm>

              <!---------PARTNER-SEARCH----------->
              <RemPartnerAssignSearch
                v-if="store.getters.getRemTab == 15"
                :type="'building'"
                :backFunc="() => setTab(13)"
                :id="buildingId"
                :createFunc="
                  () => {
                    addNewTab('partner', 14);
                  }
                "
                :partnerMainTypes="partnerMainTypes"
                :partnerSubTypes="partnerSubTypes"
              >
              </RemPartnerAssignSearch>

              <!--LINKED TASKS-->
              <RemRVGForm
                v-if="
                  (router.name == 'building-linked-tasks' && !store.getters.getRemFormEdit) && User.hasPermission('WorkflowUser') ||
                  store.getters.getRemTab == 16 && User.hasPermission('WorkflowUser')
                "
                :title="$t('message.linkedTasksOverview')"
                rvgTab="building-linked-tasks"
                :rvgConfig="linkedTasksGridConfigComputed"
                :hideButton = true
              >
              </RemRVGForm>

              <RemFormConfirmationModal
                v-model:showConfirmation="state.confirmation.showConfirmation"
                v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
                v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
                v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod"
              ></RemFormConfirmationModal>
              <!-- eslint-enable -->
            </template>
          </RemFormLayout>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
//components
import BaseLayout from '../../layouts/BaseLayout.vue';
import PropertyLists from '../../components/REM/RemLists.vue';
import SideMenuButton from '../../components/REM/SideMenuButton.vue';
import RemUpButton from '../../components/REM/RemUpButton.vue';
import ImFormLabel from '../../components/ImFormLabel.vue';
import ImFormInput from '../../components/ImFormInput.vue';
import RemRVGForm from '../../components/REM/RemRVGForm.vue';
import RemFormConfirmationModal from '../../components/REM/RemFormConfirmationModal.vue';
import RemFormLayout from '../../components/REM/RemFormLayout.vue';
import RemFormCard from '../../components/REM/RemFormCard.vue';
import RemFormButtonRowStored from '../../components/REM/RemFormButtonRowStored.vue';
import RemFormBackbutton from '../../components/REM/RemFormBackbutton.vue';
import ImFormInputVisibilityWraped from '../../components/REM/ImFormInputVisibilityWraped.vue';
import RemVisibilityWrapper from '../../components/REM/RemVisibilityWrapper.vue';
import RemPartnerAssignSearch from '../../components/REM/RemPartnerAssignSearch.vue';

//Utils
import { onMounted, ref, watch, computed } from 'vue';
import { computedAsync } from '@vueuse/core';
import RVGUtils from '../../components/RVG/RobberVueGridUtils.js';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import route from '@/misc/router';
import Utils from '../../misc/Utils';
import store from '@/misc/vuex-store.js';
import buildingUtils from './buildingUtils.js';
import toast from '@/misc/toast.js';
import remFormUtils from '../../misc/remFormUtils.js';
import TenantConfigHandler from '../../misc/TenantConfigHandler.js';
import User from '@/misc/User';

//RVG and fomdata
import formdata from './FormData.js';
import buildingBasicData from '../../misc/remFormdata/building/buildingBasicData.js';
import buildingData from '../../misc/remFormdata/building/buildingData.js';
import legalSituationsGridConfig from '@/misc/gridConfigs/buildingPage/legalSituationsGridConfig.js';
import premiseGridConfig from '@/misc/gridConfigs/buildingPage/premiseGridConfig.js';
import utilizationsGridConfig from '../../misc/gridConfigs/buildingPage/utilizationsGridConfig.js';
import meterGridConfig from '@/misc/gridConfigs/buildingPage/metersGridConfig.js';
import partnersGridConfig from '../../misc/gridConfigs/buildingPage/partnersGridConfig.js';
import allRelatedPartnersGridConfig from '../../misc/gridConfigs/buildingPage/allRelatedPartnersGridConfig.js';
import premiseSpecialData from '../../misc/remFormdata/premise/premiseSpecialData.js';
import linkedTasksGridConfig from '../../misc/gridConfigs/buildingPage/linkedTasksGridConfig';

const state = ref({
  tabsToConfirm: {
    10: {
      saveMethod: async () => {
        await savePremise();
      },
    },
    9: {
      saveMethod: async () => {
        await saveLegalsit();
      },
    },
    11: {
      saveMethod: async () => {
        await saveUtilization();
      },
    },
    12: {
      saveMethod: async () => {
        await saveMeter();
      },
    },
    14: {
      saveMethod: async () => {
        await savePartner();
      },
    },
    5: {
      saveMethod: async () => {
        await saveBuilding();
      },
    },
    8: {
      saveMethod: async () => {
        await updateStructural();
      },
    },
  },
  isElevator: false,
  isStaire: false,
  staircaseData: formdata.staircaseData,
  elevatorData: formdata.elevatorData,
  building: null,
  property: null,
  legalsitData: formdata.legalsitData,
  legalsitId: 0,
  premiseData: formdata.premiseData,
  premiseSpecialData: premiseSpecialData.data,
  premiseId: 0,
  utilizationData: formdata.utilizationData,
  utilizationId: 0,
  meterData: formdata.meterData,
  meterId: 0,
  structureTypesData: buildingData,
  basicData: buildingBasicData,
  structEdit: false,
  partnerData: formdata.partnerData,
  partnerId: 0,
  isPerson: false,
  partnerRvgType: false,
  confirmation: {
    showConfirmation: false,
    confirmationTextToken: '',
    confirmationTrueMethod: () => {},
    confirmationFalseMethod: () => {},
  },
});

// const language = computed(() => store.getters.getLanguage);
const premiseGrid = ref();
const meterGrid = ref();
const utilizationGrid = ref();
const legalSituationsGrid = ref();
const partnersGrid = ref();
const { t } = useI18n();
const router = useRoute();
const buildingId = router.params.buildingId;
const propertyId = router.params.propertyId;

const partnerMainTypes = ref([]);
const partnerSubTypes = ref([]);
const partnerMainTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(partnerMainTypes.value);
});
const partnerSubTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(partnerSubTypes.value);
});

buildingUtils.asyncGet(state, buildingId, formdata);

const deletePartner = (recordId, cbToRefreshGrid) => {
  remFormUtils.deattachConfirmation(
    state,
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/building/' +
      buildingId +
      '/partner/' +
      recordId,
    'partner',
    13,
    t,
    cbToRefreshGrid
  );
};

//LINKED TASKS
const linkedTasksGridConfigComputed = computedAsync(async () => {
  return  linkedTasksGridConfig(state, t, buildingId, )})

//PARTNERS
const partnersGridConfigComputed = computed(() => {
  return partnersGridConfig(
    state,
    t,
    buildingId,
    deletePartner,
    partnerMainTypeMapping.value,
    partnerSubTypeMapping.value
  );
});

const allRelatedPartnersGridConfigComputed = computed(() => {
  return allRelatedPartnersGridConfig(
    state,
    t,
    buildingId,
    partnerMainTypeMapping.value,
    partnerSubTypeMapping.value
  );
});

const savePartner = () => {
  buildingUtils.savePartner(state, t, buildingId);
  partnersGrid?.value?.refresh();
};

const meterGridConfigComputed = computedAsync(async () => {
  const [
    meterTypeMapping,
    utilityTypeMapping,
    partnerMainTypeMapping,
    partnerSubTypeMapping,
    meterPartnerTypeMapping,
    commTypeMapping,
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('METER_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('METER_UTILITY_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('PARTNER_MAIN_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('PARTNER_SUB_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('METER_PARTNER_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('METER_COMM_TYPE'),
  ]);

  return meterGridConfig(
    t,
    state,
    buildingId,
    meterTypeMapping,
    utilityTypeMapping,
    partnerMainTypeMapping,
    partnerSubTypeMapping,
    meterPartnerTypeMapping,
    commTypeMapping
  );
}, meterGridConfig(t, state, buildingId));

const utilizationGridConfigComputed = computedAsync(async () => {
  const [legalRelationshipMapping, partnerMainTypeMapping, partnerSubTypeMapping] =
    await Promise.all([
      RVGUtils.getFormatterMappingForOptionType('UTILIZATION_LEGAL_RELATIONSHIP'),
      RVGUtils.getFormatterMappingForOptionType('PARTNER_MAIN_TYPE'),
      RVGUtils.getFormatterMappingForOptionType('PARTNER_SUB_TYPE'),
    ]);
  return utilizationsGridConfig(
    t,
    state,
    buildingId,
    legalRelationshipMapping,
    partnerMainTypeMapping,
    partnerSubTypeMapping
  );
}, utilizationsGridConfig(t, state, buildingId));

const legalSituationsGridConfigComputed = computedAsync(async () => {
  const [legalRelationshipTypeMapping, legalSituationTypeMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('LEGAL_RELATIONSHIP_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('LEGAL_SITUATION'),
  ]);
  return legalSituationsGridConfig(
    state,
    t,
    buildingId,
    legalRelationshipTypeMapping,
    legalSituationTypeMapping
  );
}, legalSituationsGridConfig(state, t, buildingId));

const premiseGridConfigComputed = computedAsync(async () => {
  const [
    basicDataPremiseFunctionMapping,
    basicDataCremClassMapping,
    basicDataStairwayTypeMapping,
    basicDataElevatorTypeMapping,
    basicDataFloorMapping,
    specialDataFloorCoveringMapping,
    specialDataFalseCeilingMapping,
    specialDataDoorTypeMapping,
    specialDataWindowMaterialMapping,
    specialDataSzepaMapping,
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('PREMISE_FUNCTION'),
    RVGUtils.getFormatterMappingForOptionType('CREM_CLASSIFICATION'),
    RVGUtils.getFormatterMappingForOptionType('STAIRWAY_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('ELEVATOR_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('PREMISE_FLOOR'),
    RVGUtils.getFormatterMappingForOptionType('FLOOR_COVERING'),
    RVGUtils.getFormatterMappingForOptionType('FALSE_CEILING'),
    RVGUtils.getFormatterMappingForOptionType('DOOR_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('WINDOW_MATERIAL'),
    RVGUtils.getFormatterMappingForOptionType('SZEPA_CLASSIFICATION'),
  ]);

  return premiseGridConfig(
    state,
    t,
    buildingId,
    buildingUtils.toPremise,
    basicDataPremiseFunctionMapping,
    basicDataCremClassMapping,
    basicDataStairwayTypeMapping,
    basicDataElevatorTypeMapping,
    basicDataFloorMapping,
    specialDataFloorCoveringMapping,
    specialDataFalseCeilingMapping,
    specialDataDoorTypeMapping,
    specialDataWindowMaterialMapping,
    specialDataSzepaMapping
  );
}, premiseGridConfig(state, t, buildingId, buildingUtils.toPremise));

const saveBuilding = () => {
  buildingUtils.saveBuilding(state, t, propertyId, buildingId, formdata);
};

const updateStructural = () => {
  buildingUtils.updateStructural(state, t, propertyId, buildingId, formdata);
};

const saveMeter = () => {
  buildingUtils.saveMeter(state, buildingId, t);
  meterGrid?.value?.refresh();
};

const saveUtilization = async () => {
  if (
    state.value.utilizationData[3].value != '' &&
    state.value.utilizationData[2].value > state.value.utilizationData[3].value
  ) {
    toast.error(t('message.startDateLaterThanEndDate'));
    return false;
  }
  const result = await buildingUtils.saveUtilization(state, buildingId, t);
  utilizationGrid?.value?.refresh();
  return result;
};

const saveLegalsit = async () => {
  const result = await buildingUtils.saveLegalsit(state, t, buildingId);
  legalSituationsGrid?.value?.refresh();
  return result;
};

const savePremise = () => {
  buildingUtils.savePremise(state, t, buildingId);
  premiseGrid?.value?.refresh();
};

const getBuilding = async () => {
  const response = await Utils.fetchBuildingById(buildingId);
  state.value.building = response;
};

const toLevelSelector = () => {
  const siteDesign = Utils.getSiteDesignById(state.value.building.siteDesignId);

  if (siteDesign) {
    store.commit('setSelectedSiteDesign', siteDesign);
    store.commit('setSiteDesignSidebarCurrentTab', 'levels');
    store.commit('setIsFromRem', true);

    route.push({
      name: 'siteDesignSelector',
      params: { siteDesignId: state.value.building.siteDesignId },
    });
  } else {
    toast.error(t('message.noSiteDesign'));
  }
};

function setRoute(name) {
  if (!store.getters.getRemFormEdit) {
    route.push({ name: name });
  }
}

const deletePremise = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/premise/' + state.value.premiseId,
    'premise',
    1
  );
  premiseGrid?.value?.refresh();
};

const deleteUtilization = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/utilization/' +
      state.value.utilizationId,
    'utilization',
    3
  );
  utilizationGrid?.value?.refresh();
};

const deleteMeter = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/meter/${state.value.meterId}`,
    'meter',
    4
  );
  meterGrid?.value?.refresh();
};

const deleteLegalsit = () => {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/legal-situation/' +
      state.value.legalsitId,
    'legalSit',
    2
  );
  legalSituationsGrid?.value?.refresh();
};

function deleteConfirmation(endpoint, formData, tab, cb, tokenPrefix = 'delete') {
  remFormUtils.deleteConfirmation(state, endpoint, formData, tab, t, cb, tokenPrefix);
}

function addNewTab(name, tab) {
  remFormUtils.addNewTab(name, tab, state);
  if (name == 'premise') {
    remFormUtils.clearData(state.value.elevatorData);
    remFormUtils.clearData(state.value.staircaseData);
  }
}

function setTab(tab, name) {
  remFormUtils.setTab(state, tab, name);
}

const changePartnerRvgType = () => {
  state.value.partnerRvgType = !state.value.partnerRvgType;
};

const getTypeMappings = async () => {
  [partnerMainTypes.value, partnerSubTypes.value] = await Promise.all([
    Utils.getAllOptions('PARTNER_MAIN_TYPE'),
    Utils.getAllOptions('PARTNER_SUB_TYPE'),
  ]);
};

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(propertyId);
  state.value.property = response;
};

onMounted(() => {
  getProperty();
  getBuilding();
  getTypeMappings();
});

// WATCHERS
watch(
  () => store.getters.getRemFormEdit,
  () => {
    getBuilding();
  }
);

watch(
  () => state.value.partnerData[1].value,
  () => {
    setTimeout(() => {
      state.value.partnerData[2].parent = state.value.partnerData[1].value;
    }, 700);
    if (state.value.partnerData[1].value != state.value.partnerData[2].parent) {
      state.value.partnerData[2].value = null;
    }
  }
);

watch(
  () => state.value.partnerData[3].value,
  () => {
    setTimeout(() => {
      state.value.partnerData[4].parent = state.value.partnerData[3].value;
      state.value.partnerData[5].parent = state.value.partnerData[3].value;
    }, 700);
  }
);

watch(
  () => state,
  () => {
    buildingUtils.asyncGet(state, buildingId);
  }
);

watch(
  () => state.value.premiseData[0].value,
  () => {
    setTimeout(() => {
      state.value.isStaire = state.value.premiseData[0].value == 216;
      state.value.isElevator = state.value.premiseData[0].value == 217;
    }, 700);
  }
);

watch(
  () => state.value.structureTypesData[6].value,
  () => {
    setTimeout(() => {
      state.value.structureTypesData[7].parent = state.value.structureTypesData[6].value;
    }, 700);
  }
);

watch(
  () => state.value.structureTypesData[8].value,
  () => {
    setTimeout(() => {
      state.value.structureTypesData[9].parent = state.value.structureTypesData[8].value;
    }, 700);
  }
);
watch(
  () => state.value.structureTypesData[10].value,
  () => {
    setTimeout(() => {
      state.value.structureTypesData[11].parent = state.value.structureTypesData[10].value;
    }, 700);
  }
);

watch(
  () => state.value.structEdit,
  () => {
    if (state.value.structEdit) {
      state.value.structureTypesData[0].buildingId = buildingId;
      state.value.structureTypesData[0].optionSearchEndpoint = 'editSiteDesign';
      if (state.value.siteDesignName) {
        state.value.structureTypesData[0].valueLabel = state.value.siteDesignName;
      }
    }
  }
);

watch(
  () => state.value.structureTypesData[6].value,
  () => {
    if(state.value.structureTypesData[6].value != state.value.structureTypesData[7].parent){
      state.value.structureTypesData[7].value = null;
    }
    if (state.value.structureTypesData[6].value) {
      state.value.structureTypesData[7].disabled = false;
    } else if (!state.value.structureTypesData[6].value) {
      state.value.structureTypesData[7].disabled = true;
    }
  }
);

watch(
  () => state.value.structureTypesData[8].value,
  () => {
    if(state.value.structureTypesData[8].value != state.value.structureTypesData[9].parent){
      state.value.structureTypesData[9].value = null;
    }
    if (state.value.structureTypesData[8].value) {
      state.value.structureTypesData[9].disabled = false;
    } else if (!state.value.structureTypesData[8].value) {
      state.value.structureTypesData[9].disabled = true;
    }
  }
);
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  max-width: 100%;
  width: 100%;
  height: fit-content;
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>
