let uniqueField = "wfAdminTransitionGrid";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
// import store from "@/misc/vuex-store";
// import router from '../../router';

export default (
  state,
  t,
  taskTypeId,
  workflowId,
  setConfirmation,
  editCustomField
) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/custom-fields`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: "",
                tooltip: t("message.edit"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="size-4"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "editCustomFieldButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  editCustomField(record.id);
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.delete"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "deleteCFButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  setConfirmation("deleteCFConfirm", record.id);
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: t("message.identifier"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: false,
        orderable: true,
      },
      name: {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.name ? record.name : "N/A";
        },
      },
      code: {
        title: "Code",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.code ? record.code : "N/A";
        },
      },
      groupName: {
        title: t("message.groupName"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.groupName ? record.groupName : "N/A";
        },
      },
      workflowName: {
        title: t("message.workflowName"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.workflowName ? record.workflowName : "N/A";
        },
      },
      workflowVersion: {
        title: t("message.workflowVersion"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Integer",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.workflowVersion ? record.workflowVersion : "N/A";
        },
      },
      section: {
        title: "Section",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.section ? record.section : "N/A";
        },
      },
      ord: {
        title: "Ord",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Integer",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.ord ? record.ord : "N/A";
        },
      },
    },
  };
};
