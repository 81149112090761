import axios from '@/misc/axios';
import toast from '@/misc/toast.js';
//import { watchEffect } from "vue";

//import remFormUtils from "../../misc/remFormUtils";

import TenantConfigHandler from '@/misc/TenantConfigHandler';
export default {
  getTask: async (state) => {
    try {
      const resp = await axios.get(
        TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
          '/rem/workflow/task/' +
          state.value.taskId,
        {
          onTokenRefreshed: () => {
            this.getTask(state);
          },
        }
      );

      if (resp && resp.status == 200) {
        for (const [key, e] of Object.entries(state.value.taskData)) {
          if (e.type == 'label') {
            return;
          } else if (e.type == 'option') {
            e.value = resp.data[key + 'Id'] ?? '';
            e.valueLabel = resp.data[key + 'Value'] ?? '';
          } else if (e.type == 'optionapi') {
            if (resp.data[key + 'Id']) {
              e.value = resp.data[key + 'Id'];
              e.valueLabel = resp.data[e.displayValue];
            }
          } else if (e.type == 'optionWeirdApi') {
            e.value = resp.data[key + 'Id'] ?? '';
            e.valueLabel = resp.data[key + 'Name'] ?? '';
          } else if (e.type == 'optionSearchEndpoint') {
            e.valueLabel = resp.data[key + 'Name'] ?? '';
          } else if (e.type == 'checkbox' && resp.data[key] == null) {
            e.value = '';
          } else if (e.type == 'transition') {
            e.value = resp.data[key + 'Id'];
            e.valueLabel = resp.data[key + 'Name'];
          } else {
            e.value = resp.data[key];
          }
        }

        state.value.taskData.deadline.parent = state.value.taskData.createdAt.value;
        state.value.taskData.responsible.otherData = [];
        state.value.taskData.responsible.otherData.push(resp.data.responsibleEmail);

        state.value.taskData.createdBy.otherData = [];
        state.value.taskData.createdBy.otherData.push(resp.data.createdByEmail);

        if (state.value.taskId != 0) {
          state.value.taskData.type.readonly = true;
        }

        /*const respCustomfields = await axios.get(
          TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
            "/rem/workflow/task/" +
            state.value.taskId + "/custom-field-values",
          {
            onTokenRefreshed: () => {
              this.getTask(state);
            },
          }
        );
  
        if (respCustomfields && respCustomfields.status == 200) {
          console.log(respCustomfields.data);
          
        }*/
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getTasks(state, relatedTaskIds) {
    try {
      await axios
        .get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/workflow/task', {
          onTokenRefreshed: () => {
            this.getTasks(state);
          },
        })
        .then((resp) => {
          if (resp && resp.status == 200) {
            this.addRelatedTasks(state, relatedTaskIds, resp);
          }
        });
    } catch (e) {
      console.log(e);
    }
  },

  addRelatedTasks: (state, relatedTaskIds, resp) => {
    state.value.relatedTaskArray = [];

    resp.data.content.forEach((e) => {
      let contains = false;
      relatedTaskIds.value.forEach((taskId) => {
        if (e.id == taskId && e.active == true) {
          contains = true;
        }
      });

      if (contains) {
        state.value.relatedTaskArray.push(e);
        e.openTab = false;
      } else {
        if (e.id != state.value.taskId) {
          state.value.taskRepo.push({
            value: e.id,
            label: e.subject,
          });
        }
      }
    });

    state.value.options = state.value.taskRepo;
  },

  getComments: async (state, id) => {
    try {
      await axios
        .get(
          TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
            '/rem/workflow/task/' +
            id +
            '/comments',
          {
            onTokenRefreshed: () => {
              this.getComments(state, id);
            },
          }
        )
        .then((resp) => {
          if (resp && resp.status == 200) {
            state.value.comments = resp.data;
            state.value.comments.forEach((e) => {
              e.commentToEdit = e.comment;
              e.editing = false;
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  },
  saveUniversal: async function (postendpoint, putendpoint, hermes, Id, t) {
    if (Id != 0) {
      try {
        const resp = await axios.put(putendpoint, hermes, {
          onTokenRefreshed: () => {
            this.saveUniversal(postendpoint, putendpoint, hermes, Id, t);
          },
        });
        if (resp && resp.status == 200) {
          toast.success(t('message.successfulSave'));
          return resp;
        } else {
          toast.error(t('message.notSuccessfulSave'));
          return false;
        }
      } catch (e) {
        toast.error(t('message.notSuccessfulSave'));
        return false;
      }
    } else {
      try {
        const resp = await axios.post(postendpoint, hermes, {
          onTokenRefreshed: () => {
            this.saveUniversal(postendpoint, putendpoint, hermes, Id, t);
          },
        });
        if (resp && resp.status == 201) {
          toast.success(t('message.successfulSave'));
          return resp;
        } else {
          toast.error(t('message.notSuccessfulSave'));
          return false;
        }
      } catch (e) {
        toast.error(t('message.notSuccessfulSave'));
        return false;
      }
    }
  },

  deleteUniversal: async function (endpoint, t) {
    try {
      const resp = await axios.delete(endpoint, {
        onTokenRefreshed: () => {
          this.deleteUniversal(endpoint, t);
        },
      });
      if (resp && resp.status == 204) {
        toast.success(t('message.deleteSuccessful'));
        return resp;
      } else {
        toast.error(t('message.deleteNotSuccessful'));
        return false;
      }
    } catch (e) {
      toast.error(t('message.deleteNotSuccessful'));
      return false;
    }
  },
  uploadFile: async function (state, documentFile, t) {
    const formData = new FormData();

    formData.append('mainType', state.value.documentSetup.documentType);
    formData.append('subType', state.value.documentSetup.documentSubType);
    formData.append('document', documentFile.value.files[0]);
    formData.append('name', state.value.documentSetup.documentName);
    formData.append('dateOfOrigin', state.value.documentSetup.documentDateOfOrigin);

    await axios
      .post(
        TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/property/1/document`,
        formData
      )
      .then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t('message.successfulSave'));
            documentFile.value = '';
            state.value.documentSetup = {
              documentName: '',
              documentDateOfOrigin: '',
              documentType: '',
              documentSubType: '',
              documentTypeLabel: '',
              documentSubTypeLabel: '',
            };
          } else {
            toast.error(t('message.notSuccessfulSave'));
          }
        } else {
          toast.error(t('message.notSuccessfulSave'));
        }
      });
  },
  deleteDocument: async function (id, t) {
    const uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/document/${id}`;

    try {
      await axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteDocument(id, t);
        },
      });
      toast.success(t('message.deleteSuccessful'));
    } catch (error) {
      toast.error(t('message.deleteNotSuccessful'));
    }
  },
  getEvents: async function (id, selectedItems, sorting) {
    let uri = '';

    if (selectedItems.length < 1) {
      uri =
        TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        `/rem/workflow/task/${id}/history?sortDirection=${sorting}`;
    } else {
      uri =
        TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        `/rem/workflow/task/${id}/history?filter=${selectedItems}&sortDirection=${sorting}`;
    }
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.getEvents();
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  getTaskTypes: async function () {
    const uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/task/type`;

    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.getTaskTypes();
        },
      });
      return response.data.content;
    } catch (error) {
      console.log(error);
    }
  },
};
