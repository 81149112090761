<template>
  <div>
    <div v-for="(event, i) in events" :key="i">
      <div v-for="update in event.eventData?.updatedBasicFields" :key="update.basicFieldCode">
        <div
          v-if="event.eventType == 'DATA_MODIFIED' && event.eventData != null"
          class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
        >
          <EventTime :dateString="event.triggeredAt" />
          <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
            <a class="font-semibold text-blue-600 dark:text-secondary">{{
              event.triggeredByUserName
            }}</a>
            {{ $t('message.changedData') }}: {{ $t(`message.${update.basicFieldCode}`) }} -
            <span class="font-semibold text-gray-900 dark:text-white"
              >{{
                update.oldValue != null &&
                update.basicFieldCode == 'TASK_PRIORITY' &&
                update.oldValue == 'LOW'
                  ? $t('message.PriorityLow')
                  : update.oldValue != null &&
                      update.basicFieldCode == 'TASK_PRIORITY' &&
                      update.oldValue == 'MEDIUM'
                    ? $t('message.PriorityMedium')
                    : update.oldValue != null &&
                        update.basicFieldCode == 'TASK_PRIORITY' &&
                        update.oldValue == 'HIGH'
                      ? $t('message.PriorityHigh')
                      : update.oldValue != null && update.oldValue != ''
                        ? update.oldValue
                        : $t('message.empty')
              }}
              >
            </span>
            <span class="font-semibold text-gray-900 dark:text-white"
              >{{
                update.newValue != null &&
                update.basicFieldCode == 'TASK_PRIORITY' &&
                update.newValue == 'LOW'
                  ? $t('message.PriorityLow')
                  : update.newValue != null &&
                      update.basicFieldCode == 'TASK_PRIORITY' &&
                      update.newValue == 'MEDIUM'
                    ? $t('message.PriorityMedium')
                    : update.newValue != null &&
                        update.basicFieldCode == 'TASK_PRIORITY' &&
                        update.newValue == 'HIGH'
                      ? $t('message.PriorityHigh')
                      : update.newValue != null && update.newValue != ''
                        ? update.newValue
                        : $t('message.empty')
              }}
            </span>
          </div>
        </div>
      </div>

      <div v-for="update in event.eventData?.updatedCustomFields" :key="update.customFieldId">
        <div
          v-if="
            event.eventType == 'DATA_MODIFIED' &&
            event.eventData != null &&
            update.metaDataType == 'MULTIPLE'
          "
          class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
        >
          <!-- <span>{{ update.basicFieldCode }}</span> -->
          <EventTime :dateString="event.triggeredAt" />
          <div
            v-if="update.newValue != null"
            class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300"
          >
            <a class="font-semibold text-blue-600 dark:text-secondary">{{
              event.triggeredByUserName
            }}</a>
            {{
              update.customFieldName == 'Ingatlan adatai'
                ? $t('message.attachedProperties')
                : update.customFieldName == 'Épület adatai'
                  ? $t('message.attachedBuildings')
                  : update.customFieldName == 'Helyiség adatai'
                    ? $t('message.attachedPremises')
                    : update.customFieldName
            }}:
            <span class="font-semibold text-gray-900 dark:text-white">{{
              update.newValue != null && update.newValue != ''
                ? update.newValue
                : $t('message.empty')
            }}</span>
          </div>

          <div
            v-if="update.deletedValue != null"
            class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300"
          >
            <a class="font-semibold text-blue-600 dark:text-secondary">{{
              event.triggeredByUserName
            }}</a>
            {{
              update.customFieldName == 'Ingatlan adatai'
                ? $t('message.deattachedProperties')
                : update.customFieldName == 'Épület adatai'
                  ? $t('message.deattachedBuildings')
                  : update.customFieldName == 'Helyiség adatai'
                    ? $t('message.deattachedPremises')
                    : update.customFieldName
            }}:
            <span class="font-semibold text-gray-900 dark:text-white">{{
              update.deletedValue != null && update.deletedValue != ''
                ? update.deletedValue
                : $t('message.empty')
            }}</span>
          </div>
        </div>
      </div>

      <div
        v-if="event.eventType == 'STATE_TRANSITION'"
        class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
      >
        <span>{{
          event.eventData.transitionName == 'TASK_CREATION' ||
          event.eventData.transitionName == 'TASK_DELETION'
            ? $t(`message.${event.eventData.transitionName}`)
            : event.eventData.transitionName
        }}</span>
        <EventTime :dateString="event.triggeredAt" />
        <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
          <a class="font-semibold text-blue-600 dark:text-secondary">{{
            event.triggeredByUserName
          }}</a>
          {{ $t('message.changedTaskState') }}
          <span v-if="event.eventData.fromState" class="font-semibold text-gray-900 dark:text-white"
            >{{ event.eventData.fromState }} >
          </span>
          <span class="font-semibold text-gray-900 dark:text-white">{{
            event.eventData.toState
          }}</span>
        </div>
      </div>

      <div
        v-if="event.eventType == 'REMARK_MODIFIED'"
        class="items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
      >
        <EventTime :dateString="event.triggeredAt" />
        <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
          <a class="font-semibold text-blue-600 dark:text-secondary">
            {{ event.triggeredByUserName + ' ' }}
          </a>

          <span v-if="event.eventData.dataModificationType" class="text-gray-900 dark:text-white">{{
            event.eventData.dataModificationType == 'CREATION'
              ? $t('message.commentCreation')
              : event.eventData.dataModificationType == 'MODIFICATION'
                ? $t('message.commentEdition')
                : $t('message.commentDeletion')
          }}</span>
        </div>
      </div>

      <div
        v-if="event.eventType == 'DOCUMENTS_MODIFIED'"
        class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
      >
        <EventTime :dateString="event.triggeredAt" />
        <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
          <a class="font-semibold text-blue-600 dark:text-secondary">{{
            event.triggeredByUserName
          }}</a>
          {{
            event.eventData.dataModificationType == 'UPLOADING'
              ? $t('message.uploadedDocument')
              : event.eventData.dataModificationType == 'MODIFICATION'
                ? $t('message.modifiedDocument')
                : $t('message.deletedDocument')
          }}
          <span
            v-if="event.eventData.documentName"
            class="font-semibold text-gray-900 dark:text-white"
            >{{ event.eventData.documentName }}
          </span>
        </div>
      </div>

      <div
        v-if="event.eventType == 'LINKS_MODIFIED'"
        class="items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
      >
        <EventTime :dateString="event.triggeredAt" />
        <div class="px-5 text-sm font-normal text-gray-500 lex dark:text-gray-300">
          <a class="font-semibold text-blue-600 dark:text-secondary">
            {{ event.triggeredByUserName + ' ' }}
          </a>
          <span>{{
            event.eventData.dataModificationType == 'ADDITION'
              ? $t('message.linkedTaskAdded') + ': '
              : event.eventData.dataModificationType == 'DELETION'
                ? $t('message.linkedTaskDeleted') + ': '
                : ''
          }}</span>
          <span class="font-semibold text-gray-900 dark:text-white">{{
            event.eventData.relatedTaskIdentifier
          }}</span>
        </div>
      </div>

      <div
        v-if="
          event.eventType == 'SYSTEM_EVENT' &&
          event.eventData.systemEventType == 'SOLUTION_DEADLINE_ALERT'
        "
        class="flex items-center justify-between p-4 my-3 bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
      >
        <EventTime :dateString="event.triggeredAt" />
        <div class="px-5 text-sm font-bold text-gray-500 lex dark:text-gray-300">
          {{ $t('message.deadlineAlert') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import store from '@/misc/vuex-store';
import EventTime from './components/EventTime.vue';

const events = computed(() => {
  return store.getters.getEvent;
});
</script>
