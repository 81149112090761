<template>
    <div id="WorkflowMainPage" class="h-screen max-h-screen">
        <BaseLayout>
            <template #leftButtons>

            </template>

            <template #mainContent>
                <div class="h-full h-max-full mx-auto px-2  k" v-if="state.loaded == true">
                    <div>
                        <RemFormBackbutton :method="() => { moveAwayConfirm(true); }"></RemFormBackbutton>
                        <h2 class="text-lg font-sans font-semibold uppercase text-white mb-8">
                            {{ $t(`message.taskMainTitle_${route.params.view}`) }}
                        </h2>
                        <!-- eslint-disable -->
                        <ViewWrapper v-model:taskState="state.taskData.state.value"
                                        v-model:readonly="state.taskData.state.readonly"
                                        :viewList="state.taskData.state.viewList"
                                        :readonlyViewList="state.taskData.state.readonlyViewList"
                                        :defaultReadonly="state.taskData.state.defaultReadonly" :key="state.saveEvent">
                                        <WFTransitionField v-model:inputValue="state.taskData.state.value"
                                            v-model:valueLabel="state.taskData.state.valueLabel"
                                            v-model:updateKey="state.updateKey" />
                                    </ViewWrapper>


                                    <!-- eslint-enable -->
                    </div>
                    <div class="flex flex-row">
                        <!-- MAIN CARD -->
                        <TransitionGroup name="fade">
                            <RemFormCard class="mr-8 w-8/12" :key="state.saveEvent">
                                <template #remcardheader>
                                    <!-- eslint-disable -->


                                    <WFLabelFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.subject" textsm="false" />

                                    <WFLabelFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.type" textsm="false" />
                                    <WFLabelFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.identifier" textsm="false" />






                                    <!-- eslint-enable -->

                                    <div class="flex flex-row my-4">
                                        <!-- eslint-disable -->
                                        <ViewWrapper v-model:taskState="state.taskData.state.value"
                                            :viewList="workflowAccessMartix.cancelButtonConfig.viewList"
                                            :stateList="workflowAccessMartix.cancelButtonConfig.stateList"
                                            :readonlyViewList="workflowAccessMartix.cancelButtonConfig.readonlyViewList"
                                            :readonlyStateList="workflowAccessMartix.cancelButtonConfig.readonlyStateList"
                                            defaultReadonly="false">
                                            <RemPrimaryButtonSmall :method="() => { moveAwayConfirm(); }"
                                                :label="$t('message.cancel')" class="mr-2" />
                                        </ViewWrapper>



                                        <RemPrimaryButtonSmall v-if="route.params.view != 'read'"
                                            :label="$t('message.save')" :method="saveTask" class="mr-2">
                                        </RemPrimaryButtonSmall>

                                        <ViewWrapper v-model:taskState="state.taskData.state.value"
                                            :viewList="workflowAccessMartix.editButtonConfig.viewList"
                                            :stateList="workflowAccessMartix.editButtonConfig.stateList"
                                            :readonlyViewList="workflowAccessMartix.editButtonConfig.readonlyViewList"
                                            :readonlyStateList="workflowAccessMartix.editButtonConfig.readonlyStateList"
                                            defaultReadonly="false">
                                            <RemPrimaryButtonSmall :label="$t('message.edit')" :method="() => {
                                                router.push({
                                                    name: 'WorkflowTaskPage',
                                                    params: { taskId: taskId, view: 'edit' }
                                                })
                                            }" />

                                        </ViewWrapper>

                                    </div>

                                </template>
                                <template #remcardmain>

                                   

                                    <!-- eslint-disable -->
                                    <WFLabelFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.note" textsm="true" />


                                    <!-- eslint-enable -->



                                    <div>
                                        <!-- eslint-disable -->
                                        <WFFieldSlot v-model:taskType="state.taskData.type.value"
                                            v-model:customFieldSaveEvent="state.customFieldSaveEvent"
                                            v-model:form="state.customFields.body" placing="BODY">
                                        </WFFieldSlot>
                                        <PropertySelector v-model:taskType="state.taskData.type.value"
                                            v-model:taskState="state.taskData.state.value" v-model:taskId="state.taskId"
                                            v-model:customFieldSaveEvent="state.customFieldSaveEvent">
                                        </PropertySelector>

                                        <!-- eslint-enable -->
                                    </div>
                                    <div>
                                        <!-- eslint-disable -->
                                        <ViewWrapper v-model:taskState="state.taskData.state.value"
                                            :viewList="workflowAccessMartix.relatedTaskConfig.viewList"
                                            :stateList="workflowAccessMartix.relatedTaskConfig.stateList"
                                            :readonlyViewList="workflowAccessMartix.relatedTaskConfig.readonlyViewList"
                                            :readonlyStateList="workflowAccessMartix.relatedTaskConfig.readonlyStateList"
                                            defaultReadonly="false">
                                            <TaskRelatedTasks :taskPageState="state" />
                                        </ViewWrapper>

                                        <!-- eslint-enable -->
                                    </div>

                                    <!-- COMMENTS -->
                                    <div v-if="workflowAccessMartix.activitiesConfig.viewList.includes(route.params.view)"
                                        class="my-4">
                                        <TaskComments></TaskComments>


                                    </div>

                                </template>
                            </RemFormCard>

                            <!-- DETAILS CARD -->
                            <RemFormCard class="w-4/12" :key="state.saveEvent">
                                <template #remcardheader>
                                    <span class="text-white w-full text-md font-bold mb-1">{{ t('message.state') }}: {{ state.taskData.state.valueLabel }}</span>

                                </template>
                                <template #remcardmain>

                                    <!-- DETAILS  -->
                                    <WFLink :method="() => { state.openDetails = !state.openDetails }"
                                        class="uppercase mb-4">
                                        <div class="flex flex-row ">
                                            {{ $t('message.details') }}
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6"
                                                :class="{ 'rotate-180': state.openDetails == false }">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
                                            </svg>
                                        </div>

                                    </WFLink>

                                    <div v-if="state.openDetails == true">

                                        <!-- eslint-disable -->
                                        <LabelFieldListWState v-model:formData="state.taskData"
                                            :fieldArray="['responsible', 'priority', 'deadline', 'createdBy', 'createdAt']">
                                        </LabelFieldListWState>
                                        <!-- eslint-enable -->

                                    </div>
                                    <!-- eslint-disable -->
                                    <WFFieldSlot v-model:taskType="state.taskData.type.value"
                                        v-model:customFieldSaveEvent="state.customFieldSaveEvent"
                                        v-model:form="state.customFields.sideBar" placing="SIDEBAR">
                                    </WFFieldSlot>
                                    <!--
                                    <WFFormAccordion v-model:taskType="state.taskData.type.value"
                                        v-model:taskState="state.taskData.state.value"
                                        v-model:customFieldSaveEvent="state.customFieldSaveEvent"
                                        v-model:validation="state.customValidations.approver"
                                        groupFormData="approverGroup" :key="state.updateKey" />

                                    
                                        <WFFormAccordion v-model:taskType="state.taskData.type.value"
                                        v-model:taskState="state.taskData.state.value"
                                        v-model:customFieldSaveEvent="state.customFieldSaveEvent"
                                        groupFormData="approvalDates" />
                                        -->

                                    <!-- eslint-enable -->
                                </template>
                            </RemFormCard>
                        </TransitionGroup>

                    </div>
                    <!-- eslint-disable -->
                    <RemFormConfirmationModal v-model:showConfirmation="state.confirmation.showConfirmation"
                        v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
                        v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
                        v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod">
                    </RemFormConfirmationModal>
                    <!-- eslint-enable -->



                </div>





            </template>
        </BaseLayout>
    </div>
</template>

<script setup>
//components
import BaseLayout from '../layouts/BaseLayout.vue'
//import WFTitleFieldViewWraped from '../components/REM/Workflow/WFTitleFieldViewWraped.vue';
import WFLink from '../components/REM/Workflow/WFLink.vue';
import RemFormCard from '../components/REM/RemFormCard.vue';
import RemFormBackbutton from '../components/REM/RemFormBackbutton.vue';
import RemPrimaryButtonSmall from '../components/REM/RemPrimaryButtonSmall.vue';
import LabelFieldListWState from '../components/REM/Workflow/LabelFieldListWState.vue';
import TaskComments from './WorkflowPageComponents/TaskComments.vue';
import TaskRelatedTasks from './WorkflowPageComponents/TaskRelatedTasks.vue';
import RemFormConfirmationModal from '../components/REM/RemFormConfirmationModal.vue';
import WFTransitionField from '../components/REM/Workflow/WFTransitionField.vue';
import ViewWrapper from '../components/REM/Workflow/ViewWrapper.vue';
//import WFFormAccordion from '../components/REM/Workflow/WFFormAccordion.vue';
import PropertySelector from '../components/REM/Workflow/PropertySelector/PropertySelector.vue';
import WFFieldSlot from '../components/REM/Workflow/WFFieldSlot.vue';
import WFLabelFieldViewWraped from '@/components/REM/Workflow/WFLabelFieldViewWraped.vue';

// utils

import { ref, watch, onMounted } from 'vue';
import utils from '@/misc/fieldConfigs/utils';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useRoute } from "vue-router";
import router from "@/misc/router";
import workflowTaskUtils from './WorkflowPageComponents/workflowTaskUtils.js';
import { useI18n } from "vue-i18n";
import workflowAccessMartix from './WorkflowPageComponents/workflowAccessMartix.js';
import toast from "@/misc/toast.js";


//formdata
import taskFormData from "../misc/remFormdata/workflow/taskData.js";

//import taskCommentData from "../misc/remFormdata/workflow/taskCommentData.js";


let route = useRoute();
const { t } = useI18n();

const state = ref({});

state.value.taskData = {};
state.value.updateKey = 0;
state.value.loaded = false;

state.value.taskId = route.params.taskId
workflowAccessMartix.checkAccess(state, route.params.view)


state.value.openDetails = true
state.value.relatedTaskIds = [];
state.value.confirmation = {
    showConfirmation: false,
    confirmationTextToken: "",
    confirmationTrueMethod: () => { },
    confirmationFalseMethod: () => { },
}
state.value.saveEvent = 0;
state.value.customFieldSaveEvent = {
    counter: 0,
    id: route.params.taskId
};

state.value.mainTitle = '';

state.value.customFields = {
    sideBar: [],
    body: []
}

state.value.customValidations = {
    approver: false
}
onMounted(() => {
    for (let i = 0; i < taskFormData.data.length; i++) {

        state.value.taskData[taskFormData.data[i].model] = taskFormData.data[i];
        state.value.taskData[taskFormData.data[i].model].value = ""
        state.value.taskData[taskFormData.data[i].model].valueLabel = ""
    }



    if (state.value.taskId != 0) {
        workflowTaskUtils.getTask(state)
    } else {
        state.value.taskData.priority.value = "MEDIUM"
        state.value.taskData.priority.valueLabel = t("message.PriorityMedium")
    }

    state.value.loaded = true



})




/*if (state.value.taskId != 0) {
    workflowTaskUtils.getTask(state)
} else {
    state.value.taskData.priority.value = "MEDIUM"
    state.value.taskData.priority.valueLabel = t("message.PriorityMedium")
}*/




const saveTask = async () => {

    if (checkTaskValidation() == false) {
        return;
    }

    state.value.customFieldSaveEvent.newTask = route.params.taskId == 0

    const hermes = {
        priority: state.value.taskData.priority.value == "" ? null : state.value.taskData.priority.value,
        typeId: state.value.taskData.type.value == "" ? null : state.value.taskData.type.value,
        identifier: state.value.taskData.identifier.value == "" ? null : state.value.taskData.identifier.value,
        subject: state.value.taskData.subject.value == "" ? null : state.value.taskData.subject.value,
        stateId: state.value.taskData.state.value == null ? 1 : state.value.taskData.state.value,
        deadline: state.value.taskData.deadline.value == "" ? null : state.value.taskData.deadline.value,
        note: state.value.taskData.note.value == "" ? null : state.value.taskData.note.value,
        responsibleUserId: state.value.taskData.responsible.value == "" ? null : state.value.taskData.responsible.value,
        relatedTaskIds: [],
        customFields: [],
    };

    for (let group of state.value.customFields.body) {
        for (let field of group.fieldList) {
            const resp = utils.formatCustomFieldForSaving(field)

            if (resp) {
                hermes.customFields = hermes.customFields.concat(resp)
            }
        }
    }

    for (let group of state.value.customFields.sideBar) {
        for (let field of group.fieldList) {
            const resp = utils.formatCustomFieldForSaving(field)

            if (resp) {
                hermes.customFields = hermes.customFields.concat(resp)
            }
        }
    }


    state.value.relatedTaskIds.forEach((e) => {
        hermes.relatedTaskIds.push(e)
    })

    const resp = await workflowTaskUtils.saveUniversal(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/workflow/task",
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/workflow/task/" + state.value.taskId,
        hermes,
        state.value.taskId,
        t)
    state.value.taskId = resp.data.id
    state.value.customFieldSaveEvent.counter = state.value.customFieldSaveEvent.counter + 1;
    state.value.customFieldSaveEvent.id = resp.data.id;


    setTimeout(() => {
        router.push({
            name: "WorkflowTaskPage",
            params: { taskId: resp.data.id, view: "read" },
        });
    }, 1000)

}

const checkTaskValidation = () => {

    for (const [key, e] of Object.entries(state.value.taskData)) {
        key;
        if (e.mandatory == true && e.value == "") {
            toast.error(t('message.missingMandatoryField'));
            return false;
        }
        if (e.validationMethod && e.validated == false) {
            toast.error(t('message.notSuccessfulSave'));
            return false;
        }
    }

    return true
}

const moveAwayConfirm = (backbutton) => {
    const readonlyWiews = ['read'];
    if (readonlyWiews.includes(route.params.view) ) {
        router.push({
            name: 'WorkflowMainPage'
        })
    } else {
        state.value.confirmation.confirmationTextToken = route.params.taskId == 0 ? "abortTaskCreate" : "abortTaskEdit";
        if (route.params.view == 'edit' && backbutton != true) {
            state.value.confirmation.confirmationTrueMethod = () => {
                state.value.confirmation.showConfirmation = false;
                router.push({
                    name: "WorkflowTaskPage",
                    params: { taskId: route.params.taskId, view: "read" },
                });
            };
        } else {
            state.value.confirmation.confirmationTrueMethod = () => {
                state.value.confirmation.showConfirmation = false;
                router.push({
                    name: 'WorkflowMainPage'
                })
            };
        }

        state.value.confirmation.confirmationFalseMethod = () => {
            state.value.confirmation.showConfirmation = false;
        };
        state.value.confirmation.showConfirmation = true;
    }

}

/*const deleteTaskConfirm = () => {
    state.value.confirmation.confirmationTextToken = "deleteConfirm";
    state.value.confirmation.confirmationTrueMethod = () => {
        deleteTask()
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
}*/

watch(() => route.params.view, () => {

    workflowAccessMartix.checkAccess(state, route.params.view)
    workflowTaskUtils.getTask(state);
    state.value.saveEvent = state.value.saveEvent + 1
})

/*watch(() => route.params.taskId, () => {
    state.value.taskId = route.params.taskId
    workflowTaskUtils.getTask(state);
})*/






</script>

<style scoped>
.comment-link:hover {
    color: #e18330
}

.comment-link-active {
    color: #e18330
}

.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;

    max-width: 100%;
    overflow-x: auto;
    height: fit-content;
}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}
</style>