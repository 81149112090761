<template>
  <div class="flex flex-row gap-2 my-2">
    <div class="w-1/2">
      <label>Művelet:</label>
      <select
        @change="changeValue"
        v-model="operation"
        class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
      >
        <option v-for="(option, key) in possibleOperations" :value="key" :key="key">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="w-1/2">
      <label>Érték:</label>
      <input
        :state="validation"
        v-model="currentValue"
        type="text"
        placeholder="Szűrőfeltétel beírása..."
        @change="changeValue"
        class="mt-1.5 form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
        :class="[{ '!border-error': !validation }]"
      />
    </div>
  </div>
</template>

<script>
import Config from './RVGFilterableText.config.js';

export default {
  name: 'RVGFilterableText',
  components: {},
  data() {
    let initialValue = {};
    if (this.config.multi) {
      initialValue = {
        operation: 'cn',
        currentValue: null,
      };
    } else {
      initialValue = {
        operation: 'eq',
        currentValue: null,
      };
    }
    return initialValue;
  },
  computed: {
    possibleOperations() {
      return Config.possibleOperations(this.config.multi);
    },
    validation() {
      return this.currentValue !== null && this.currentValue !== '';
    },
  },
  props: ['data', 'config', 'id'],
  methods: {
    changeValue() {
      this.$emit('changeValue', this.id, {
        field: this.id,
        filterKey: this.config.filterKey ? this.config.filterKey : this.id,
        type: 'text',
        operation: this.operation,
        value: this.currentValue,
        isValid: this.validation,
        textual: Config.getTextual(this.operation, this.currentValue),
      });
    },
  },
};
</script>

<style scoped></style>
