<template>
    <div id="WorkflowMainPage" class="flex-1 grow self-stretch p-0 pt-0 h-full" style="overflow: auto;">
        <BaseLayout>
            <template #leftButtons>
                <BackToMapButton />
            </template>
            <template #mainContent>
                <div class="mx-auto px-2  k">
                    <h2 class="text-lg font-sans font-semibold uppercase text-white my-8">{{
                        $t('message.taskListTitle') }}
                    </h2>
                    <RemPrimaryButtonSmall class="mr-4 mb-1" :label="$t('message.create')" :method="() => {
                        router.push({
                            name: 'WorkflowTaskPage',
                            params: { taskId: 0, view: 'newfromlist' }
                        });
                    }"></RemPrimaryButtonSmall>

                    <TaskSearch @refreshGrid="refreshGrid" @updateFilters="updateFilters" />
                    
                   <!--<RemPrimaryButtonSmall :label="$t('message.taskAssinment')" :method="() => {
                        router.push({
                            name: 'WorkflowTaskAssign',
                            params: {  }
                        });
                    }"></RemPrimaryButtonSmall>--> 
                    

                    <RobberVueGrid ref="tasksGrid" :config="taskGridConfigComputed" />
                </div>
                <!-- eslint-disable -->
             <AssignTaskModal v-model:showModal="assignModalData.showModal"></AssignTaskModal>
                <ApproveTaskModal v-model:showModal="approveModalData.showModal" v-model:taskId="approveModalData.taskId" @refreshGrid="refreshGrid"></ApproveTaskModal>
                <!-- eslint-enable -->


            </template>
        </BaseLayout>
    </div>
</template>

<script setup>
//components
import BaseLayout from '../layouts/BaseLayout.vue'
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue";
import BackToMapButton from '../components/BackToMapButton.vue';
import RemPrimaryButtonSmall from '../components/REM/RemPrimaryButtonSmall.vue';
import ApproveTaskModal from '../components/REM/Workflow/ApproveTaskModal.vue';
import AssignTaskModal from '../components/REM/Workflow/AssignTaskModal.vue';
import TaskSearch from './WorkflowPageComponents/TaskSearch.vue'


// utils
import router from "@/misc/router";
import { ref, computed  } from 'vue';
import { useI18n } from "vue-i18n";
const { t } = useI18n();


// RVGS
import taskGridConfig from "@/misc/gridConfigs/workflowPage/universalGridConfig.js";

const tasksGrid = ref()

const assignModalData = ref({
    showModal:false,
    taskId: 0
});

const approveModalData = ref({
    showModal:false,
    taskId: 0
});

const taskFilters = ref({})

const updateFilters = (filters) => {
    taskFilters.value = filters;
    refreshGrid()
}

const taskGridConfigComputed = computed(() => {
    return taskGridConfig(t, /*approveModalData, assignModalData,*/ taskFilters.value);
});

const refreshGrid = () => {
    tasksGrid.value?.gridRefresh(true)
};


</script>

<style scoped></style>