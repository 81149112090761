<template>
    <div>
        <div class="input-group w-50">


            <input style="width: 60%;" class="input input-sm input-bordered w-50" type="text" v-model="inputValue[0]"
                :id="`${title}${type}`" @focusout="inputFocus = false" @focusin="inputFocus = true">
            <select style="width: 40%;" class="input input-sm input-bordered btn-sm" v-model="inputValue[1]"
                @focusout="ovFocus = false" @focusin="ovFocus = true">
                <option v-for="op in options" v-bind:key="op.value" :value="op.value">{{ op.label }}
                </option>

            </select>


        </div>



    </div>
</template>

<script setup>
import { defineModel, watch, ref, computed, onMounted } from "vue";
import store from "@/misc/vuex-store";
import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

const props = defineProps(["type", 'title', 'disabled', 'optionValue']);

//const displayValue = defineModel("displayValue");
const inputValue = defineModel("inputValue");
const focus = defineModel("focus");
const optionsOut = defineModel('optionsOut')

const inputFocus = ref(false)
const ovFocus = ref(false)
const options = ref([])

const language = computed(() => store.getters.getLanguage);


onMounted(() => {
    getOptions();
})

const getOptions = async () => {



    const resp = await Axios.get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/option/options/" +
        props.optionValue,
        {
            'Accept-Language': language.value
        },
        {
            onTokenRefreshed: () => {
                getOptions();
            },
        }
    )
    if (resp && resp.data) {
        options.value = []
        resp.data.forEach((op) => {
            options.value.push({
                label: op.value,
                value: op.id,
                parent: op.parent != null ? op.parent.id : "",

            });
        });
    }

    optionsOut.value = options.value

    if(options.value.length == 1){
        inputValue.value[1] = options.value[0].value
    }


}

watch(() => ovFocus.value, () => {
    setTimeout(() => {
        if (inputFocus.value == false && ovFocus.value == false) {
            focus.value = false
        }
    }, 500);

})

watch(() => inputFocus.value, () => {

    setTimeout(() => {
        if (inputFocus.value == false && ovFocus.value == false) {
            focus.value = false
        }
    }, 500);


})
</script>

<style scoped>
input[disabled] {
    @apply border border-gray-600;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
