export default {
  data: [
    {
      title: "designation",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "energyIntake",
      value: "",
      model: "energyIntake",
      type: "positiveNumber",
    },
    {
      title: "cooling",
      value: "",
      model: "cooling",
      type: "checkbox",
    },
    {
      title: "coolingPerformance",
      value: "",
      model: "coolingPerformance",
      type: "positiveNumber",
    },
    {
      title: "heating",
      value: "",
      model: "heating",
      type: "checkbox",
    },
    {
      title: "heatingPerformance",
      value: "",
      model: "heatingPerformance",
      type: "positiveNumber",
    },
    {
      title: "installationDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "maintenanceCycle",
      value: "",
      model: "maintenanceCycle",
      type: "text",
    },
    {
      title: "yearOfManufacture",
      value: "",
      model: "yearOfManufacture",
      type: "integer",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
    {
      title: "liquidCoolerCoolingPerformance",
      value: "",
      model: "liquidCoolerCoolingPerformance",
      type: "integer",
      validationMethod: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) => {
        if(newValue < 0){
          inputError.value = "message.positiveNumberOnly"
        }
        else {
          inputError.value = ""
        }
      }
    },
    {
      title: "liquidCoolerMaxElectricPerformance",
      value: "",
      model: "liquidCoolerMaxElectricPerformance",
      type: "integer",
      validationMethod: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) => {
        if(newValue < 0){
          inputError.value = "message.positiveNumberOnly"
        }
        else {
          inputError.value = ""
        }
      }
    },
  ]
}