<template>
  <div class="my-2 p-4 overflow-y-auto max-h-96">
    <!-- <div>
            Quick filters:
        </div> -->
    <div class="w-full">
      <ul class="flex flex-wrap">
        <li v-for="(item,i) in state.menuItems" :key="i" class="1/3 my-2">
          <a
            @click="
              filtering(item);
              getEvents();
              console.log(state.selectedItems)
            "
            class="hover:cursor-pointer hover:opacity-80 text-white p-1 mx-2 bg-secondary rounded-md"
            :class="{ active: state.selectedItems.includes(item) }"
            aria-current="page"
            >{{ $t(`message.${item}`) }}</a
          >
        </li>
      </ul>
    </div>
    <div class="flex justify-end mt-5">
      <button @click="state.buttonToggle = !state.buttonToggle">
        <svg v-if="state.buttonToggle"
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          stroke="currentColor"
          stroke-width="0.2"
          class="w-7 h-7 btn btn-secondary btn-sm hover:!opacity-80 btn-circle"
          viewBox="0 0 25 25"
        >
          <path
            d="M20 17H23L19 21L15 17H18V3H20V17M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z"
          />
        </svg>
        <svg v-if="!state.buttonToggle"
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          stroke="currentColor"
          stroke-width="0.2"
          class="w-7 h-7 btn btn-secondary btn-sm hover:!opacity-80 btn-circle"
          viewBox="0 0 25 25"
        >
          <path
            d="M18 7H15L19 3L23 7H20V21H18V7M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z"
          />
        </svg>
      </button>
    </div>

    <div class="">
      <EventDataModified
        v-if="state.selectedItems.includes('DATA_MODIFIED')"
      />
      <EventStateTransitions
        v-if="state.selectedItems.includes('STATE_TRANSITION')"
      />
      <EventRemarkModified
        v-if="state.selectedItems.includes('REMARK_MODIFIED')"
      />
      <EventDocumentsModified
        v-if="state.selectedItems.includes('DOCUMENTS_MODIFIED')"
      />
      <EventLinksModified
        v-if="state.selectedItems.includes('LINKS_MODIFIED')"
      />
      <EventSystemEvents
        v-if="state.selectedItems.includes('SYSTEM_EVENT')"
      />
      <EventsAll v-if="state.selectedItems.length == 0"/>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import wfUtils from './workflowTaskUtils';
import store from '@/misc/vuex-store';

//Components
import EventDataModified from '@/components/REM/Workflow/EventHandler/EventDataModified.vue';
import EventStateTransitions from '@/components/REM/Workflow/EventHandler/EventStateTransitions.vue';
import EventRemarkModified from '@/components/REM/Workflow/EventHandler/EventRemarkModified.vue';
import EventDocumentsModified from '@/components/REM/Workflow/EventHandler/EventDocumentsModified.vue';
import EventLinksModified from '@/components/REM/Workflow/EventHandler/EventLinksModified.vue';
import EventSystemEvents from '@/components/REM/Workflow/EventHandler/EventSystemEvents.vue';
import EventsAll from '@/components/REM/Workflow/EventHandler/EventsAll.vue';

let route = useRoute();

const state = ref({
  buttonToggle: true,
  menuItems: [
    'DATA_MODIFIED',
    'STATE_TRANSITION',
    'REMARK_MODIFIED',
    'DOCUMENTS_MODIFIED',
    'LINKS_MODIFIED',
    'SYSTEM_EVENT',
  ],
  selectedItems: [],
  sorting: '',
});

state.value.taskId = route.params.taskId;

const filtering = (event) => {
  const i = state.value.selectedItems.indexOf(event);
  if (i !== -1) {
    state.value.selectedItems.splice(i, 1);
  } else {
    state.value.selectedItems.push(event);
  }
};

const getEvents = async () => {
  if (state.value.buttonToggle) {
    state.value.sorting = 'desc';
  } else {
    state.value.sorting = 'asc';
  }

  if (state.value.selectedItems.length === 0) {
    const response = await wfUtils.getEvents(
      state.value.taskId,
      [],
      state.value.sorting
    );
    store.commit('setEvent', response);
  } else {
    const response = await wfUtils.getEvents(
      state.value.taskId,
      state.value.selectedItems,
      state.value.sorting
    );
    store.commit('setEvent', response);
  }
};

watch(state.value.selectedItems, () => {
  getEvents();
});

watch(
  () => state.value.buttonToggle,
  () => {
    getEvents();
  }
);

onMounted(() => {
  getEvents();
});

watch(
  () => route.params.taskId,
  () => {
    state.value.taskId = route.params.taskId;
    getEvents();
  }
);
</script>

<style scoped>
.active {
  border-width: 3px;
  @apply border-white;
}
</style>
