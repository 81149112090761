import { createStore } from "vuex";
import moment from "moment";
import localStorageService from "../services/LocalStorageService/LocalStorageService.js";
import WeatherIcons from "./WeatherIcons.js";
import VueCookie from "vue-cookie";

export default createStore({
  state() {
    return {
      event: null,
      flipped: false,
      isModalOpen: false,
      isTrModalOpen: false,
      isTrCfModalOpen: false, //Modal a custom fieldek transitionökhöz való hozzárendelésére
      isImportModalOpen: false,
      sidebarState: 'open',
      optionValues: {
        selectedDocType: null,
        selectedPartnerMainType: null,
        selectedInterSlabStruct: null,
        selectedClosingSlabStruct: null,
        selectedCoHeFunction: null,
      },
      documentById: null,
      premiseById: null,
      meterById: null,
      utilizationById: null,
      contactById: null,
      legalSituationById: null,
      isFromRem: false,
      buildingById: null,
      propCurrentTab: "",
      propertyById: null,
      siteDesignSidebarCurrentTab: "",
      partnerById: {},
      powerbi_access_token: null,
      access_token: null,
      id_token: null,
      graph_access_token: null,
      graph_id_token: null,
      roles: null,
      siteDesigns: null,
      selectedSiteDesign: null,
      selectedAsset: localStorageService.getSelectedAsset()
        ? localStorageService.getSelectedAsset()
        : null,
      weatherData: [],
      weatherDate: null,
      currentDate: moment().format("YYYY-MM-DD"),
      sidebarEntity: null,
      isSearchModalOpen: false,
      zoomLevel: null,
      theme: "dark",
      siteDesignsLoaded: false,
      appConfig: {
        gistTypes: [],
        zoneTypes: [],
        adapterTypes: [],
        allowedModules: [],
        allowedExtensions: [],
        allowedFileFilters: [],
        aggregationFunctions: [],
        aggregationGistTypes: [],
      },
      tenantConifg: {},
      language: "en",
      remTab: 1,
      remFormEdit: false,
    };
  },
  mutations: {
    setEvent(state, event){
      state.event = event
    },
    setDocumentById(state, document){
      state.documentById = document
    },
    setIsModalOpen(state, modalStatus){
      state.isModalOpen = modalStatus
    },
    setIsTrModalOpen(state, modalStatus){
      state.isTrModalOpen = modalStatus
    },
    setIsTrCfModalOpen(state, modalStatus){
      state.isTrCfModalOpen = modalStatus
    },
    setIsImportModalOpen(state, modalStatus){
      state.isImportModalOpen = modalStatus
    },
    setPremiseById(state, premise){
      state.premiseById = premise
    },
    setSidebarState(state, sidebarState) {
      state.sidebarState = sidebarState;
      localStorageService.setSidebarState(sidebarState)
    },
    setMeterById(state, meter){
      state.meterById = meter
    },
    setUtilizationById(state, utilization){
      state.utilizationById = utilization
    },
    setContactById(state, contact){
      state.contactById = contact
    },
    setLegalSituationById(state, legalSituation){
      state.legalSituationById = legalSituation
    },
    setSelectedDocType(state, docType){
      state.optionValues.selectedDocType = docType
    },
    setSelectedCoHeFunction(state, coHeFunction){
      state.optionValues.selectedCoHeFunction = coHeFunction
    },
    setSelectedClosingSlabStruct(state, closingSlabStruct){
      state.optionValues.selectedClosingSlabStruct = closingSlabStruct
    },
    setSelectedInterSlabStruct(state, interSlabStruct){
      state.optionValues.selectedInterSlabStruct = interSlabStruct
    },
    setSelectedPartnerMainType(state, partnerMainType){
      state.optionValues.selectedPartnerMainType = partnerMainType
    },
    setIsFromRem(state, isFromRem){
      state.isFromRem = isFromRem
    },
    setBuildingById(state, building){
      state.buildingById = building
    },
    setPropCurrentTab(state, tab) {
      state.propCurrentTab = tab;
    },
    setPropertyById(state, property) {
      state.propertyById = property;
    },
    setSiteDesignSidebarCurrentTab(state, currentTab) {
      state.siteDesignSidebarCurrentTab = currentTab;
    },
    setPartnerById(state, partner) {
      state.partnerById = partner;
    },
    setFlipped(state, flipped) {
      state.flipped = flipped;
      VueCookie.set('flipped', flipped);
    },
    setLanguage(state, language) {
      state.language = language;
      VueCookie.set("language", language);
    },
    setAppConfig(state, appConfig) {
      state.appConfig.aggregationFunctions = appConfig.aggregationFunctions;
      state.appConfig.gistTypes = appConfig.gistTypes;
      state.appConfig.zoneTypes = appConfig.zoneTypes;
      state.appConfig.adapterTypes = appConfig.adapters;
      state.appConfig.allowedModules = appConfig["allowed-modules"].toLowerCase().split(',');
      state.appConfig.allowedExtensions = appConfig["allowed-extensions"].toLowerCase().split(',');
      state.appConfig.allowedFileFilters = appConfig["allowed-file-filters"].split(',');
      state.appConfig.aggregationGistTypes = appConfig.aggregationGistTypes;
    },
    setTenantConfig(state, tenantConfig) {
      state.tenantConifg = tenantConfig;
    },

    setAccessToken(state, access_token) {
      VueCookie.set("access_token", access_token);
      state.access_token = access_token;
    },
    clearAccessToken(state) {
      VueCookie.delete("access_token");
      state.access_token = null;
    },
    setIdToken(state, id_token) {
      VueCookie.set("id_token", id_token);
      state.id_token = id_token;
    },
    clearIdToken(state) {
      VueCookie.delete("id_token");
      state.id_token = null;
    },
    setGraphAccessToken(state, graph_access_token) {
      VueCookie.set("graph_access_token", graph_access_token);
      state.graph_access_token = graph_access_token;
    },
    clearGraphAccessToken(state) {
      VueCookie.delete("graph_access_token");
      state.graph_access_token = null;
    },
    setGraphIdToken(state, graph_id_token) {
      VueCookie.set("graph_id_token", graph_id_token);
      state.graph_id_token = graph_id_token;
    },
    clearGraphIdToken(state) {
      VueCookie.delete("graph_id_token");
      state.graph_id_token = null;
    },
    setPowerbiAccessToken(state, powerbi_access_token) {
      VueCookie.set("powerbi_access_token", powerbi_access_token);
      state.powerbi_access_token = powerbi_access_token;
    },
    setRoles(state, roles) {
      VueCookie.set("roles", roles);
      state.roles = roles;
    },
    clearRoles(state) {
      VueCookie.delete("roles");
      state.roles = null;
    },
    logout(state) {
      VueCookie.delete("access_token");
      VueCookie.delete("id_token");
      VueCookie.delete("roles");
      state.access_token = null;
      state.id_token = null;
      state.roles = null;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setSiteDesigns(state, siteDesigns) {
      state.siteDesigns = siteDesigns;
      if (siteDesigns) {
        state.siteDesignsLoaded = true;
      }
    },
    addSiteDesign(state, siteDesign) {
      let idx = state.siteDesigns.findIndex(obj => obj.id == siteDesign.id);
      if(idx >= 0)
      {
        state.siteDesigns.splice(idx, 1);
      }
      state.siteDesigns?.push(siteDesign);
    },
    removeSiteDesign(state, siteDesignId) {
      let idx = state.siteDesigns.findIndex(obj => obj.id == siteDesignId);
      if(idx >= 0)
      {
        state.siteDesigns.splice(idx, 1);
      }
    },
    updateSiteNameHierarchy(state, siteDesign) {
      let idx = state.siteDesigns.findIndex(obj => obj.id == siteDesign.id);
      if(idx >= 0)
      {
        state.siteDesigns[idx].siteNameHierarchy = siteDesign.siteNameHierarchy;
      }
    },
    setSelectedAsset(state, assetId) {
      localStorageService.setSelectedAsset(assetId);
      state.selectedAsset = assetId;
    },
    clearSelectedAsset(state, assetId) {
      if (localStorageService.getSelectedAsset() == assetId) {
        localStorageService.clearSelectedAsset();
        state.selectedAsset = null;
      }
    },
    setSelectedSiteDesign(state, siteDesign) {
      state.selectedSiteDesign = siteDesign;
    },
    setWeatherData(state, weatherData, weatherDate) {
      state.weatherData = weatherData;
      state.weatherDate = weatherDate;
    },
    setCurrentDate(state, currentDate) {
      state.currentDate = currentDate;
    },
    showSidebar(state, sidebarEntity) {
      state.sidebarEntity = sidebarEntity;
    },
    hideSidebar(state) {
      state.sidebarEntity?.onClose?.call();
      state.sidebarEntity = null;
    },
    showSearchModal(state, show){
      state.isSearchModalOpen = show
    },
    setZoomLevel(state, zoomLevel) {
      state.zoomLevel = zoomLevel;
    },
    setRemTab(state, remTab){
      state.remTab = remTab;
    },
    setRemFormEdit(state, edit){
      state.remFormEdit = edit;
    },

  },
  getters: {
    getEvent(state) {
      return state.event;
    },
    getFlipped(state) {
      return state.flipped;
    },
    getDocumentById: (state) => {
      return state.documentById;
    },
    getIsModalOpen: (state) => {
      return state.isModalOpen;
    },
    getIsTrModalOpen: (state) => {
      return state.isTrModalOpen;
    },
    getIsTrCfModalOpen: (state) => {
      return state.isTrCfModalOpen;
    },
    getIsImportModalOpen: (state) => {
      return state.isImportModalOpen;
    },
    getPremiseById: (state) => {
      return state.premiseById;
    },
    getSidebarState: (state) => {
      return state.sidebarState;
    },
    getMeterById(state){
      return state.meterById
    },
    getUtilizationById(state){
      return state.utilizationById
    },
    getContactById(state){
      return state.contactById
    },
    getLegalSituationById(state){
      return state.legalSituationById
    },
    getSelectedDocType(state){
      return state.optionValues.selectedDocType
    },
    getSelectedCoHeFunction(state){
      return state.optionValues.selectedCoHeFunction
    },
    getSelectedClosingSlabStruct(state){
      return state.optionValues.selectedClosingSlabStruct
    },
    getSelectedInterSlabStruct(state){
      return state.optionValues.selectedInterSlabStruct
    },
    getSelectedPartnerMainType(state){
      return state.optionValues.selectedPartnerMainType
    },
    getIsFromRem(state){
      return state.isFromRem
    },
    getBuildingById(state){
      return state.buildingById
    },
    getPropCurrentTab(state) {
      return state.propCurrentTab;
    },
    getPropertyById(state) {
      return state.propertyById;
    },
    getSiteDesignSidebarCurrentTab(state) {
      return state.siteDesignSidebarCurrentTab;
    },
    getPartnerById(state) {
      return state.partnerById;
    },
    getLanguage(state) {
      return state.language;
    },
    getGistTypes(state) {
      return state.appConfig.gistTypes;
    },
    getAggregationGistTypes(state) {
      return state.appConfig.aggregationGistTypes;
    },
    getAggregationFunctions(state) {
      return state.appConfig.aggregationFunctions;
    },
    getAdapterTypes(state) {
      return state.appConfig.adapterTypes;
    },
    getZoneTypes(state) {
      return state.appConfig.zoneTypes;
    },
    getAllowedModules(state) { 
      return state.appConfig.allowedModules;
    },
    getAllowedExtensions(state) { 
      return state.appConfig.allowedExtensions;
    },
    getAllowedFileFilters(state) { 
      return state.appConfig.allowedFileFilters;
    },
    getAccessToken: (state) => {
      return state.access_token;
    },
    getIdToken: (state) => {
      return state.id_token;
    },
    getRoles: (state) => {
      return state.roles;
    },
    getGraphAccessToken: (state) => {
      return state.graph_access_token;
    },
    getGraphIdToken: (state) => {
      return state.graph_id_token;
    },
    getSiteDesigns(state) {
      return state.siteDesigns;
    },
    getTheme(state) {
      return state.theme;
    },
    getSelectedAsset(state) {
      if (localStorageService.getSelectedAsset()) {
        state.selectedAsset = localStorageService.getSelectedAsset();
      }

      return state.selectedAsset;
    },
    getSelectedSiteDesign(state) {
      return state.selectedSiteDesign;
    },
    getIsSearchModalOpen(state) {
      return state.isSearchModalOpen
    },
    getCurrentDate(state) {
      return state.currentDate;
    },
    getSiteDesignsLoaded(state) {
      return state.siteDesignsLoaded;
    },
    getWeatherData: (state) => (siteDesignId) => {
      for (let i in state.siteDesigns) {
        if (state.siteDesigns[i].id == siteDesignId) {
          if (!state.siteDesigns[i].currentWeatherGist) {
            return null;
          }

          let cData = state.siteDesigns[i].currentWeatherGist;
          var cIcon = WeatherIcons[cData.weatherIcon];

          if (cIcon) {
            if (
              !(cData.weatherIcon > 699 && cData.weatherIcon < 800) &&
              !(cData.weatherIcon > 899 && cData.weatherIcon < 1000)
            ) {
              cData.iconText = "day-" + WeatherIcons[cData.weatherIcon].icon;
            } else {
              cData.iconText = WeatherIcons[cData.weatherIcon].icon;
            }
          }
          return cData;
        }
      }

      return null;
    },
    getSiteDesignForMap: (state) => (siteDesignId) => {
      for (let i in state.siteDesigns) {
        if (state.siteDesigns[i].id == siteDesignId) {
          let cPresenceData = state.siteDesigns[i].currentPresenceGist
            ? state.siteDesigns[i].currentPresenceGist
            : 0;
          let cNumberOfWorkplacesData = state.siteDesigns[i].currentPresenceGist
            ? state.siteDesigns[i].currentPresenceGist
            : 0;
          let cDisplayNameData = state.siteDesigns[i].displayName
            ? state.siteDesigns[i].currentPresenceGist
            : "N/A";
          return {
            cPresenceData: cPresenceData,
            cNumberOfWorkplacesData: cNumberOfWorkplacesData,
            cDisplayNameData: cDisplayNameData,
          };
        }
      }

      return 0;
    },
    getPresenceData: (state) => (siteDesignId) => {
      for (let i in state.siteDesigns) {
        if (state.siteDesigns[i].id == siteDesignId) {
          let cData = state.siteDesigns[i].currentPresenceGist;
          return cData ? cData : 0;
        }
      }

      return 0;
    },
    getNumberOfWorkplacesData: (state) => (siteDesignId) => {
      for (let i in state.siteDesigns) {
        if (state.siteDesigns[i].id == siteDesignId) {
          let cData = state.siteDesigns[i].numberOfWorkplaces;
          return cData ? cData : 0;
        }
      }

      return 0;
    },
    getNameData: (state) => (siteDesignId) => {
      for (let i in state.siteDesigns) {
        if (state.siteDesigns[i].id == siteDesignId) {
          let cData = state.siteDesigns[i].name;
          return cData ? cData : "N/A";
        }
      }

      return 0;
    },
    isModuleEnabled: (state) => (moduleName) => {
      return state.appConfig.allowedModules.includes(moduleName.toLowerCase());
    },
    isExtensionEnabled: (state) => (extensionName) => {
      return state.appConfig.allowedExtensions.includes(extensionName.toLowerCase());
    },
    getTenantConfig:(state) => () => {
      return state.tenantConifg;
    },
    getSidebarEntity(state) {
      return state.sidebarEntity;
    },
    getZoomLevel(state) {
      return state.zoomLevel
    },
    getRemTab(state){
      return state.remTab
    },
    getRemFormEdit(state){
      return state.remFormEdit
    },

  },
  actions: {},
});
