<template>
  <div>
    <input
      type="text"
      class="input input-sm input-bordered w-full"
      :value="formattedValue"
      @input="onInput"
      @blur="onBlur"
      :id="`${title}number`"
      step="1"
      autocomplete="off"
      @keydown="onKeydown"
      @paste="onPaste"
    />
  </div>
</template>

<script setup>
import { defineModel, computed, ref } from "vue";

const props = defineProps(["title", "validationMethod"]);
const emit = defineEmits(["update:inputError"]);

const inputValue = defineModel("inputValue");
const inputError = defineModel("inputError");
const inputErrorVariables = defineModel("inputErrorVariables");

// Ensure initial value is a positive integer or empty string
const lastValidValue = ref(
  inputValue.value != null &&
    Number.isInteger(inputValue.value * 1) &&
    inputValue.value >= 0
    ? inputValue.value
    : ""
);

const formattedValue = computed(() => {
  return lastValidValue.value.toString();
});

const onKeydown = (event) => {
  if (event.key === "," || event.key === "." || event.key === "e") {
    event.preventDefault();
    emit("update:inputError", "message.integerOnly");
  }
};

const onPaste = (event) => {
  const text = event.clipboardData.getData("text");
  if (!/^\d+$/.test(text)) {
    event.preventDefault();
    emit("update:inputError", "message.integerOnly");
  }
};

const onInput = (event) => {
  const rawValue = event.target.value;

  if (rawValue === "") {
    inputValue.value = null;
    lastValidValue.value = "";
    emit("update:inputError", "");
    return;
  }

  if (/^0\d/.test(rawValue)) {
    event.target.value = lastValidValue.value;
    emit("update:inputError", "message.integerOnly");
    return;
  }

  if (/^\d+$/.test(rawValue)) {
    inputValue.value = parseInt(rawValue, 10);
    lastValidValue.value = rawValue;
    emit("update:inputError", "");
  } else {
    event.target.value = lastValidValue.value;
    emit("update:inputError", "message.integerOnly");
  }
};

const onBlur = (event) => {
  if (props.validationMethod) {
    props.validationMethod(
      [],
      [],
      "",
      event.target.value,
      "",
      inputValue,
      inputError,
      inputErrorVariables
    );
  } else {
    emit("update:inputError", "");
  }
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
