<template>
  <div id="BuildingPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="h-fit px-2 overflow-y-auto mx-30 k">
          <!--Header-->
          <header class="w-full shadow-sm body-font">
            <div class="items-start px-6 pb-6 mx-auto md:flex-row mb-8">
              <div class="flex justify-between">
                <h1 class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold">
                  {{ $t('message.building') }} -
                  {{ state.building?.siteDesignName ? state.building.siteDesignName : 'N/A' }}
                  ({{ state.property ? state.property.primaryAddressCompleteAddress : '' }}) /
                  {{ $t('message.energetics') }}
                </h1>

                <RemUpButton remType="energetics" />
              </div>
            </div>
          </header>

          <div class="flex md:flex-row md:columns-2 flex-col w-full">
            <div
              class="im-sidemenu-contanier rounded-xl m-0 w-full grid"
              :class="{
                'im-w-auto': router.name != 'energeticsPage' || store.getters.getRemTab != 0,
              }"
            >
              <div
                v-for="item in state.basicData"
                v-bind:key="item.title"
                class="flex flex-row columns-2 mb-4 justify-between justify-self-center im-sidemnu-row"
              >
                <!-- eslint-disable -->
                <ImFormInput
                  v-model:inputValue="item.value"
                  v-model:parent="item.parent"
                  :title="item.title"
                  type="toggle"
                  class="w-32"
                >
                </ImFormInput>
                <!-- eslint-enable -->

                <label class="tooltip tooltip-bottom self-center">
                  <a
                    @click="
                      () => {
                        openTab(item);
                      }
                    "
                    class="btn btn-sm btn-secondary btn-circle normal-case text-sm"
                    :class="{
                      'im-button-active': router.name == item.name,
                      'im-button-disabled': item.value == false,
                    }"
                  >
                    <SVGRenderer svgKey="arrow_icon"></SVGRenderer>
                  </a>
                </label>
              </div>
            </div>
            <TransitionGroup name="fade">
              <!---   HŐSZIGETELÉS -->

              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'insulation' || store.getters.getRemTab == 2"
                :title="$t('message.insulation')"
                rvgTab="insulation"
                formTab="2"
                :addNewMethod="
                  () => {
                    addNewTab('insulation', 2);
                  }
                "
                v-model:formData="state.insulationData"
                :rvgConfig="insulationConfigComputed"
                :saveMethod="saveInsulation"
                :deleteMethod="deleteInsulation"
                :backMethod="
                  () => {
                    setTab(1);
                  }
                "
                v-model:elementId="state.insulationId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!---   NAPELEM  -->

              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'solar-panel' || store.getters.getRemTab == 4"
                :title="$t('message.solarPanel')"
                rvgTab="solar-panel"
                formTab="4"
                :addNewMethod="
                  () => {
                    addNewTab('solarPanel', 4);
                  }
                "
                v-model:formData="state.solarPanelData"
                :rvgConfig="solarPanelGridConfigComputed"
                :saveMethod="saveSolarPanel"
                :deleteMethod="deleteSolarPanel"
                :backMethod="
                  () => {
                    setTab(3);
                  }
                "
                v-model:elementId="state.solarPanelId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!---   KÖZPONTI KLÍMA   -->

              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'central-air-conditioner' || store.getters.getRemTab == 6"
                :title="$t('message.centralAirConditioner')"
                rvgTab="central-air-conditioner"
                formTab="6"
                :addNewMethod="
                  () => {
                    addNewTab('centralAirConditioner', 6);
                  }
                "
                v-model:formData="state.centralAirConditionerData"
                :rvgConfig="centralAirConditionerGridConfigComputed"
                :saveMethod="saveCentralAirConditioner"
                :deleteMethod="deleteCentralAirConditioner"
                :backMethod="
                  () => {
                    setTab(5);
                  }
                "
                v-model:elementId="state.centralAirConditionerId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!---   KÖZPONTI KLÍMA KÜLTÉRI EGYSÉG   -->
              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'air-conditioner-outdoor-unit' || store.getters.getRemTab == 8"
                :title="$t('message.airConditionerOutdoorUnit')"
                rvgTab="air-conditioner-outdoor-unit"
                formTab="8"
                :addNewMethod="
                  () => {
                    addNewTab('airConditionerOutdoorUnit', 8);
                  }
                "
                v-model:formData="state.airConditionerOutdoorUnitData"
                :rvgConfig="airConditionerOutdoorUnitGridConfigComputed"
                :saveMethod="saveAirConditionerOutdoorUnit"
                :deleteMethod="deleteAirConditionerOutdoorUnit"
                :backMethod="
                  () => {
                    setTab(7);
                  }
                "
                v-model:elementId="state.airConditionerOutdoorUnitId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!---   GENERÁTOR   -->

              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'fuel-cell-generator' || store.getters.getRemTab == 10"
                :title="$t('message.fuelCellGeneratorTitle')"
                rvgTab="fuel-cell-generator"
                formTab="10"
                :addNewMethod="
                  () => {
                    addNewTab('fuelCellGenerator', 10);
                  }
                "
                v-model:formData="state.fuelCellGeneratorData"
                :rvgConfig="fuelCellGeneratorGridConfigComputed"
                :saveMethod="saveFuelCellGenerator"
                :deleteMethod="deleteFuelCellGenerator"
                :backMethod="
                  () => {
                    setTab(9);
                  }
                "
                v-model:elementId="state.fuelCellGeneratorId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!---   DIESEL GENERÁTOR   -->

              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'diesel-generator' || store.getters.getRemTab == 12"
                :title="$t('message.dieselGeneratorTitle')"
                rvgTab="diesel-generator"
                formTab="12"
                :addNewMethod="
                  () => {
                    addNewTab('dieselGenerator', 12);
                  }
                "
                v-model:formData="state.dieselGeneratorData"
                :rvgConfig="dieselGeneratorGridConfigComputed"
                :saveMethod="saveDieselGenerator"
                :deleteMethod="deleteDieselGenerator"
                :backMethod="
                  () => {
                    setTab(11);
                  }
                "
                v-model:elementId="state.dieselGeneratorId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!---   SZÜNETMENTES TÁP   -->

              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'ups' || store.getters.getRemTab == 14"
                :title="$t('message.ups')"
                rvgTab="ups"
                formTab="14"
                :addNewMethod="
                  () => {
                    addNewTab('ups', 14);
                  }
                "
                v-model:formData="state.upsData"
                :rvgConfig="upsGridConfigComputed"
                :saveMethod="saveUps"
                :deleteMethod="deleteUps"
                :backMethod="
                  () => {
                    setTab(13);
                  }
                "
                v-model:elementId="state.upsId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->

              <!---   KÖZPONTI FŰTÉS  -->

              <!-- eslint-disable -->
              <RemRVGForm
                v-if="router.name == 'central-heating' || store.getters.getRemTab == 16"
                :title="$t('message.centralHeating')"
                rvgTab="central-heating"
                formTab="16"
                :addNewMethod="
                  () => {
                    addNewTab('centralHeating', 16);
                  }
                "
                v-model:formData="state.centralHeatingData"
                :rvgConfig="centralHeatingGridConfigComputed"
                :saveMethod="saveCentralHeating"
                :deleteCentralHeating="deleteCentralHeating"
                :backMethod="
                  () => {
                    setTab(15);
                  }
                "
                v-model:elementId="state.centralHeatingId"
              >
              </RemRVGForm>
              <!-- eslint-enable -->
            </TransitionGroup>
          </div>
        
        </div>
        <!-- eslint-disable -->
        <RemFormConfirmationModal
          v-model:showConfirmation="state.confirmation.showConfirmation"
          v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
          v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
          v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod"
        >
        </RemFormConfirmationModal>
        <!-- eslint-enable -->
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
// Components
import ImFormInput from '../components/ImFormInput.vue';
import BaseLayout from '../layouts/BaseLayout.vue';
import RemUpButton from '../components/REM/RemUpButton.vue';
import SVGRenderer from '../components/SVGRenderer.vue';
import RemFormConfirmationModal from '../components/REM/RemFormConfirmationModal.vue';
import RemRVGForm from '../components/REM/RemRVGForm.vue';

//Utils

import { onMounted, ref } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useRoute } from 'vue-router';
import route from '@/misc/router';
import store from '@/misc/vuex-store';
import { useI18n } from 'vue-i18n';
import energeticsUtils from './EnergeticsPageComponents/energeticsUtils';
import remFormUtils from '../misc/remFormUtils';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import Utils from '../misc/Utils.js';

//RVGs

import universalGridConfig from '../misc/gridConfigs/energeticsPage/universalGridConfig';

//FormData
import insulationData from '../misc/remFormdata/energetics/insulationData.js';
import solarpanelData from '../misc/remFormdata/energetics/solarpanelData.js';
import centralAirConditionerData from '../misc/remFormdata/energetics/centralAirConditionerData.js';
import airConditionerOutdoorUnitData from '../misc/remFormdata/energetics/airConditionerOutdoorUnitData.js';
import fuelCellGeneratorData from '../misc/remFormdata/energetics/fuelCellGeneratorData.js';
import dieselGeneratorData from '../misc/remFormdata/energetics/dieselGeneratorData.js';
import upsData from '../misc/remFormdata/energetics/upsData.js';
import centralHeatingData from '../misc/remFormdata/energetics/centralHeatingData.js';

/**
 * Setup
 */
const insulationGrid = ref();
const solarPanelGrid = ref();
const cacGrid = ref();
const airConditionerOutdoorUnitGrid = ref();
const fuelCellGeneratorGrid = ref();
const dieselGeneratorGrid = ref();
const upsGrid = ref();
const centralHeatingGrid = ref();

const { t } = useI18n();
//console.log(t)

const router = useRoute();
store.commit('setRemTab', 0);
store.commit('setRemFormEdit', false);

const state = ref({});
state.value.buildingId = router.params.buildingId;
state.value.propertyId = router.params.propertyId;
state.value.basicData = {
  insulation: {
    tab: 1,
    title: t('message.insulation'),
    value: false,
    parent: false,
    name: 'insulation',
  }, // hőszigetelés
  solarPanel: {
    tab: 3,
    title: t('message.solarPanel'),
    value: false,
    parent: false,
    name: 'solar-panel',
  },
  centralAirConditioner: {
    tab: 5,
    title: t('message.centralAirConditioner'),
    value: false,
    parent: false,
    name: 'central-air-conditioner',
  },
  airConditionerOutdoorUnit: {
    tab: 7,
    title: t('message.airConditionerOutdoorUnit'),
    value: false,
    parent: false,
    name: 'air-conditioner-outdoor-unit',
  },
  fuelCellGenerator: {
    tab: 9,
    title: t('message.fuelCellGeneratorTitle'),
    value: false,
    parent: false,
    name: 'fuel-cell-generator',
  },
  dieselGenerator: {
    tab: 11,
    title: t('message.dieselGeneratorTitle'),
    value: false,
    parent: false,
    name: 'diesel-generator',
  },
  ups: {
    tab: 13,
    title: t('message.ups'),
    value: false,
    parent: false,
    name: 'ups',
  }, // szünetmentes táp
  centralHeating: {
    tab: 15,
    title: t('message.centralHeating'),
    value: false,
    parent: false,
    name: 'central-heating',
  },
};

state.value.energeticsId = 0;
state.value.insulationData = insulationData.data;
state.value.insulationId = 0;

state.value.solarPanelData = solarpanelData.data;
state.value.solarPanelId = 0;

state.value.centralAirConditionerData = centralAirConditionerData.data;
state.value.centralAirConditionerId = 0;

state.value.airConditionerOutdoorUnitData = airConditionerOutdoorUnitData.data;
state.value.airConditionerOutdoorUnitId = 0;

state.value.fuelCellGeneratorData = fuelCellGeneratorData.data;
state.value.fuelCellGeneratorId = 0;

state.value.dieselGeneratorData = dieselGeneratorData.data;
state.value.dieselGeneratorId = 0;

state.value.upsData = upsData.data;
state.value.upsId = 0;

state.value.centralHeatingData = centralHeatingData.data;
state.value.centralHeatingId = 0;

state.value.confirmation = {
  showConfirmation: false,
  confirmationTextToken: 'saveConfirmation',
  confirmationTrueMethod: () => {},
  confirmationFalseMethod: () => {},
};

state.value.tabsToConfirm = {
  2: {
    saveMethod: async () => {
      await saveInsulation();
    },
    name: 'insulation',
  },
  4: {
    saveMethod: async () => {
      await saveSolarPanel();
    },
    name: 'solarPanel',
  },
  6: {
    saveMethod: async () => {
      await saveCentralAirConditioner();
    },
    name: 'centralAirConditioner',
  },
  8: {
    saveMethod: async () => {
      await saveAirConditionerOutdoorUnit();
    },
    name: 'airConditionerOutdoorUnit',
  },
  10: {
    saveMethod: async () => {
      await saveFuelCellGenerator();
    },
    name: 'fuelCellGenerator',
  },
  12: {
    saveMethod: async () => {
      await saveDieselGenerator();
    },
    name: 'dieselGenerator',
  },
  14: {
    saveMethod: async () => {
      await saveUps();
    },
    name: 'ups',
  },
  16: {
    saveMethod: async () => {
      await saveCentralHeating();
    },
    name: 'centralHeating',
  },
};

const getBuilding = async () => {
  const response = await Utils.fetchBuildingById(state.value.buildingId);
  state.value.building = response;
};

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(state.value.propertyId);
  state.value.property = response;
};

function setRoute(name) {
  if (!store.getters.getRemFormEdit) {
    route.push({ name: name });
  }
}

onMounted(() => {
  getBuilding();
  getProperty();
  energeticsUtils.getMainEnergetics(state, t);
});

const openTab = (item) => {
  if (item.value) {
    setTab(item.tab, item.name);
    setRoute(item.name);
  }
};

/**
 *  RVGs
 */
const insulationConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'insulation', 'insulation', 2, t);
});

const solarPanelGridConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'solarPanel', 'solar-panel', 4, t);
});

const centralAirConditionerGridConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'centralAirConditioner', 'central-ac', 6, t);
});

const airConditionerOutdoorUnitGridConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'airConditionerOutdoorUnit', 'ac-outdoor-unit', 8, t);
});

const fuelCellGeneratorGridConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'fuelCellGenerator', 'fc-generator', 10, t);
});

const dieselGeneratorGridConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'dieselGenerator', 'diesel-generator', 12, t);
});

const upsGridConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'ups', 'ups', 14, t);
});

const centralHeatingGridConfigComputed = computedAsync(async () => {
  return await universalGridConfig(state, 'centralHeating', 'central-heating', 16, t);
});

/**
 * Savers
 */

async function saveInsulation() {
  const result = await energeticsUtils.saveUniversal(state, 'insulation', 'insulation', 1, t);
  insulationGrid.value.refresh();
  return result;
}

async function saveSolarPanel() {
  const result = await energeticsUtils.saveUniversal(state, 'solarPanel', 'solar-panel', 3, t);
  solarPanelGrid.value.refresh();
  return result;
}

async function saveCentralAirConditioner() {
  const result = await energeticsUtils.saveUniversal(
    state,
    'centralAirConditioner',
    'central-ac',
    5,
    t
  );
  cacGrid.value.refresh();
  return result;
}

async function saveAirConditionerOutdoorUnit() {
  const result = await energeticsUtils.saveUniversal(
    state,
    'airConditionerOutdoorUnit',
    'ac-outdoor-unit',
    7,
    t
  );
  airConditionerOutdoorUnitGrid.value.refresh();
  return result;
}

async function saveFuelCellGenerator() {
  const result = await energeticsUtils.saveUniversal(
    state,
    'fuelCellGenerator',
    'fc-generator',
    9,
    t
  );
  fuelCellGeneratorGrid.value.refresh();
  return result;
}
async function saveDieselGenerator() {
  const result = await energeticsUtils.saveUniversal(
    state,
    'dieselGenerator',
    'diesel-generator',
    11,
    t
  );
  dieselGeneratorGrid.value.refresh();
  return result;
}
async function saveUps() {
  const result = await energeticsUtils.saveUniversal(state, 'ups', 'ups', 13, t);
  upsGrid.value.refresh();
  return result;
}
async function saveCentralHeating() {
  const result = await energeticsUtils.saveUniversal(
    state,
    'centralHeating',
    'central-heating',
    15,
    t
  );
  centralHeatingGrid.value.refresh();
  return result;
}

/**
 * Destroyers
 */

function deleteInsulation() {
  deleteConfirmation('insulation', 'insulation', 1);
}

function deleteSolarPanel() {
  deleteConfirmation('solarPanel', 'solar-panel', 3);
}

function deleteCentralAirConditioner() {
  deleteConfirmation('centralAirConditioner', 'central-ac', 5);
}

function deleteAirConditionerOutdoorUnit() {
  deleteConfirmation('airConditionerOutdoorUnit', 'ac-outdoor-unit', 7);
}

function deleteFuelCellGenerator() {
  deleteConfirmation('fuelCellGenerator', 'fc-generator', 9);
}

function deleteDieselGenerator() {
  deleteConfirmation('dieselGenerator', 'diesel-generator', 11);
}

function deleteUps() {
  deleteConfirmation('ups', 'ups', 13);
}

function deleteCentralHeating() {
  deleteConfirmation('central-heating', 'centralHeating', 15);
}

function deleteConfirmation(endpoint, formData, tab) {
  remFormUtils.deleteConfirmation(
    state,
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/building/energetics/${endpoint}/${state.value[formData + 'Id']}`,
    formData,
    tab,
    t
  );
}

function addNewTab(name, tab) {
  remFormUtils.addNewTab(name, tab, state);
}

function setTab(tab, name) {
  remFormUtils.setTab(state, tab, name);
}
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  height: fit-content;
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
    display: flex;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeoutbtn {
  0% {
    opacity: 100%;
    display: flex;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes widenbtn {
  0% {
    width: 10rem;
  }

  50% {
    width: 10rem;
  }

  100% {
    width: 18rem;
  }
}

.im-sidemenu-contanier {
  transition: width 0.5s;
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemnu-row {
  width: 14rem;
}

.im-w-auto {
  margin-right: 2rem;
  width: 10rem;
}

.im-w-auto .tooltip-bottom {
  display: none;
}

.im-w-auto:hover {
  width: 18rem;
}

.im-w-auto:hover .tooltip-bottom {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: flex;
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

:deep(.im-round-btn) {
  border-radius: 9999px;
}

/* button */

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-button-disabled {
  border-color: #a6adbb;
  color: #a6adbb;
  background-color: rgba(0, 0, 0, 0);
}
</style>
