let uniqueField = 'utilizationsGrid';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import router from '@/misc/router';

export default (state, t, propertyId) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/task/of-property/${propertyId}`,
    idkey: 'id',
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: 'content',
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns',
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: '30sec',
          refreshInterval: 30,
          title: t('message.rvg-automatic-list-refresh-30-sec'),
          default: true,
        },
        {
          key: '1min',
          refreshInterval: 60,
          title: t('message.rvg-automatic-list-refresh-1-min'),
        },
        {
          key: '5min',
          refreshInterval: 300,
          title: t('message.rvg-automatic-list-refresh-5-min'),
        },
      ],
    },
    mapping: {
      $operations: {
        title: '',
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.review'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList: 'btn btn-secondary btn-circle btn-sm hover:!opacity-80',
                testValueAttribute: 'taskReviewButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  const routeData = router.resolve({
                    name: 'WorkflowTaskPage',
                    params: { taskId: record.id, view: 'read' },
                  });
                  window.open(routeData.href, '_blank');
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: 'ID',
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number',
        },
        exportable: false,
        orderable: true,
      },
      priority: {
        title: t('message.priority'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status',
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: 'text',
          mapping: {
            LOW: {
              title: t(`message.PriorityLow`),
            },
            MEDIUM: {
              title: t(`message.PriorityMedium`),
            },
            HIGH: {
              title: t(`message.PriorityHigh`),
            },
            null: {
              title: 'N/A',
            },
          },
        },
      },
      typeName: {
        title: t('message.taskType'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.typeName ? record.typeName : 'N/A';
        },
      },
      identifier: {
        title: t('message.identifier'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.identifier ? record.identifier : 'N/A';
        },
      },
      subject: {
        title: t('message.subject'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.subject ? record.subject : 'N/A';
        },
      },
      responsibleName: {
        title: t('message.responsible'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record ? record.responsibleName : 'N/A';
        },
      },
      stateName: {
        title: t('message.state'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.stateName ? record.stateName : 'N/A';
        },
      },
    },
  };
};
