<template>
    <!-- eslint-disable -->
    <RemVisibilityWrapper v-model:toWatch="toWatch" :visibilityCondition="config.visibilityCondition">
        <ImFormInput v-model:inputValue="inputValue" v-model:parent="parent" v-model:validated="validated"
            v-model:options="options" :title="$t('message.' + config.title)" :type="config.type"
            :valueLabel="config.valueLabel" :optionValue="config.optionValue" :endPoint="config.endPoint"
            :manualOptions="config.manualOptions" :displayValue="config.displayValue" :searchable="config.searchable"
            :onchangeMethod="config.onchangeMethod" :validationMethod="config.validationMethod"
            :mandatory="config.mandatory" :optionSearchEndpoint="config.optionSearchEndpoint"
            :helperText="config.helperText ? $t('message.' + config.helperText) : null"
            :buildingId="config.buildingId" :disabled="config.disabled"
            >
        </ImFormInput>
    </RemVisibilityWrapper>
    <!-- eslint-enable -->
</template>

<script setup>
import ImFormInput from '../ImFormInput.vue';
import RemVisibilityWrapper from './RemVisibilityWrapper.vue';

import { defineModel } from 'vue'

const toWatch = defineModel('toWatch')
const inputValue = defineModel('inputValue');
const parent = defineModel('parent')
const validated = defineModel('validated')
const options = defineModel('options')

defineProps(['config'])

</script>