//import Axios from "@/misc/axios";
//import toast from "@/misc/toast.js";

import TenantConfigHandler from '@/misc/TenantConfigHandler';
//import store from "@/misc/vuex-store";
import router from '@/misc/router';
// import moment from 'moment';
//import formData from "./../../remFormdata/workflow/taskData.js";

let uniqueField = 'workflowGrid';

export default (t, filters) => {
  let config = {
    endpoint:
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/workflow/task/list-tasks` +
      (filters.taskIdentifier ||
      filters.taskType?.name  ||
      filters.subject ||
      filters.state ||
      filters.responsible ||
      filters.firstApprover ||
      filters.secondApprover ||
      filters.deadlineFrom ||
      filters.deadlineTo ||
      filters.ownTasks
        ? '?'
        : '') +
      (filters.taskIdentifier ? `&identifier=${filters.taskIdentifier}` : '') +
      (filters.taskType?.name ? `&typeName=${filters.taskType?.name}` : '') +
      (filters.subject ? `&subject=${filters.subject}` : '') +
      (filters.state ? `&stateName=${filters.state}` : '') +
      (filters.responsible ? `&responsibleId=${filters.responsible}` : '') +
      (filters.firstApprover ? `&approverId=${filters.firstApprover}` : '') +
      (filters.secondApprover ? `&secondApproverId=${filters.secondApprover}` : '') +
      (filters.deadlineFrom ? `&deadlineFrom=${filters.deadlineFrom}` : '') +
      (filters.deadlineTo ? `&deadlineTo=${filters.deadlineTo}` : '') +
      (filters.ownTasks ? `&onlyOwnTasks=${filters.ownTasks}` : ''),
    idkey: 'id',
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: 'content',
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns',
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: '30sec',
          refreshInterval: 30,
          title: t('message.rvg_automatic_list_refresh_30_sec'),
          default: true,
        },
        {
          key: '1min',
          refreshInterval: 60,
          title: t('message.rvg_automatic_list_refresh_1_min'),
        },
        {
          key: '5min',
          refreshInterval: 300,
          title: t('message.rvg_automatic_list_refresh_5_min'),
        },
      ],
    },
    mapping: {
      $operations: {
        title: '',
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.review'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList: 'btn btn-secondary btn-circle btn-sm hover:!opacity-80',
                testValueAttribute: 'propertyEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  console.log(record);

                  router.push({
                    name: 'WorkflowTaskPage',
                    params: { taskId: record.id, view: 'read' },
                  });
                },
              });

              buttons.push({
                title: '',
                tooltip: t('message.edit'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-circle btn-sm hover:!opacity-80',
                testValueAttribute: 'propertyEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  //console.log(record);

                  router.push({
                    name: 'WorkflowTaskPage',
                    params: { taskId: record.id, view: 'edit' },
                  });
                },
              });

              /*buttons.push({
                title: "",
                tooltip: t("message.evaluation"),
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard2-check" viewBox="0 0 16 16"><path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/><path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/><path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/></svg>`,
                classList:
                  "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                testValueAttribute: "propertyEditButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  approveModalData.value.showModal = true;
                  approveModalData.value.taskId = record.id;
                },
              });*/

              /*buttons.push({
                title: "",
                tooltip: t("message.assign"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16"> <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" /> </svg>',
                classList:
                  "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                testValueAttribute: "propertyEditButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  assignModalData.value.showModal = true;
                  assignModalData.value.taskId = record.id;
                },
              });*/

              return buttons;
            },
          },
        },
      },
      id: {
        title: 'id',
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number',
        },
        exportable: true,
        orderable: true,
      },
      priority: {
        title: t('message.priority'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status',
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: 'text',
          mapping: {
            LOW: {
              title: t(`message.PriorityLow`),
            },
            MEDIUM: {
              title: t(`message.PriorityMedium`),
            },
            HIGH: {
              title: t(`message.PriorityHigh`),
            },
            null: {
              title: 'N/A',
            },
          },
        },
      },
      typeName: {
        title: t('message.taskType'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.typeName ? record.typeName : 'N/A';
        },
      },
      identifier: {
        title: t('message.identifier'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.identifier ? record.identifier : 'N/A';
        },
      },
      subject: {
        title: t('message.subject'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.subject ? record.subject : 'N/A';
        },
      },
      responsibleName: {
        title: t('message.responsible'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record ? record.responsibleName : 'N/A';
        },
      },
      stateName: {
        title: t('message.state'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.stateName ? record.stateName : 'N/A';
        },
      },
      createdAt: {
        title: t('message.taskCreatedAt'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Date',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.createdAt ? new Date(record.createdAt).toLocaleString() : 'N/A';
        },
      },
      createdByName: {
        title: t('message.createdBy'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record ? record.createdByName : 'N/A';
        },
      },
      updatedAt: {
        title: t('message.taskUpdatedAt'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Date',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.updatedAt ? new Date(record.updatedAt).toLocaleString()/*moment(record.updatedAt).format('YYYY-MM-DD')*/ : 'N/A';
        },
      },
      deadline: {
        title: t('message.deadline'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Date',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.deadline ? new Date(record.deadline).toLocaleDateString() : 'N/A';
        },
      },
      approverName: {
        title: t('message.first_approval_user_id'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text',
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record ? record.approverName : 'N/A';
        },
      },
      approvalDate: {
        title: t('message.approvalDate'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Date',
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.approvalDate ? new Date(record.approvalDate).toLocaleString() : 'N/A';
        },
      },
      secondApproverName: {
        title: t('message.second_approval_user_id'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text',
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record ? record.secondApproverName : 'N/A';
        },
      },
      secondApprovalDate: {
        title: t('message.secondApprovalDate'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Date',
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.secondApprovalDate
            ? new Date(record.secondApprovalDate).toLocaleString()
            : 'N/A';
        },
      },
      resolvedAt: {
        title: t('message.resolvedAt'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Date',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.resolvedAt ? new Date(record.resolvedAt).toLocaleString() : 'N/A';
        },
      },
    },
  };

  return config;
};
