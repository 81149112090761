<template>
  <div class="RobberVueGridFiltering mt-2 flex flex-row gap-2 items-center">
    <button
      title="Szűrőfeltétel hozzáadása"
      class="btn btn-sm"
      @click="
        () => {
          filteringModalShown = true;
        }
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
        />
      </svg>
    </button>
    <div class="filtersSection">
      <div class="currentFiltersHolder">
        <span class="noFilterMessage" v-if="filters.length <= 0">
          <!-- Nincs beállított szűrőfeltétel... -->
          {{ $t('message.noAppliedFilters') }}
        </span>
        <span class="flex flex-row items-center" v-if="filters.length > 0">
          <span v-for="filter in tweakedFilters" :key="filter.field">
            <ul class="flex flex-row" v-if="!filter._opselector">
              <li class="flex flex-row items-center">
                <a
                  @click="removeFilter(filter.field)"
                  :title="filter.textual"
                  :disabled="false"
                  class="tag flex flex-row items-center bg-magenta px-2 py-0.5 rounded-lg text-slate-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                    />
                  </svg>
                  <span>{{ filter.textual }}</span>
                </a>
              </li>
            </ul>
            <ul class="list-inline d-inline-block mb-0 ml-2 mr-2" v-else>
              <li class="list-inline-item tagContent">
                <select
                  class="form-select h-7 w-full rounded-lg border border-slate-300 bg-white px-2.5 text-xs+ hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                  data-test="filterOperatorSelector"
                  v-model="operator"
                >
                  <option value="OR">VAGY</option>
                  <option value="AND">ÉS</option>
                </select>
              </li>
            </ul>
          </span>
        </span>
      </div>
    </div>

    <!-- :disabled="!currentFilterValue || !currentFilterValue.isValid" -->
    <CustomContentModal
      :show="filteringModalShown"
      title="Szűrőfeltétel hozzáadása"
      cancelButtonTitle="Mégse"
      okButtonTitle="OK"
      :close="
        () => {
          filteringModalShown = false;
        }
      "
      :ok="addFiltering"
    >
      <template #content>
        <div class="pb-2">
          <label class="pull-left" for="selectorField">Mező kiválasztása:</label>
          <select
            class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
            v-model="selectorField"
          >
            <option v-for="(text, key) in selectorFieldOptions" :key="key" :value="key">
              {{ text }}
            </option>
          </select>
          <component
            v-if="selectorField != 'null' && currentFilterableConfig"
            @changeValue="changeFilterValue"
            v-bind:is="'RVGFilterable' + currentFilterableConfig.type"
            :config="currentFilterableConfig"
            :id="selectorField"
            :formatterConfig="formatterConfig"
          />
        </div>
      </template>
    </CustomContentModal>
  </div>
</template>

<script>
// filterables
import RVGFilterableNumber from './Filterables/RVGFilterableNumber.vue';
import RVGFilterableNumberConfig from './Filterables/RVGFilterableNumber.config.js';

import RVGFilterableInteger from './Filterables/RVGFilterableInteger.vue';
import RVGFilterableIntegerConfig from './Filterables/RVGFilterableInteger.config.js';

import RVGFilterableText from './Filterables/RVGFilterableText.vue';
import RVGFilterableTextConfig from './Filterables/RVGFilterableText.config.js';

import RVGFilterableDate from './Filterables/RVGFilterableDate.vue';
import RVGFilterableDateConfig from './Filterables/RVGFilterableDate.config.js';

import RVGFilterableStatus from './Filterables/RVGFilterableStatus.vue';
import RVGFilterableStatusConfig from './Filterables/RVGFilterableStatus.config.js';

import RVGFilterableLookup from './Filterables/RVGFilterableLookup.vue';

import CustomContentModal from './Components/CustomContentModal.vue';

export default {
  name: 'RobberVueGridFiltering',
  props: [
    'filteringConfig',
    'dataMapping',
    'pushFilter',
    'removeFilter',
    'filters',
    'filterOperator',
    'changeFilterOperator',
  ],
  components: {
    RVGFilterableNumber,
    RVGFilterableInteger,
    RVGFilterableText,
    RVGFilterableDate,
    RVGFilterableStatus,
    RVGFilterableLookup,
    CustomContentModal,
  },
  data() {
    return {
      selectorField: null,
      currentFilterValue: null,
      operator: 'OR',
      filteringModalShown: false,
    };
  },
  mounted() {
    this.operator = this.filterOperator;
  },
  watch: {
    filterOperator() {
      this.operator = this.filterOperator;
    },
    operator() {
      if (this.changeFilterOperator) {
        this.changeFilterOperator(this.operator);
      }
    },
  },
  computed: {
    tweakedFilters() {
      const newFilters = [];
      let firstOne = true;
      let opCounter = 0;
      for (const i in this.filters) {
        const currentFilter = this.filters[i];
        if (!firstOne) {
          newFilters.push({
            _opselector: true,
            field: 'op' + opCounter,
            textual: '',
          });
          ++opCounter;
        }

        const cNewFilter = {
          field: currentFilter.field,
          operation: currentFilter.operation,
          type: currentFilter.type,
          value: currentFilter.value,
        };

        switch (currentFilter.type) {
          case 'number':
            cNewFilter.textual = RVGFilterableNumberConfig.getTextual(
              currentFilter.operation,
              currentFilter.value
            ).replace('%%fieldname%%', this.dataMapping[currentFilter.field].title);
            break;
          case 'integer':
            cNewFilter.textual = RVGFilterableIntegerConfig.getTextual(
              currentFilter.operation,
              currentFilter.value
            ).replace('%%fieldname%%', this.dataMapping[currentFilter.field].title);
            break;
          case 'text':
            cNewFilter.textual = RVGFilterableTextConfig.getTextual(
              currentFilter.operation,
              currentFilter.value
            ).replace('%%fieldname%%', this.dataMapping[currentFilter.field].title);
            break;
          case 'date':
            cNewFilter.textual = RVGFilterableDateConfig.getTextual(
              currentFilter.operation,
              currentFilter.value,
              true
            ).replace('%%fieldname%%', this.dataMapping[currentFilter.field].title);
            break;
          case 'status':
            cNewFilter.textual = RVGFilterableStatusConfig.getTextual(
              currentFilter.operation,
              currentFilter.value,
              this.dataMapping[currentFilter.field].formatter
            ).replace('%%fieldname%%', this.dataMapping[currentFilter.field].title);
            break;
        }

        newFilters.push(cNewFilter);

        firstOne = false;
      }

      return newFilters;
    },
    selectorFieldOptions() {
      var options = {
        null: '-- Mező kiválasztása --',
      };
      for (var i in this.dataMapping) {
        if (this.dataMapping[i].filterable && this.dataMapping[i].filterable.active) {
          var title = this.dataMapping[i].title;
          if (this.dataMapping[i].filterable.titleOverride) {
            title = this.dataMapping[i].filterable.titleOverride;
          }

          options[i] =
            title + ' (' + this.translateTypeToHumanType(this.dataMapping[i].filterable.type) + ')';
        }
      }

      return options;
    },
    currentFilterableConfig() {
      if (
        !this.selectorField ||
        !this.dataMapping[this.selectorField] ||
        !this.dataMapping[this.selectorField].filterable
      ) {
        return null;
      }

      return this.dataMapping[this.selectorField].filterable;
    },
    formatterConfig() {
      if (
        !this.selectorField ||
        !this.dataMapping[this.selectorField] ||
        !this.dataMapping[this.selectorField].formatter
      ) {
        return null;
      }

      return this.dataMapping[this.selectorField].formatter;
    },
    needOperatorSelector() {
      if (!this.filters) {
        return false;
      }

      return this.filters.length > 1;
    },
  },
  methods: {
    translateTypeToHumanType(type) {
      switch (type.toLowerCase()) {
        case 'number':
          return 'Szám';
        case 'integer':
          return 'Egész szám';
        case 'text':
          return 'Szöveges';
        case 'date':
          return 'Dátum';
        case 'user':
          return 'Felhasználó';
        case 'status':
          return 'Státusz';
        case 'lookup':
          return 'Elem';
        default:
          return 'Szöveges';
      }
    },
    changeFilterValue(field, obj) {
      this.currentFilterValue = obj;
    },
    addFiltering() {
      if (!this.currentFilterValue || !this.currentFilterValue.isValid) {
        return;
      }

      this.pushFilter({
        field: this.currentFilterValue.field,
        filterKey: this.currentFilterValue.filterKey,
        type: this.currentFilterValue.type,
        dataType: this.currentFilterValue.dataType,
        operation: this.currentFilterValue.operation,
        value: this.currentFilterValue.value,
        // textual: this.currentFilterValue.textual.replace("%%fieldname%%", this.dataMapping[this.currentFilterValue.field].title)
      });

      this.filteringModalShown = false;

      this.selectorField = null;
    },
  },
};
</script>

<style scoped>
.pull-left {
  float: left;
}

.filtersSection,
.filtersSectionSeparator,
.filterOperatorSelector {
  display: inline-block;
}

.filtersSectionSeparator {
  margin-left: 15px;
  margin-right: 15px;
}

.currentFiltersHolder {
  display: inline-block;
}

.noFilterMessage {
  font-weight: 300;
  font-style: italic;
}

.filterBadge {
  font-size: 14px;
}

.tagContent:deep(.badge) {
  margin-right: 0px;
}
</style>
