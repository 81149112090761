<template>
  <div id="MapView" class="h-full">
    <!-- DATEPICKER -->
    <div class="datePckr absolute !z-[900] top-4 right-4">
      <div class="input-group input-group-sm">
        <button class="btn btn-secondary btn-square">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
          </svg>
        </button>
        <flat-pickr class="input input-bordered input-md" v-model="pickerDate" />
      </div>
    </div>
    <!-- DATEPICKER -->

    <!-- LAYERS -->
    <!-- <TopRightSection v-if="zoneGroups && zoneGroups.length > 0" :onZoneGroupChange="onZoneGroupChange" :zoneGroups="zoneGroups" /> -->
    <!-- LAYERS -->

    <LeafletMap :key="'lmap_' + selectedSiteDesign.id" v-if="(selectedSiteDesign != null)" class="lMap" ref="map" :mapName="selectedSiteDesign.map" :geometry="selectedSiteDesign.geometry" :onMapReady="onMapReady">
      <template #mapContent v-if="mapReady">
        <div v-if="zones.length > 0">
          <LPolygon @mouseenter="onPolygonEnter" @mouseleave="onPolygonLeave" @click="() => { zoneClick(zone) }" v-for="(zone) in zonesWithColors" :key="'poly_' + zone.id" :lat-lngs="mapServerPointsToPolygonData(zone.points)" :fillOpacity="zone.fillOpacity" :fillColor="zone.fillColor" :color="zone.lineColor" :opacity="zone.lineOpacity" :weight="2">
            <l-tooltip :options="{permanent: true, direction:'center', interactive: true }" :key="'tooltip_' + zone.id">
              <div :class="['rounded-lg border-none z-[650] flex flex-col flex-nowrap items-center cursor-pointer relative']">
                <div class="rounded-full bg-white opacity-90 text-xs flex flex-col items-center justify-center px-1 font-sans text-[12px]" :title="zone.displayName" style="padding: 3px 8px;">
                  <div class="flex flex-row items-center gap-[0.5] justify-center">
                    <span class="font-bold text-[16px]">
                      {{ zone.currentPresenceGist }}
                    </span>
                    <span class="">
                      / {{ zone.numberOfWorkplaces }}
                    </span>
                  </div>
                </div>
              </div>
            </l-tooltip>
          </LPolygon>
        </div>
      </template>
    </LeafletMap>

  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/themes/dark.css';
//import PolygonContent from './PolygonContent.vue';
import { LPolygon, LTooltip } from '@vue-leaflet/vue-leaflet';
import "leaflet-draw/dist/leaflet.draw.css";
import LeafletMap from '../../components/LeafletMap2.vue';
import Utils from '../../misc/Utils';
import { useRoute } from 'vue-router';
import router from '../../misc/router';
//import TopRightSection from './TopRightSection.vue';
//import WeatherUtils from '../../misc/WeatherUtils';



export default {
  name: 'MapView',
  props: {
  },
  components: {
    LPolygon,
    LeafletMap,
    flatPickr,
    //TopRightSection,
    LTooltip
    //PolygonContent
  },
  data() {
    return {
      loading: false,
      currentDrawedMarkers: [],
      zones: [],
      zoneGroups: [],
      mapDimensionsX: null,
      mapDimensionsY: null,
      mapReady: false,
      //layers: [process.env.VUE_APP_DEFAULT_LAYER],
      renderCode: 0,
      pickerDate: '',
      isStarting: true,
      starterZone: null
    }
  },
  computed: {
    date() {
      return this.$store.getters.getCurrentDate;
    },
    sidebarEntity() {
      return this.$store.getters.getSidebarEntity;
    },
    zoomLevel() {
      return this.$store.getters.getZoomLevel;
    },
    selectedSiteDesign() {
      return this.$store.getters.getSelectedSiteDesign;
    },
    activeZoneGroup() {
      if (this.sidebarEntity && this.sidebarEntity.type == "zoneGroup" && this.sidebarEntity.entity) {
        return this.sidebarEntity.entity;
      }
      return null;
    },
    zonesWithColors() {
      const MIN_OPACITY = 0.2;
      const MAX_OPACITY = 0.7;
      const HEAT_BASE = 'currentPresenceGist';
      const maxValue = Math.max.apply(Math, this.zones.map(function (o) { return o[HEAT_BASE]; }))
      const minValue = Math.min.apply(Math, this.zones.map(function (o) { return o[HEAT_BASE]; }))

      const zonesWithColors = [];
      for (let i in this.zones) {
        let cZone = this.zones[i];
        cZone.lineOpacity = 0.5;
        cZone.fillOpacity = minValue == maxValue ? MIN_OPACITY : (((cZone[HEAT_BASE] - minValue) / (maxValue - minValue)) * (MAX_OPACITY - MIN_OPACITY)) + MIN_OPACITY;
        if (this.activeZoneGroup && cZone.zoneGroupIds.includes(this.activeZoneGroup.id)) {
          cZone.fillColor = this.activeZoneGroup.color;
          cZone.lineColor = this.activeZoneGroup.color;
        }
        else {
          cZone.fillColor = process.env.VUE_APP_POLYGON_FILL_COLOR;
          cZone.lineColor = process.env.VUE_APP_POLYGON_LINE_COLOR;
        }
        // if(cZone.id == this.sidebarEntityZoneId) {
        //   const cZoneGroup = this.parseZoneGroups(cZone.zoneGroupIds);
        //   if(cZoneGroup && cZoneGroup.length > 0) {
        //     cZone.fillColor = cZoneGroup[0].color;
        //     cZone.lineColor = cZoneGroup[0].color;
        //   }
        //   else {
        //     cZone.fillColor = process.env.VUE_APP_POLYGON_FILL_COLOR;
        //     cZone.lineColor = process.env.VUE_APP_POLYGON_LINE_COLOR;
        //   }
        // }
        // else {

        // }

        zonesWithColors.push(cZone)
      }
      return zonesWithColors;
    }
  },
  async mounted() {

    if (this.selectedSiteDesign) {
      this.fetchZones();
      const route = useRoute();
      this.starterZone = route.params.zone;

    }

    this.pickerDate = this.date;


  },
  watch: {
    selectedSiteDesign() {
      console.log("SELECTED_SITE_DESIGN_CHANGED")
      this.mapReady = false;
      this.fetchZones();

    },
    pickerDate() {
      this.$store.commit('setCurrentDate', this.pickerDate)
    },
    isStarting(newVal,oldVal){
      if(oldVal == true && this.starterZone){
        this.openSidebarOnLoad();
      }
    }
  },
  methods: {
    onZoneGroupChange(zoneGroupIds) {
      console.log(zoneGroupIds)
    },
    onPolygonEnter() {
    },
    onPolygonLeave() {
    },
    zoneClick(zone) {
      if (!this.sidebarEntity || !this.sidebarEntity.entity || this.sidebarEntity.entity.id != zone.id) {
        const zoneGroups = this.parseZoneGroups(zone.zoneGroupIds);
        router.push({ name: 'map', params: { zone: zone.id } });
        
        this.$store.commit('showSidebar', {
          type: 'zone', 
          entity: zone, 
          params: {
            zoneGroups: zoneGroups
          },
          onClose: () => {
            router.push({ name: 'map', params: { siteDesignId: this.selectedSiteDesign.id } });
          }
        });
      }
      else {
        this.$store.commit('hideSidebar');
      }
    },
    parseZoneGroups(zoneGroupIds) {
      return this.zoneGroups.filter(zg => zoneGroupIds.includes(zg.id));
    },
    openSidebarOnLoad() {
      
      //console.log("mounted?");
      

      if (this.starterZone) { // directly linked or page refreshed
        
        const z = this.zones.find(element => element.id == this.starterZone);
        
        if (!this.sidebarEntity || !this.sidebarEntity.entity || this.sidebarEntity.entity.id != z.id) {
          
          const zoneGroups = this.parseZoneGroups(z.zoneGroupIds);
          this.$store.commit('showSidebar', {
            type: 'zone', 
            entity: z, 
            params: {
              zoneGroups: zoneGroups
            },
            onClose: () => {
              router.push({ name: 'map', params: { siteDesignId: this.selectedSiteDesign.id } });
            }
          });
        }
      }

    },
    async fetchZones() {
      this.loading = true;
      this.zones = [];
      const response = await Utils.fetchZones(this.selectedSiteDesign.id);
      this.zones = response.zones;
      this.zoneGroups = [];
      const zoneGroupColors = process.env.VUE_APP_ZONE_GROUPS_COLOR.split(',');
      for (var i in response.zone_groups) {
        let cZoneGroup = response.zone_groups[i];
        cZoneGroup.color = zoneGroupColors[i % zoneGroupColors.length];
        this.zoneGroups.push(cZoneGroup);
      }
      this.loading = false;
      this.isStarting = false;
     
    },
    mapServerPointsToPolygonData(points) {
      var result = [];

      for (var i in points) {
        result.push(this.transformPointsToMap(points[i].x, points[i].y))
      }

      return result;
    },
    transformPointsToMap(y, x) {
      if (!this.selectedSiteDesign.geometry) {
        return;
      }

      return Utils.transformPointsToMap(y, x, this.mapDimensionsX, this.mapDimensionsY, this.selectedSiteDesign.geometry, this.selectedSiteDesign.dimension)
    },
    onMapReady(mapDimensionsX, mapDimensionsY) {
      console.log("MAP_READY")
      this.mapDimensionsX = mapDimensionsX
      this.mapDimensionsY = mapDimensionsY
      this.mapReady = true

    }
  }
}
</script>

<style scoped>
.lMap>>>.leaflet-tooltip {
  background: transparent !important;
  border: none !important;
  box-shadow: none;
}

.lMap>>>.leaflet-tooltip-pane:hover,
.lMap>>>.leaflet-tooltip:hover {
  z-index: 9999;
}
</style>
