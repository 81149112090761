<template>
    <div>
        <input type="checkbox" class="modal-toggle" v-model="openModal" />
        <div class="modal">
            <div class="modal-box">
                <h2 class="text-lg text-secondary font-bold mb-4">
                    {{ $t("message.wfAddPremise") }}
                </h2>
                <hr class="mb-4" />
                <span class="text-secondary font-bold my-4">{{
                    $t("message.WfPropertyData")
                }}</span>
                <!-- eslint-disable -->
                <ImFormInput v-model:inputValue="state.searchParams.address.value"
                    :title="$t('message.' + state.searchParams.address.title)" type="text" :mandatory="true"
                    class="my-4" />
                <ImFormInput v-model:inputValue="state.searchParams.floc.value"
                    :title="$t('message.' + state.searchParams.floc.title)" type="text" class="mb-4" />
                <!-- eslint-enable -->



                <span class="text-secondary font-bold my-4">{{
                    $t("message.WfBuildingData")
                }}</span>
                <!-- eslint-disable -->
                <ImFormInput v-model:inputValue="state.searchParams.sapId.value"
                    :title="$t('message.' + state.searchParams.sapId.title)" type="text" class="my-4" />
                <ImFormInput v-model:inputValue="state.searchParams.buildingMarking.value"
                    :title="$t('message.' + state.searchParams.buildingMarking.title)" type="text" class="mb-4" />
                <!-- eslint-enable -->

                <span class="text-secondary font-bold my-4">{{
                    $t("message.WfPremiseData")
                }}</span>
                <!-- eslint-disable -->
                <ImFormInput v-model:inputValue="state.searchParams.premiseFunction.value"
                    :title="$t('message.' + state.searchParams.premiseFunction.title)" type="option"
                    optionValue="PREMISE_FUNCTION" class="my-4" :searchable="true" />
                <ImFormInput v-model:inputValue="state.searchParams.identifier.value"
                    :title="$t('message.' + state.searchParams.identifier.title)" type="text" class="mb-4" />
                <!-- eslint-enable -->
                <RemPrimaryButtonSmall @click="() => {
                    setUpSelectorEndPoint(true);
                    state.updateKey = state.updateKey + 1;
                }
                " :label="$t('message.search')" class="mb-4" />

                <div>
                    <span class="text-secondary font-bold mb-4">{{
                        $t("message.wfSearchResult")
                    }}</span>
                    <RobberVueGrid ref="premiseSelectorGrid" :config="premiseSelectorGridConfigComputed"
                        :key="state.updateKey" />
                </div>

                <div>
                    <span class="text-secondary font-bold mb-4">{{
                        $t("message.wfSelectedObjects")
                    }}</span>
                    <RobberVueGrid ref="premiseSelectedGrid" :config="premiseSelectedConfigComputed"
                        :key="state.updateKey" />
                </div>

                <RemPrimaryButtonSmall :label="$t(`message.okay`)" :method="() => {
                    savePremiseModal()
                    openModal = false;
                }
                    " class="mr-4">
                </RemPrimaryButtonSmall>
                <RemPrimaryButtonSmall :label="$t(`message.cancel`)" :method="() => {

                    openModal = false;
                }
                    ">
                </RemPrimaryButtonSmall>
            </div>
        </div>
    </div>
</template>

<script setup>
import RobberVueGrid from "../../../RVG/RobberVueGrid.vue";
import RemPrimaryButtonSmall from "../../RemPrimaryButtonSmall.vue";
import ImFormInput from "../../../ImFormInput.vue";

import { defineModel, ref, onMounted, watch } from "vue";
import PropertySelectorConfigs from "./PropertySelectorConfigs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import toast from "@/misc/toast";
import axios from '@/misc/axios';
import TenantConfigHandler from '@/misc/TenantConfigHandler';

let router = useRouter();

const { t } = useI18n();

const openModal = defineModel("openModal");
const inputValue = defineModel("inputValue");
const saveEvent = defineModel("modalSaveEvent");

const state = ref({
    rvgUpdate: 0,
    updateKey: 0,
    searchParams: {
        address: {
            title: "address",
            value: "",
        },
        floc: {
            title: "floc",
            value: "",
        },
        sapId: {
            title: "sapBuildingId",
            value: ''
        },
        buildingMarking: {
            title: "buildingMarking",
            value: ''
        },
        premiseFunction: {
            title: "function",
            value: ''
        },
        identifier: {
            title: "identifier",
            value: ''
        }
    },
    selectedIds: [],
    selectorEndpoint: "/rem/premise/filtered-premises",
    selectorFilterinit: "",
    selectedEndpoint: "/rem/premise/filtered-premises",
    selectedFilterinit: "",
});

onMounted(() => {
    setUpSelectorEndPoint();
    setUpSelectedEndPoint();

    state.value.updateKey = state.value.updateKey + 1;
    state.value.loaded = true;
});

let premiseSelectorGridConfigComputed =
    PropertySelectorConfigs.getPremiseSelectorConfig(state, t, router, state.value.selectorEndpoint);

let premiseSelectedConfigComputed =
    PropertySelectorConfigs.getPremiseSelectedConfig(state, t, router, state.value.selectedEndpoint);

const setUpSelectorEndPoint = (button) => {
    state.value.selectorEndpoint = "/rem/premise/filtered-premises";

    state.value.selectorFilterinit = false;
    if (button == true && state.value.searchParams.address.value == "") {
        addArraytoEndpoint(['0'], "include", 'selector');
        toast.error(t('message.mandatoryFieldAddress'));
        button = false;
    } else {


        if (state.value.searchParams.address.value == "") {
            addArraytoEndpoint(['0'], "include", 'selector');
        } else {
            addArraytoEndpoint(state.value.selectedIds, "exclude", "selector");
            if (inputValue.value.length > 0) {
                addArraytoEndpoint(inputValue.value, "exclude", 'selector');
            }
            if (state.value.searchParams.floc.value != "") {
                addArraytoEndpoint(
                    [state.value.searchParams.floc.value],
                    "floc",
                    "selector"
                );
            }
            if (state.value.searchParams.address.value != "") {
                addArraytoEndpoint([state.value.searchParams.address.value], "address", 'selector');
            }
            if (state.value.searchParams.sapId.value != "") {
                addArraytoEndpoint([state.value.searchParams.sapId.value], "sapId", 'selector');
            }
            if (state.value.searchParams.buildingMarking.value != "") {
                addArraytoEndpoint([state.value.searchParams.buildingMarking.value], "buildingMarking", 'selector');
            }
            if (state.value.searchParams.premiseFunction.value != "") {
                addArraytoEndpoint([state.value.searchParams.premiseFunction.value], "functionId", 'selector');
            }
            if (state.value.searchParams.identifier.value != "") {
                addArraytoEndpoint([state.value.searchParams.identifier.value], "identifier", 'selector');
            }
        }



    }


    premiseSelectorGridConfigComputed =
        PropertySelectorConfigs.getPremiseSelectorConfig(
            state,
            t,
            router,
            state.value.selectorEndpoint
        );

    if (button == true) {
        responseCheck()
    }
};

const setUpSelectedEndPoint = () => {
    state.value.selectedEndpoint = "/rem/premise/filtered-premises";

    state.value.selectedFilterinit = false;

    if (state.value.selectedIds.length == 0) {
        addArraytoEndpoint(['0'], "include", 'selected');
    } else {
        addArraytoEndpoint(state.value.selectedIds, "include", 'selected');
    }

    premiseSelectedConfigComputed =
        PropertySelectorConfigs.getPremiseSelectedConfig(
            state,
            t,
            router,
            state.value.selectedEndpoint
        );

};

const addArraytoEndpoint = async (array, filter, tag) => {
    if (array.length > 0) {
        state.value[`${tag}Endpoint`] += state.value[`${tag}Filterinit`] == false ? "?" : "";


        for (let i = 0; i < array.length; i++) {
            if (i != 0 || state.value[`${tag}Filterinit`] == true) {
                state.value[`${tag}Endpoint`] += "&";
            }
            state.value[`${tag}Endpoint`] += `${filter}=${array[i]}`;
        }
        state.value[`${tag}Filterinit`] = true;
    }
};

const responseCheck = async () => {
    let responseCheck = await axios.get(
        TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        state.value.selectorEndpoint,
        {
            onTokenRefreshed: () => {
                this.getTask(state);
            },
        }
    );

    console.log(responseCheck.c);

    if (responseCheck?.data?.content?.length < 1) {
        toast.error(t("message.noObjectFound"))
    }
}

const savePremiseModal = () => {
    let temp = inputValue.value ? structuredClone(inputValue.value) : []

    for (let e of state.value.selectedIds) {
        // console.log(e, temp.find(j => j==e));

        if (!temp.find((j) => j == e)) {
            temp.push(e)
        }
    }
    inputValue.value = temp
    saveEvent.value = saveEvent.value + 1
    openModal.value = false;
};

watch(
    () => state.value.rvgUpdate,
    () => {

        setUpSelectorEndPoint();
        setUpSelectedEndPoint();
        setTimeout(() => {
            state.value.updateKey = state.value.updateKey + 1;
        }, 500)

    }
);
</script>

<style scoped>
.modal-box {
    width: 75%;
    max-width: 75%;
}
</style>
