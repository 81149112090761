<template>
  <time class="mb-1 text-xs font-normal text-end text-gray-400 sm:order-last sm:mb-0">
    {{ dateDisplay(dateString) }} <br> {{ timeDisplay(dateString) }}
  </time>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
  dateString: String,
});

function toLocaleDateAndTime(dateString) {
  const date = new Date(dateString);
  const localeString = date.toLocaleString();
  const dateParts = localeString.split(' ');
  let localeDate = dateParts[0];
  let localeTime = dateParts[1];
  if (dateParts.length > 2) {
    localeDate = dateParts[0] + dateParts[1] + dateParts[2];
    localeTime = dateParts[3];
  }
  return [localeDate, localeTime];
}

function dateDisplay(dateString){
  return toLocaleDateAndTime(dateString)[0];
}

function timeDisplay(dateString){
  return toLocaleDateAndTime(dateString)[1];
}

</script>
