<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box overflow-y-visible">
        <div class="h-full w-full">
          <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
              >
                <span class="text-secondary">{{ $t('message.attachTask') }}</span>
              </h2>
            </div>
            <a
              href="javascript:void(null)"
              class="hover:text-secondary"
              @click="state.showAbortConfirmation = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>

          <div class="px-5 pb-5 mt-5">
            <div class="flex flex-col gap-2 w-full h-full">
              <!--eslint-disable-->

              <div :class="{ hideOpenIndicator: state.associationToAdd }" class="w-full">
                <label class="label mt-0 pt-0" for="">
                  <span class="label-text-alt">{{ $t('message.associationType') }}</span>
                </label>
                <div class="dropdown w-full flex flex-row justify-between">
                  <select
                    class="input input-sm input-bordered w-full"
                    v-model="state.associationToAdd"
                  >
                    <option
                      v-for="association in state.associationTypes"
                      v-bind:key="association.id"
                      :value="association.id"
                    >
                      {{ $t(`message.${association.code}`) }}
                    </option>
                  </select>
                  <button
                    v-if="state.associationToAdd"
                    class="clear-button"
                    @click="state.associationToAdd = null"
                    aria-label="Törlés"
                  >
                    <span class="text-sm bold"> X </span>
                  </button>
                </div>
              </div>

              <div class="flex flex-row my-0 mt-2 w-full grow">
                <!--eslint-disable-->
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{ $t('message.linkableTasks') }}</span>
                  </label>
                  <SearchOptions
                    v-model:inputValue="taskToAdd"
                    type="tasks"
                    :taskId="route.params.taskId"
                    ref="searchOptions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label
            class="btn btn-sm bg-gray-800 text-white font-light text-xs"
            @click="
              () => {
                state.showAbortConfirmation = true;
              }
            "
            >{{ $t('message.cancel') }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                addThisTask();
                store.commit('setIsModalOpen', false);
              }
            "
            >{{ $t('message.save') }}</label
          >
        </div>
      </div>
    </div>
    <input type="checkbox" class="modal-toggle" v-model="state.showAbortConfirmation" />
    <div class="modal">
      <div class="modal-box">
        <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
          {{ $t('message.abortAttachingTask') }}
        </h3>
        <div class="modal-action">
          <label
            class="btn btn-sm text-white font-light text-xs"
            @click="
              () => {
                state.showAbortConfirmation = false;
              }
            "
            >{{ $t('message.deny') }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                state.showAbortConfirmation = false;
                closeModal();
              }
            "
            >{{ $t('message.confirm') }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch, ref } from 'vue';
import store from '@/misc/vuex-store.js';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import axios from '@/misc/axios.js';
import toast from '@/misc/toast';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import SearchOptions from '@/components/SearchOptions.vue';

const { t } = useI18n();
const route = useRoute();
const taskId = route.params.taskId;

const emits = defineEmits(['refresh']);
const props = defineProps(['showModal']);
const taskToAdd = ref(null);
const searchOptions = ref(null);

const state = reactive({
  showModal: false,
  associationTypes: [],
  associationToAdd: null,
  showAbortConfirmation: false,
});

const getAssociationTypes = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/task/right-association-types`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        getAssociationTypes();
      },
    });
    state.associationTypes = response.data.content;
  } catch (error) {
    console.log(error);
  }
};

const addThisTask = async () => {
  const hermes = {
    associationTypeId: state.associationToAdd,
    rightTaskId: taskToAdd.value,
  };

  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/task/${taskId}/association`;
  try {
    await axios.post(uri, hermes, {
      onTokenRefreshed: () => {
        addThisTask();
      },
    });
    toast.success(t('message.successfulSave'));
    emits('refresh');
    state.associationToAdd = null;
    taskToAdd.value = null;
    searchOptions.value?.clearSuggestions();
  } catch (error) {
    toast.error(t('message.notSuccessfulSave'));
    console.log(error);
  }
};

const closeModal = () => {
  store.commit('setIsModalOpen', false);
};

watch(
  () => props.showModal,
  () => {
    state.showModal = props.showModal;
  }
);

onMounted(() => {
  getAssociationTypes();
});
</script>

<style scoped>
select[disabled] {
  @apply border border-gray-600;
}

.im-dropdown-content {
  position: absolute;
}

.im-invisible {
  display: none;
}

.dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.clear-button {
  position: absolute;
  right: 0.6rem;
  background: none;
  border: none;
  color: rgb(175, 170, 170);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
}

.clear-button:hover {
  cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
  display: none;
}

.dropdown::before {
  position: absolute;
  font-size: 12px;
  content: ' \25BC';
  top: 7px;
  right: -8px;
  height: 20px;
  width: 28px;
  pointer-events: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.option-hover {
  padding: 5px 0 5px 5px;
  font-size: 0.875rem;
}

.option-hover:hover {
  background-color: rgb(147, 185, 247);
  color: #242933;
}

ul .option-hover:first-of-type {
  border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
  border-radius: 0 0 10px 10px;
}
</style>
