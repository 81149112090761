<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box">
        <div class="h-full overflow-y-auto w-full">
          <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
              >
                <span class="text-secondary">{{ 'Import' }}</span>
              </h2>
            </div>
            <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>

          <div class="px-5 pb-5">
            <div class="flex flex-col items-center justify-center gap-2 w-full">
              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt">{{ $t('message.importFile') }}</span>
                  </label>
                  <input
                    class="file:p-2 file:bg-gray-800 file:border-none file:hover:cursor-pointer w-full text-sm text-gray-200 border border-gray-300 rounded-md bg-gray-50 dark:text-gray-200 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                    ref="importInput"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                importWf();
                store.commit('setIsImportModalOpen', false);
              }
            "
            >{{ $t('message.save') }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch, nextTick } from 'vue';
import store from '@/misc/vuex-store.js';
import { useRoute } from 'vue-router';
import wfAdminUtils from './wfAdminUtils';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const router = useRoute();
const taskTypeId = router.params.taskTypeId;

const state = reactive({
  showModal: false,
});

const importInput = ref(null);

const props = defineProps(['showModal', 'wfVersionsGrid']);

const importWf = async () => {
  await wfAdminUtils.importWf(taskTypeId, importInput, emptyForm, t, props.wfVersionsGrid);
  props.wfVersionsGrid?.refresh();
};

const closeModal = () => {
  store.commit('setIsImportModalOpen', false);
};

const emptyForm = () => {
  nextTick(() => {
    if (importInput.value) {
      importInput.value.value = ''; // Ha szükséges, állítsuk üresre
    }
  });
};

watch(
  () => props.showModal,
  (newVal) => {
    state.showModal = newVal;
    if (!newVal) {
      // A modal zárásakor is hívjuk a `emptyForm`-ot
      emptyForm();
    }
  }
);

onMounted(() => {});
</script>

<style scoped></style>
