<template>
    <div>
        <div v-for="item in state.basicData" v-bind:key="item.model" class="mb-8">
            <div v-if="store.getters.getRemFormEdit == false">
                <ImFormLabel :item="item"></ImFormLabel>
            </div>
            <div v-if="store.getters.getRemFormEdit == true">
                <!-- eslint-disable -->
                <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                    :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                    :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                    :displayValue="item.endPoint" :searchable="item.searchable"
                    :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                </ImFormInput>
                <!-- eslint-enable -->
            </div>
        </div>
        <div v-if="state.isStaire">
            <h2 class="text-xl text-secondary w-full font-bold">
                {{ $t("message.stairway") }}
            </h2>
            <hr class="mb-8" />
            <div v-for="item in state.staircaseData" v-bind:key="item.model" class="mb-8">
                <div v-if="store.getters.getRemFormEdit == false">
                    <ImFormLabel :item="item"></ImFormLabel>
                </div>
                <div v-if="store.getters.getRemFormEdit == true">
                    <!-- eslint-disable -->
                    <ImFormInput v-model:inputValue="item.value" :title="$t('message.' + item.title)" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.endPoint" :searchable="item.searchable">
                    </ImFormInput>
                    <!-- eslint-enable -->
                </div>
            </div>
        </div>

        <div v-if="state.isElevator">
            <h2 class="text-xl text-secondary w-full font-bold">
                {{ $t("message.elevator") }}
            </h2>
            <hr class="mb-8" />
            <div v-for="item in state.elevatorData" v-bind:key="item.model" class="mb-8">
                <div v-if="store.getters.getRemFormEdit == false">
                    <ImFormLabel :item="item"></ImFormLabel>
                </div>
                <div v-if="store.getters.getRemFormEdit == true">
                    <!-- eslint-disable -->
                    <ImFormInput v-model:inputValue="item.value" :title="$t('message.' + item.title)" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.endPoint" :searchable="item.searchable">
                    </ImFormInput>
                    <!-- eslint-enable -->
                </div>
            </div>
        </div>

        <!-- eslint-disable -->
        <RemFormButtonRowStored :saveMethod="savePremise" deleteMethod="">
        </RemFormButtonRowStored>
        <!-- eslint-enable -->
    </div>
</template>

<script setup>

// Components
import RemFormButtonRowStored from '../../components/REM/RemFormButtonRowStored.vue';
import ImFormInput from '../../components/ImFormInput.vue';
import ImFormLabel from '../../components/ImFormLabel.vue';

//Data
import basicData from '../../misc/remFormdata/premise/premiseBasicData.js';
import elevatorData from '../../misc/remFormdata/premise/premiseElevatorData.js';
import staircaseData from '../../misc/remFormdata/premise/premiseStaircaseData.js';

// Utils
import { ref, watch, defineModel, /*watch,computed, onMounted*/ } from "vue";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import Axios from "@/misc/axios";
import { useRoute } from "vue-router";
import Utils from "../../misc/Utils.js";
import remFormUtils from '../../misc/remFormUtils.js';
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import store from "@/misc/vuex-store";


const route = useRoute();
const premiseId = route.params.premiseId
const { t } = useI18n();
const saveMe = defineModel('saveMe');
const setMeBack = defineModel('setMeBack');
let mainPremise = defineModel('mainPremise')

const mainPrem = async () => {
  const resp = await Utils.fetchPremiseById(premiseId)
  mainPremise.value = resp
}

const state = ref({
    basicData: basicData,
    elevatorData: elevatorData,
    staircaseData: staircaseData,
    premiseEdit: false,
    isStaire: false,
    isElevator: false,
})

store.commit('setRemFormEdit', false);

async function getPremise() {
    const response = await Axios.get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        route.params.premiseId,
        {
            onTokenRefreshed: () => {
                getPremise();
            },
        }
    );
    if (response && response.data) {
        state.value.orig = response.data;

        remFormUtils.remGetOptions(
            response.data,
            state.value.basicData,
            true
        );
        remFormUtils.remGetOptions(
            response.data,
            state.value.staircaseData,
            true
        );
        remFormUtils.remGetOptions(
            response.data,
            state.value.elevatorData,
            true
        );
    }
}

getPremise();

// watchers

watch(() => state.value.basicData[0].value,
    () => {
        setTimeout(() => {
            state.value.isStaire = state.value.basicData[0].value == 216;
            state.value.isElevator = state.value.basicData[0].value == 217;
        }, 700);

    });

watch(() => saveMe.value, () => {
    savePremise();
})

watch(() => setMeBack.value, () => {
    
    
    remFormUtils.setBackData(state.value.basicData)
    remFormUtils.setBackData(state.value.elevatorData)
    remFormUtils.setBackData(state.value.staircaseData)
})



// functions

async function savePremise() {
    const hermes = {
        buildingId: state.value.orig.buildingId,
        basicData: {},
        partnerIds: [],
        //coolingHeatingDevice: state.value.orig.coolingHeatingDevice
    };
    state.value.basicData.forEach((e) => {
        if (e.type == "label") return;
        let model = e.model.replace("basicData", "");
        model = model.charAt(0).toLowerCase() + model.slice(1);
        hermes.basicData[model] = e.value === '' ? null: e.value;      
    });

    state.value.staircaseData.forEach((e) => {
        let model = e.model.replace("basicData", "");
        model = model.charAt(0).toLowerCase() + model.slice(1);
        hermes.basicData[model] = e.value === '' ? null: e.value; 
    });
    state.value.elevatorData.forEach((e) => {
        let model = e.model.replace("basicData", "");
        model = model.charAt(0).toLowerCase() + model.slice(1);
        hermes.basicData[model] = e.value === '' ? null: e.value; 
    });

    Axios.put(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        route.params.premiseId,
        hermes,
        {
            onTokenRefreshed: () => {
                this.asyncSavePremise(state, route.params.premiseId, t);
            },
        }
    ).then(function (resp) {
        if (resp) {
            if (resp.status == 200) {
                toast.success(t("message.successfulSave"));
                store.commit('setRemFormEdit', false)
                getPremise()
                mainPrem()
            } else {
                toast.error(t("message.notSuccessfulSave"));
            }
        } else {
            toast.error(t("message.notSuccessfulSave"));
        }
    });
}

</script>