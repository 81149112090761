export default {
  basic: {
    DATE: {
      name: "Dátum",
      config: {
        backendType: "DATE",
        backendName: "date",
        type: "date",
      },
    },
    NUMBER: {
      name: "Egész Szám",
      config: {
        backendType: "NUMBER",
        backendName: "number",
        type: "integer",
      },
    },
    DOUBLE: {
      name: "Szám",
      config: {
        backendType: "NUMBER",
        backendName: "number",
        type: "positiveNumber",
      },
    },
    USER_DATA: {
      name: "Felhasználó Kiválasztás",
      config: {
        backendType: "NUMBER",
        backendName: "userName",
        type: "optionSearchEndpoint",
        optionSearchEndpoint: "uploader",
        otherData:[],
      },
    },
    SINGLE_VALUE: {
      name: "Szöveges Mező",
      config: {
        backendType: "STRING",
        backendName: "userName",
        type: "text",
      },
    },
    OPTION_VALUE: {
      name: "Lenyíló Lista",
      config: {
        backendType: "NUMBER",
        backendName: "userName",
        type: "option",
      },
    },
    ENQUIRY_TYPE: {
      name: "Lenyíló Lista",
      config: {
        backendType: "NUMBER",
        backendName: "optionValue",
        type: "option",
        optionValue: "WORKFLOW_ENQUIRY"
      },
    },
    MONEY_CURRENCY: {
      name: "Pénznem",
      config: {
        backendType: ["STRING", "NUMBER" ],
        backendName: ["Üzemeltetési díj", "optionValue"],
        type: "currencyField",
        optionValue: "CURRENCY"
      },
    },
    DATE_TIME: {
      name: "Pénznem",
      config: {
        backendType: "DATE_TIME",
        backendName: "dateTime",
        type: "datetime-local",
        
      },
    },
  }
  
};
