import typeConfigs from './typeConfigs';
//import optionValueConfig from "./optionValueConfig"
import validationConfigs from './validationConfigs';

export default {
  createConfig(field, formReadonly, fieldList) {
    //console.log(formReadonly);

    if (field.displayable == false) {
      return false;
    }

    if (field.metaDataName == 'MONEY_QUANTITY') {
      return false;
    }

    let config={};

    if(field.metaDataBusinessType == "SINGLE_VALUE" && (field.metaDataValueType == "NUMBER" || field.metaDataValueType == "DOUBLE")){
      config = structuredClone(typeConfigs.basic[field.metaDataValueType]?.config);
    }else{
      config = structuredClone(typeConfigs.basic[field.metaDataBusinessType]?.config);
    }

    

    if (!config) {
      console.log(`No config was found for ${field.metaDataBusinessType}`);

      return false;
    }

    /*if(config.type == "option"){
            config.optionValue = optionValueConfig[field.metaDataValueConverterParam]
        }*/
    if (field.metaDataBusinessType == 'MONEY_CURRENCY') {
      config = this.addCompositGenericFields(field, config, formReadonly, fieldList);
    } else {
      config = this.addGenericFields(field, config, formReadonly);
    }
    //config.metaDataValueType = field.metaDataValueType
    if (field.validationName) {
      config.validationMethod = validationConfigs[field.validationName].script;
    }
//console.log(config);

    return config;
  },

  addGenericFields(field, config, formReadonly) {
    config.value = '';
    config.title = field.name;
    config.defaultReadonly = formReadonly == true ? true : !field.editable;
    config.model = field.code;
    config.backendLocalization = true;
    config.ord = field.ord
    config.metaDataValueType = field.metaDataValueType

    if (field.dataType == 'SINGLE' && field.values && field.values[0]) {
      config.value = field.values[0];
      config.valueLabel = field.valueDtos[0][config.backendName];
    } else if (field.dataType == 'MULTIPLE' && field.values) {
      config.value = field.values;
      config.valueLabel = [];
      field.valueDtos.forEach((e) => {
        config.valueLabel.push(e[config.backendName]);
      });
    }

    if(field.metaDataBusinessType == "USER_DATA" && field.valueDtos.length > 0){
      
      
      config.otherData.push(field.valueDtos[0].email)
    }

    config.id = field.customFieldValueId ? field.customFieldValueId : 0;
    if (!field.customFieldId) {
      config.customFieldId = field.id;
    } else {
      config.customFieldId = field.customFieldId;
    }

    config.metaDataType = field.dataType
      ? field.dataType
      : field.metaDataType
      ? field.metaDataType
      : '';

    return config;
  },

  addCompositGenericFields(field, config, formReadonly, fieldList) {
    config.value = [];
    
    config.defaultReadonly = formReadonly == true ? true : !field.editable;
    config.model = field.code;
    config.backendLocalization = true;
    config.ord = field.ord

    const mate = fieldList.find((e) => field.code.replace("_CURRENCY", "") == e.code)

    if(!mate){
      console.log(`No quantity config was found for ${field.code}`)
      return false;
    }
    config.title = mate.name;
    config.metaDataValueType = [mate.metaDataValueType, field.metaDataValueType]
    config.value = [ (mate.values && mate.values[0]) ? mate.values[0] : '', (field.values && field.values[0]) ? field.values[0]: ''];

    let valueLabel1 = (mate.values && mate.values[0]) ? mate.values[0] : ''
    /*if(mate.valueDtos && mate.valueDtos[0] && config.backendName[0] && mate.valueDtos[0][config.backendName[0]] != null){
      valueLabel1 = mate.valueDtos[0][config.backendName[0]]
    }*/

    let valueLabel2 = ""
    if(field.valueDtos && field.valueDtos[0] && config.backendName[1] && field.valueDtos[0][config.backendName[1]] != null){
      valueLabel2 = field.valueDtos[0][config.backendName[1]]
    }

    config.valueLabel = `${valueLabel1} ${valueLabel2}` ;

    config.id = field.customFieldValueId ? field.customFieldValueId : 0;

    if (!field.customFieldId) {
      config.customFieldId = [mate.id, field.id ];
    } else {
      config.customFieldId = [mate.customFieldId, field.customFieldId ];
    }

    config.metaDataType = field.dataType
      ? field.dataType
      : field.metaDataType
      ? field.metaDataType
      : '';

    return config;
  },
};
