//import Axios from "@/misc/axios";
//import toast from "@/misc/toast.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from '@/misc/vuex-store';

let uniqueField = 'doorsAndWindowsGrid';

export default (premiseId, state, t, doorTypeMapping, windowMaterialMapping) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/premise/${premiseId}/doors-and-windows`,
    idkey: 'id',
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: 'content',
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      //local: false,

      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns',
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: '30sec',
          refreshInterval: 30,
          title: t('message.rvg_automatic_list_refresh_30_sec'),
          default: true,
        },
        {
          key: '1min',
          refreshInterval: 60,
          title: t('message.rvg_automatic_list_refresh_1_min'),
        },
        {
          key: '5min',
          refreshInterval: 300,
          title: t('message.rvg_automatic_list_refresh_5_min'),
        },
      ],
    },
    mapping: {
      $operations: {
        title: '',
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.open'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-circle btn-sm hover:!opacity-80',
                testValueAttribute: 'propertyEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  //console.log(record.id);
                  state.value.doorsAndWindowsId = record.id;
                  state.value.doorsAndWindowsData.forEach((e) => {
                    
                    if (e.type == 'option') {
                      e.value = record[e.model];
                      e.valueLabel = record[e.model.slice(0, -2) + 'Value'];
                    }else if(e.type == 'optionManual'){
                      e.value = record[e.model];
                      e.valueLabel = t(`message.${record[e.model]}`);
                      
                      
                    } else {
                      e.value = record[e.model]
                    }
                  });
                  store.commit('setRemTab', 20);
                  store.commit('setRemFormEdit', false);
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: 'ID',
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number',
        },
        exportable: false,
        orderable: true,
      },
      doorsAndWindowsType: {
        title: t('message.doorsAndWindowsType'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status',
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: 'text',
          mapping: {
            DOOR: {
              title: t(`message.door`),
            },
            WINDOW: {
              title: t(`message.window`),
            },
            null: {
              title: 'N/A',
            },
          },
        },
      },
      doorTypeValue: {
        title: t('message.doorTyp'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status',
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: 'text',
          mapping: doorTypeMapping,
        },
      },
      numberOfItems: {
        title: t('message.doorsAndWindowsNumber'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Integer',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.numberOfItems ? record.numberOfItems : 'N/A';
        },
      },
      windowMaterialValue: {
        title: t('message.material'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status',
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: 'text',
          mapping: windowMaterialMapping,
        },
      },
      width: {
        title: t('message.windowWidth'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.width ? record.width : 'N/A';
        },
      },
      height: {
        title: t('message.windowHeight'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.height ? record.height : 'N/A';
        },
      },
      depth: {
        title: t('message.windowDepth'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number',
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.depth ? record.depth : 'N/A';
        },
      },
      openable: {
        title: t('message.openable'),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status',
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: 'boolean', // could be: integer, text, boolean
          mapping: {
            true: {
              title: t('message.yes'),
            },
            false: {
              title: t('message.no'),
            },
            null: {
              title: 'N/A',
            },
          },
        },
      },
    },
  };
};
