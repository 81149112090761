let uniqueField = "wfAdminTransitionGrid";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import store from "@/misc/vuex-store";
import router from "../../router";
import axios from "@/misc/axios";
import toast from "@/misc/toast";
import wfAdminUtils from "@/pages/WorkflowAdminComponents/wfAdminUtils";

export default (state, t, taskTypeId, refreshGrid, editWorkflow) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/workflow/admin/task-type/${taskTypeId}/workflows`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: "",
                tooltip: t("message.edit"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="size-4"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "editWorkflowButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  editWorkflow(record.id);
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.openStates"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" class="size-4"><path d="M6.27 17.05C6.72 17.58 7 18.25 7 19C7 20.66 5.66 22 4 22S1 20.66 1 19 2.34 16 4 16C4.18 16 4.36 16 4.53 16.05L7.6 10.69L5.86 9.7L9.95 8.58L11.07 12.67L9.33 11.68L6.27 17.05M20 16C18.7 16 17.6 16.84 17.18 18H11V16L8 19L11 22V20H17.18C17.6 21.16 18.7 22 20 22C21.66 22 23 20.66 23 19S21.66 16 20 16M12 8C12.18 8 12.36 8 12.53 7.95L15.6 13.31L13.86 14.3L17.95 15.42L19.07 11.33L17.33 12.32L14.27 6.95C14.72 6.42 15 5.75 15 5C15 3.34 13.66 2 12 2S9 3.34 9 5 10.34 8 12 8Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "workflowStatesButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  router.push({
                    name: "workflow-admin",
                    params: { taskTypeId: taskTypeId, workflowId: record.id },
                  });
                  store.commit("setRemFormEdit", false);
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.openCustomFields"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "workflowVersionsButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  router.push({
                    name: "workflow-custom-fields",
                    params: { taskTypeId: taskTypeId, workflowId: record.id },
                  });
                  store.commit("setRemFormEdit", false);
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.activate"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "workflowActivateButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: async () => {
                  let uri = "";
                  if (record.status == "ACTIVE") {
                    uri =
                      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${record.id}/inactivate`;
                    try {
                      await axios.patch(uri);
                      toast.success(t("message.successfulSave"));
                      refreshGrid();
                    } catch (error) {
                      console.log(error);
                      toast.error(t("message.notSuccessfulSave"));
                    }
                  } else if (record.status == "INACTIVE" || record.status == "DRAFT") {
                    uri =
                      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                      `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${record.id}/activate`;
                    try {
                      await axios.patch(uri);
                      toast.success(t("message.successfulSave"));
                      refreshGrid();
                    } catch (error) {
                      console.log(error);
                      toast.error(t("message.notSuccessfulSave"));
                    }
                  }
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.clone"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path d="M19 17V19H7V17S7 13 13 13 19 17 19 17M16 8A3 3 0 1 0 13 11A3 3 0 0 0 16 8M19.2 13.06A5.6 5.6 0 0 1 21 17V19H24V17S24 13.55 19.2 13.06M18 5A2.91 2.91 0 0 0 17.11 5.14A5 5 0 0 1 17.11 10.86A2.91 2.91 0 0 0 18 11A3 3 0 0 0 18 5M8 10H5V7H3V10H0V12H3V15H5V12H8Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "workflowCloneButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: async () => {
                  const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${record.id}/clone`;

                    try {
                      await axios.post(uri);
                      toast.success(t("message.successfulClone"));
                      refreshGrid();
                    } catch (error) {
                      console.log(error);
                      toast.error(t("message.notSuccessfulSave"));
                    }
                },
              });

              buttons.push({
                title: "",
                tooltip: "Export",
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path d="M17.86 18L18.9 19C17.5 20.2 14.94 21 12 21C7.59 21 4 19.21 4 17V7C4 4.79 7.58 3 12 3C14.95 3 17.5 3.8 18.9 5L17.86 6L17.5 6.4C16.65 5.77 14.78 5 12 5C8.13 5 6 6.5 6 7S8.13 9 12 9C13.37 9 14.5 8.81 15.42 8.54L16.38 9.5H13.5V10.92C13 10.97 12.5 11 12 11C9.61 11 7.47 10.47 6 9.64V12.45C7.3 13.4 9.58 14 12 14C12.5 14 13 13.97 13.5 13.92V14.5H16.38L15.38 15.5L15.5 15.61C14.41 15.86 13.24 16 12 16C9.72 16 7.61 15.55 6 14.77V17C6 17.5 8.13 19 12 19C14.78 19 16.65 18.23 17.5 17.61L17.86 18M18.92 7.08L17.5 8.5L20 11H15V13H20L17.5 15.5L18.92 16.92L23.84 12L18.92 7.08Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "editWorkflowButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  wfAdminUtils.exportWf(taskTypeId, record.id)
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: t("message.identifier"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: false,
        orderable: true,
      },
      name: {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.name ? record.name : "N/A";
        },
      },
      taskTypeId: {
        title: t("message.taskType") + " Id",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Integer",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.taskTypeId ? record.taskTypeId : "N/A";
        },
      },
      version: {
        title: t("message.version"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Integer",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.version ? record.version : "N/A";
        },
      },
      status: {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.status ? record.status : "N/A";
        },
      },
    },
  };
};
