<template>
  <div class="p-0 h-full overflow-y-hidden w-full max-w-full">
    <div class="flex flex-col w-full border-opacity-50 pt-4 pl-5">
      <div class="text-white font-bold text-xl text-left w-full">{{ getUserName() }}</div>
    </div>

    <div class="flex flex-col gap-2 w-full overflow-hidden">
      <div class="text-left w-full flex-col mt-5 px-5">
        <h2 class="text-sm font-bold">{{ $t('message.generateApiKeyTitle') }}</h2>
      </div>

      <div v-if="!apiKeyLoading" class="text-center w-full flex-col px-5">
        <div class="relative w-full">
          <input disabled type="text" :value="currentApiKeyText" class="input input-bordered w-full max-w-md dark:bg-naBlack dark:text-white dark:outline-none" />
          <button v-if="currentApiKey" @click="copyApiKey" class="absolute right-1 top-2 btn btn-sm font-light text-sm border-none bg-naGold dark:text-naBlack focus:bg-naGold active:bg-naGold visited:bg-naGold text-naWhite">
            {{ $t("message.copy") }}
          </button>
        </div>
      </div>
      <SpinnerLoader v-else />
      <div class="text-center w-full flex-col px-5">
        <div v-if="toast && toast.message" class="alert rounded-lg p-2 px-4 text-xs flex flex-row items-start">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-magenta shrink-0 w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span class="grow">{{ toast.message }}</span>
        </div>
      </div>

      <div class="text-left w-full flex-col mt-5 px-5">
        <h2 class="text-sm font-bold">{{ $t('message.generateApiKeyExample') }}</h2>
      </div>

      <div class="text-center w-full flex-col px-5 overflow-hidden">
        <div class="mockup-code">
          <pre data-prefix="$"><code>curl -H "x-api-key: {{ currentApiKeyText }}" https://SITE_URL/api/v2/reporting/sources/areas</code></pre>
        </div>
      </div>

      <div v-if="!apiKeyLoading" class="text-center w-full flex-col px-5 mt-5">
        <button @click="clickOnGenerate" class="btn btn-sm bg-magenta text-white font-light text-xs m-auto border-none">
          {{ $t("message.generateApiKeyButtonTitle") }}
        </button>
      </div>

      <div class="text-left w-full flex-col mt-5 px-5">
        <h2 class="text-sm font-bold">{{ $t('message.version') }}</h2>
      </div>

      <div class="text-center w-full flex-col px-5 overflow-hidden">
        <div class="font-mono flex items-start px-2 flex-col">
          <span v-for="(version, key) in versions" :key="key">{{ key }}: <b>{{ version }}</b></span>
        </div>
      </div>


    </div>

    <input type="checkbox" class="modal-toggle" v-model="showRegenerateConfirmation" />
    <div class="modal">
      <div class="modal-box">
        <h3 class="text-lg text-left font-normal">{{ $t('message.regenerateApiKeyConfirmation') }}</h3>
        <div class="modal-action">
          <label class="btn btn-sm text-white font-light text-xs border-none" @click="() => { showRegenerateConfirmation = false }">{{ $t('message.regenerateApiKeyConfirmationCancel') }}</label>
          <label class="btn btn-sm bg-magenta text-white font-light text-xs border-none" @click="() => { showRegenerateConfirmation = false; generateNewApiKey(); }">{{ $t('message.regenerateApiKeyConfirmationOk') }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from '@/misc/axios';
import SpinnerLoader from '../../components/SpinnerLoader.vue';
import User from '../../misc/User';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { version } from '../../../package.json'

export default {
  name: 'UserProfileSidebarContent',
  props: {
  },
  data() {
    return {
      showRegenerateConfirmation: false,
      apiKeyLoading: false,
      currentApiKey: null,
      versions: { frontend: version },
      toast: null,
      toastInterval: null,
    }
  },
  components: {
    SpinnerLoader
  },
  mounted() {
    this.getVersions();
    this.fetchCurrentApiKey()
  },
  methods: {
    hideSidebar() {
      this.$store.commit('hideSidebar');
    },
    getVersions() {
      Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/versions', {
      onTokenRefreshed: () => {
          this.getVersions()
        }
      }).then((res) => {
        this.versions = res.data;
        this.versions.frontend = version;
      })
      .catch((err) => {
        console.log(err)
      });
    },
    getUserName() {
      return User.getUserName();
    },
    getUserRoles() {
      return User.getUserRoles();
    },
    clickOnGenerate() {
      if(this.apiKeyLoading) return;
      if(this.currentApiKey && this.currentApiKey.length > 0) {
        this.showRegenerateConfirmation = true;
      }
      else {
        this.generateNewApiKey();
      }
    },
    showToast(type, message) {
      if(this.toastInterval) {
        window.clearTimeout(this.toastInterval);
      }

      this.toast = {
        type: type,
        message: message
      };

      var vm = this;
      vm.toastInterval = window.setTimeout(() => {
        vm.toast = null;
      }, 3000)
    },
    fetchCurrentApiKey() {
      this.apiKeyLoading = true;
      Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/user/get-api-key', {
        onTokenRefreshed: () => {
          this.fetchCurrentApiKey()
        }
      }).then((res) => {
        if(res.data && res.data.apiKey) {
          this.currentApiKey = res.data.apiKey;
        }
        else {
          this.currentApiKey = null;
        }
        this.apiKeyLoading = false;
      })
      .catch((err) => {
        console.log(err)
        this.currentApiKey = null;
        this.apiKeyLoading = false;
      })
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.currentApiKey);
      this.showToast('success', this.$t("message.copiedToClipboard"));
    },
    generateNewApiKey() {
      this.apiKeyLoading = false;
      Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/user/regenerate-api-key', {
        onTokenRefreshed: () => {
          this.fetchCurrentApiKey()
        }
      }).then((res) => {
        if(res.data && res.data.apiKey) {
          this.currentApiKey = res.data.apiKey;
        }
        else {
          this.currentApiKey = null;
        }
        this.apiKeyLoading = false;
      })
      .catch((err) => {
        console.log(err)
        this.currentApiKey = null;
        this.apiKeyLoading = false;
      })
    }
  },
  computed: {
    sidebarEntity() {
      var entity = this.$store.getters.getSidebarEntity;
      return entity.entity;
    },
    zoneGroups() {
      var entity = this.$store.getters.getSidebarEntity;
      return entity.params.zoneGroups;
    },
    currentApiKeyText() {
      if(!this.currentApiKey) return this.$t("message.missingApiKey");
      return this.currentApiKey;
      //return this.currentApiKey.substr(0, 12) + '...' + this.currentApiKey.substr(this.currentApiKey.length - 12);
    }
  }
}
</script>