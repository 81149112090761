<template>
  <div>
    <WFLink
      :method="
        () => {
          state.open = !state.open;
        }
      "
      class="uppercase mb-4 hover:cursor-pointer"
    >
      <div class="flex flex-row">
        {{ $t('message.relatedTasks') }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          :class="{ 'rotate-180': state.open == false }"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
          />
        </svg>
      </div>
    </WFLink>

    <div v-if="state.open == true" class="">
      <RemPrimaryButtonSmall
        class="my-4"
        :label="$t('message.attachTaskButton')"
        :method="() => store.commit('setIsModalOpen', true)"
      >
      </RemPrimaryButtonSmall>
      <div>
        <div
          v-for="task in state.associations"
          v-bind:key="task.associationId"
          class="p-4 my-3 text-gray-700 dark:text-white bg-white border border-gray-200 rounded-lg shadow-xs sm:flex dark:bg-gray-700 dark:border-gray-600"
        >
          <div class="flex justify-between items-center w-full">
            <p
              class="text-white comment-link hover:cursor-pointer"
              @click="
                () => {
                  openTask(task);
                }
              "
            >
              {{ $t(`message.${task.associationTypeCode}`) }}: {{ task.linkedTaskIdentifier }} -
              {{
                task.linkedTaskSubject.length > 20
                  ? task.linkedTaskSubject.slice(0, 17) + '...'
                  : task.linkedTaskSubject
              }}
              - {{ $t('message.status') }}: {{ task.linkedTaskStateName }} -
              {{ $t('message.responsible') }}: {{ task.linkedTaskResponsibleName }} -
              {{ $t('message.priority') }}:
              {{
                task.linkedTaskPriority == 'LOW'
                  ? $t(`message.PriorityLow`)
                  : task.linkedTaskPriority == 'MEDIUM'
                  ? $t(`message.PriorityMedium`)
                  : $t(`message.PriorityHigh`)
              }}
            </p>
            <button
              class="btn btn-sm btn-secondary btn-circle normal-case text-sm"
              @click="deleteAssociationConfirm(task.associationId)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
                fill="none"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <title>{{ $t('message.deattach') }}</title>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.2 11.8L11.8 6.19998M6.6 2.6V1M11.4 15.4V17M2.6 6.6H1M15.4 11.4H17M3.33137 3.33137L2.2 2.2M14.6687 14.6686L15.8 15.7999M9.00003 13.5254L7.30297 15.2225C6.05329 16.4721 4.02717 16.4721 2.77749 15.2225C1.52781 13.9728 1.52781 11.9467 2.77749 10.697L4.47454 8.99993M13.5255 8.99993L15.2226 7.30287C16.4722 6.0532 16.4722 4.02707 15.2226 2.77739C13.9729 1.52771 11.9468 1.52771 10.6971 2.77739L9.00003 4.47445"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- eslint-disable -->
    <RemFormConfirmationModal
      v-model:showConfirmation="state.confirmation.showConfirmation"
      v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
      v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
      v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod"
    >
    </RemFormConfirmationModal>
    <!-- eslint-enable -->

    <AddTaskAssociationModal @refresh="getAssociations()" :showModal="modalStatus" />
  </div>
</template>

<script setup>
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';
import WFLink from '../../components/REM/Workflow/WFLink.vue';
import RemFormConfirmationModal from '../../components/REM/RemFormConfirmationModal.vue';
import AddTaskAssociationModal from './AddTaskAssociationModal.vue';

import { onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/misc/vuex-store.js';
import router from '@/misc/router';
import axios from '@/misc/axios.js';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import workflowTaskUtils from './workflowTaskUtils';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const route = useRoute();

const state = ref({});

state.value.taskId = route.params.taskId;
state.value.associations = [];
state.value.open = false;
state.value.confirmation = {
  showConfirmation: false,
  confirmationTextToken: '',
  confirmationTrueMethod: () => {},
  confirmationFalseMethod: () => {},
};

const openTask = (task) => {
  const routeData = router.resolve({
    name: 'WorkflowTaskPage',
    params: { taskId: task.linkedTaskId, view: 'read' },
  });
  window.open(routeData.href, '_blank');
};

const getAssociations = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/task/${state.value.taskId}/associations`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        getAssociations();
      },
    });

    state.value.associations = response.data;
  } catch (error) {
    console.log(error);
  }
};

const deleteAssociationConfirm = (id) => {
  state.value.confirmation.confirmationTextToken = 'deleteAssociationConfirm';
  state.value.confirmation.confirmationTrueMethod = () => {
    removeThisTask(id);
    state.value.confirmation.showConfirmation = false;
  };
  state.value.confirmation.confirmationFalseMethod = () => {
    state.value.confirmation.showConfirmation = false;
  };
  state.value.confirmation.showConfirmation = true;
};

const removeThisTask = async (id) => {
  let resp = await workflowTaskUtils.deleteUniversal(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/task/association/${id}`,
    t
  );
  if (resp != false) {
    getAssociations();
  }
};

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

onMounted(() => {
  getAssociations();
});
</script>

<style scoped>
.comment-link:hover {
  color: #e18330;
}

.comment-link-active {
  color: #e18330;
}

.wf-title:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.related-task-card {
  background-color: rgba(0, 0, 0, 0.3);
}

select[disabled] {
  @apply border border-gray-600;
}

.im-dropdown-content {
  position: absolute;
}

.im-invisible {
  display: none;
}

.dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 96%;
}

.clear-button {
  position: absolute;
  right: 0.6rem;
  background: none;
  border: none;
  color: rgb(175, 170, 170);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
}

.clear-button:hover {
  cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
  display: none;
}

.dropdown::before {
  position: absolute;
  font-size: 12px;
  content: ' \25BC';
  top: 7px;
  right: -8px;
  height: 20px;
  width: 28px;
  pointer-events: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.option-hover {
  padding: 5px 0 5px 5px;
  font-size: 0.875rem;
}

.option-hover:hover {
  background-color: rgb(147, 185, 247);
  color: #242933;
}

ul .option-hover:first-of-type {
  border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
  border-radius: 0 0 10px 10px;
}
</style>
