<template>
  <div v-if="state.loaded == true">
    <div v-for="group in form" v-bind:key="group.groupName">
      <div v-if="group.fieldList.length > 0">
        <WFLink :method="() => {
          group.open = !group.open;
        }
          " class="uppercase my-4">
          <div class="flex flex-row">
            {{ $t(`message.${group.groupName}`) }}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6" :class="{ 'rotate-180': group.open == false }">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
            </svg>
          </div>
        </WFLink>
        <div v-if="group.open">
          <div v-for="field in group.fieldList" v-bind:key="field.model">
            <!-- eslint-disable -->
            <WFLabelField v-model:inputValue="field.value" v-model:parent="field.parent"
              v-model:validated="field.validated" v-model:readonly="field.defaultReadonly" :config="field">
            </WFLabelField>
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import WFLink from "./WFLink.vue";
import WFLabelField from "./WFLabelField.vue";

import { useRoute } from "vue-router";
import { defineModel, ref, watch, onMounted } from "vue";
import axios from "@/misc/axios";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import backendTypeMapper from "../../../misc/fieldConfigs/backendTypeMapper";
//import utils from "@/misc/fieldConfigs/utils";

const taskType = defineModel("taskType");
const customFieldSaveEvent = defineModel("customFieldSaveEvent");
const form = defineModel("form");

const props = defineProps(["placing"]);

let route = useRoute();

const state = ref({
  loaded: false,
  formReadonly: route.params.view == 'read',
  //form: [],
});



onMounted(() => {
  setTimeout(() => {
    setMeUp();
  }, 1000);

});

const setMeUp = async (taskId) => {
  form.value = [];

  if (!taskId) {
    taskId = route.params.taskId;
  }
  state.value.newTask = taskId == 0;

  if (state.value.newTask == true && !taskType.value) {
    return;
  }
  let resp;
  if (state.value.newTask) {
    resp = await axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      "/rem/workflow/task/type/" +
      taskType.value +
      "/start-state-custom-fields",
      {
        onTokenRefreshed: () => {
          this.setMeUp(state);
        },
      }
    );



  } else {
    resp = await axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      "/rem/workflow/task/" +
      taskId +
      "/custom-field-values",
      {
        onTokenRefreshed: () => {
          this.setMeUp(state);
        },
      }
    );


  }
  if (resp && resp.status == 200) {
    if (resp.data.length == 0) {
      state.value.loaded = true;
      return;
    }

    resp.data.forEach((section) => {
      if (props.placing != section.section) {
        return;
      }
      section.groups.forEach((fieldGroup) => {
        if (props.placing == "") {
          return;
        }
        let group = {
          groupName: fieldGroup.groupName,
          open: route.params.view == "newfromlist",
          fieldList: [],
        };

        fieldGroup.customFields.forEach((field) => {
          const fieldConfig = backendTypeMapper.createConfig(field, state.value.formReadonly, fieldGroup.customFields);

          if (fieldConfig) {
            group.fieldList.push(fieldConfig);
          }
        });

        group.fieldList.sort((a, b) => a.ord > b.ord)

        form.value.push(group);
      });




    })


  }
  /*form.value[0].fieldList.push({
    type: "currencyField",
    title: 'test composit field',
    optionValue: 'CURRENCY',
    options: [],
    value: ['', '']
  })*/
  state.value.loaded = true;
};

/*const saveCustomFields = async (id) => {
  state.value.form.forEach((group) => {
    group.fieldList.forEach(async (field) => {
      const resp = utils.saveWFCustomField(id, field)

      if (resp && resp.status == 200) {
        console.log(resp);
      }
    });
  });
};*/

// watchers

if (route.params.taskId == 0) {
  watch(
    () => taskType.value,
    () => {
      setMeUp();
    }
  );
}

watch(
  () => route.params.view,
  (newValue) => {
    state.value.formReadonly = newValue == "read"
    setMeUp();
  }
);

watch(() => route.params.taskId, () => {
  //setMeUp()
})



watch(
  () => customFieldSaveEvent.value.counter,
  () => {
    //console.log("Custom field save is not set up");

   // saveCustomFields(customFieldSaveEvent.value.id);
  }
);
</script>
