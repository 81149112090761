<template>
  <div>
    <!-- Modal toggle -->
    <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
    <div class="modal">
      <div class="modal-box">
        <div class="h-full overflow-y-visible w-full">
          <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
            <div class="flex flex-row items-center gap-2 justify-center">
              <h2
                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
              >
                <span class="text-secondary">{{ $t('message.editDocument') }}</span>
              </h2>
            </div>
            <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>

          <div class="px-5 pb-5">
            <div class="flex flex-col items-center justify-center gap-2 w-full">
              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt"
                      >{{ $t('message.name') }}
                      <span :class="{ 'im-mandatory': !state.docuName || state.docuName == '' }">{{
                        ' *'
                      }}</span>
                    </span>
                  </label>
                  <input
                    v-model="state.docuName"
                    type="text"
                    class="input input-sm input-bordered w-full"
                  />
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt"
                      >{{ $t('message.dateOfOrigin') }}
                      <span
                        :class="{
                          'im-mandatory': !state.docuOriginDate || state.docuOriginDate == '',
                        }"
                        >{{ ' *' }}</span
                      >
                    </span>
                  </label>
                  <label class="flex flex-row gap-2">
                    <VueDatePicker
                      format="yyyy MMM dd"
                      :enable-time-picker="false"
                      :time-picker-inline="true"
                      :locale="$i18n.locale"
                      :month-change-on-scroll="false"
                      :action-row="{}"
                      :clearable="true"
                      select-text="OK"
                      :cancel-text="t('message.cancel')"
                      :teleport="true"
                      model-type="yyyy-MM-dd"
                      :placeholder="t('message.dateOfOrigin')"
                      v-model="state.docuOriginDate"
                      :dark="true"
                    />
                  </label>
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt"
                      >{{ $t('message.mainType') }}
                      <span
                        :class="{
                          'im-mandatory': !state.typeId || state.typeId == '',
                        }"
                        >{{ ' *' }}</span
                      >
                    </span>
                  </label>
                  <OptionValueSelector
                    id="mainTypeSelector"
                    v-model="state.typeId"
                    type="DOCUMENT_TYPE"
                  />
                </div>
              </div>

              <div class="flex flex-row gap-3 w-full mt-2">
                <div class="form-control w-full mt-0">
                  <label class="label mt-0 pt-0" for="">
                    <span class="label-text-alt"
                      >{{ $t('message.subType') }}
                      <span
                        :class="{
                          'im-mandatory': !state.subTypeId || state.subTypeId == '',
                        }"
                        >{{ ' *' }}</span
                      >
                    </span>
                  </label>
                  <OptionValueSelector
                    id="subTypeSelector"
                    v-model="state.subTypeId"
                    type="DOCUMENT_SUB_TYPE"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label
            v-if="User.hasPermission('VocabularyAdmin')"
            class="btn btn-sm text-white font-light btn-error hover:bg-black hover:border-none text-xs"
            @click="
              () => {
                setConfirmation('deleteDocumentConfirm', deleteDocument);
              }
            "
            >{{ $t('message.delete') }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                updateDocument(props.id);
              }
            "
            >{{ $t('message.save') }}</label
          >
        </div>
      </div>
    </div>
    <!--CONFIRMATION MODAL-->
    <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
    <div class="modal">
      <div class="modal-box">
        <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
          {{ $t('message.' + state.confirmationModal.message) }}
        </h3>
        <div class="modal-action">
          <label
            class="btn btn-sm text-white font-light text-xs"
            @click="
              () => {
                state.confirmationModal.show = false;
              }
            "
            >{{ $t('message.no') }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                state.confirmationModal.function();
                state.confirmationModal.show = false;
              }
            "
            >{{ $t('message.yes') }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, watch } from 'vue';
import Axios from '@/misc/axios';
import store from '@/misc/vuex-store.js';
import toast from '@/misc/toast.js';
import { useI18n } from 'vue-i18n';
import OptionValueSelector from '@/components/OptionValueSelector.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import User from '@/misc/User.js';

import TenantConfigHandler from '@/misc/TenantConfigHandler';

const { t } = useI18n();

const emits = defineEmits(['refreshGrid']);

const state = reactive({
  showModal: false,
  docuName: '',
  docuOriginDate: '',
  typeId: null,
  subTypeId: null,
  confirmationModal: {
    show: false,
    message: '',
    function: '',
  },
});

const props = defineProps([
  'showModal',
  'id',
  'propertyGrid',
  'buildingGrid',
  'refreshGrid',
  'premiseGrid',
]);

const updateDocument = async (id) => {
  const uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/document/${id}`;

  const formData = new FormData();
  formData.append('nameWithoutExtension', state.docuName);
  formData.append('mainType', state.typeId);
  formData.append('subType', state.subTypeId);
  formData.append('dateOfOrigin', state.docuOriginDate);

  try {
    const resp = await Axios.put(uri, formData, {
      onTokenRefreshed: () => {
        updateDocument(id);
      },
    });
    if (resp.status == 200) {
      toast.success(t('message.successfulSave'));
      emptyEditor();
      closeModal();
    }
  } catch (error) {
    console.log(error);
    toast.error(t('message.missingFormFields'));
  }

  props.propertyGrid?.refresh();
  props.buildingGrid?.refresh();
  props.premiseGrid?.refresh();

  emits('refreshGrid');
};

const deleteDocument = async () => {
  const uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/document/${props.id}`;
  try {
    await Axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteDocument();
      },
    });
    toast.success(t('message.deleteSuccessful'));
    closeModal();
  } catch (error) {
    console.log(error);
    toast.error(t('message.deleteNotSuccessful'));
  }
  props.propertyGrid?.refresh();
  props.buildingGrid?.refresh();
  props.premiseGrid?.refresh();

  emits('refreshGrid');
};

const setConfirmation = (message, func) => {
  state.confirmationModal.message = message;
  state.confirmationModal.function = func;
  state.confirmationModal.show = true;
};

const document = computed(() => {
  return store.getters.getDocumentById;
});

const fillEditor = () => {
  state.docuName = document.value.nameWithoutExtension ? document.value.nameWithoutExtension : '';

  if (document.value.dateOfOrigin[2] < 10) {
    document.value.dateOfOrigin[2] = '0' + document.value.dateOfOrigin[2];
  }
  if (document.value.dateOfOrigin[1] < 10) {
    document.value.dateOfOrigin[1] = '0' + document.value.dateOfOrigin[1];
  }
  state.docuOriginDate = document.value.dateOfOrigin ? document.value.dateOfOrigin.join('-') : '';

  state.typeId = document.value.typeId ? document.value.typeId : null;
  state.subTypeId = document.value.subTypeId ? document.value.subTypeId : null;
};

const emptyEditor = () => {
  (state.docuName = ''),
    (state.docuOriginDate = ''),
    (state.typeId = null),
    (state.subTypeId = null);
};

watch(
  () => props.showModal,
  () => {
    state.showModal = props.showModal;
    fillEditor();
  }
);

const closeModal = () => {
  store.commit('setIsModalOpen', false);
};

onMounted(() => {});
</script>

<style scoped>
.im-mandatory {
  color: red;
}
</style>
