let uniqueField = 'wfAdminTransitionGrid';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import axios from "@/misc/axios";
import toast from "@/misc/toast";
// import store from "@/misc/vuex-store";

export default (state, t, taskTypeId, workflowId, transitionId, wfAdminTransitionGrid, setTransRequired) => {
  return {
    endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${transitionId}/custom-fields-configs`,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min')
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
        "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.deattach'),
                icon: '<svg class="w-4 h-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.2 11.8L11.8 6.19998M6.6 2.6V1M11.4 15.4V17M2.6 6.6H1M15.4 11.4H17M3.33137 3.33137L2.2 2.2M14.6687 14.6686L15.8 15.7999M9.00003 13.5254L7.30297 15.2225C6.05329 16.4721 4.02717 16.4721 2.77749 15.2225C1.52781 13.9728 1.52781 11.9467 2.77749 10.697L4.47454 8.99993M13.5255 8.99993L15.2226 7.30287C16.4722 6.0532 16.4722 4.02707 15.2226 2.77739C13.9729 1.52771 11.9468 1.52771 10.6971 2.77739L9.00003 4.47445" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                classList: 'btn btn-secondary btn-circle btn-sm hover:!opacity-80',
                testValueAttribute: 'propertyEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: async () => {
                  const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${transitionId}/custom-field-config/${record.id}/remove`
                  try {
                      await axios.patch(uri)
                      toast.success(t("message.successfulSave"))
                      wfAdminTransitionGrid.value.refresh()
                  } catch (error) {
                      console.log(error);
                      toast.error(t("message.notSuccessfulSave"))
                  }
              },
              })

              buttons.push({
                title: '',
                tooltip: t('message.required'),
                icon: '<svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 19C14 16.21 15.91 13.87 18.5 13.2L12 2L1 21H14.35C14.13 20.37 14 19.7 14 19M13 18H11V16H13V18M13 14H11V10H13V14M21 15V18H24V20H21V23H19V20H16V18H19V15H21Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                classList: 'btn btn-secondary btn-circle btn-sm hover:!opacity-80',
                testValueAttribute: 'requiredButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  let required = record.required = !record.required
                  setTransRequired(record.id, required)
                },
              })

              return buttons;
            }
          }
        }
      },
      "id": {
        title: "CF ID",
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "code": {
        title: "Code",
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.code ? record.code : 'N/A'
        },
      },
      "name": {
        title: "Custom field neve",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.name ? record.name : 'N/A'
        },
      },
      "groupName": {
        title: "Group name",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.groupName ? record.groupName : 'N/A'
        },
      },
      "required": {
        title: "Kötelező",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        formatter: {
            type: 'Status',
            dataType: "boolean", // could be: integer, text, boolean
            mapping: {
              "true": {
                title: t('message.yes')
              },
              "false": {
                title: t('message.no')
              },
              "null": {
                title: 'N/A'
              }
            }
          }
      },
    }
  }
}
